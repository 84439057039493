import { Button, Flex, FormControl, FormHelperText, FormLabel, Icon, Input, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { Dispatch, SetStateAction, useState } from "react";
import { FaCheck } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import { CreateProjectRequest, ParentProjectDto, PersonDto, useCreateProjectMutation } from "../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../common/auth/useLoggedInWithOrgContextState";
import { DateFormControl } from "../../common/input/DateTimeSelectors/DateFormControl";
import { SingleSelector } from "../../common/input/Selector/SingleSelector";
import { PersonSelector } from "../../common/persons/PersonSelector";
import { urls } from "../../urls";
import { organizationProjectRoles } from "../organizations/current/manage-users/utils";
import { ProjectSelector } from "./ProjectSelector";
import { useProjectTypes } from "./useProjectTypes";

const initializeProject = (): CreateProjectRequest => ({
  id: uuid(),
  externalId: "",
  name: "",
  startDate: undefined,
  endDate: undefined,
  parent: undefined,
  projectResponsible: "",
  sourcingResponsible: "",
});

export const CreateProjectForm = ({ setShowModal }: { setShowModal: Dispatch<SetStateAction<boolean>> }) => {
  const navigate = useNavigate();
  const [createProject, { isLoading: isCreatingProject }] = useCreateProjectMutation();
  const projectTypes = useProjectTypes();
  const toast = useToast();
  const authState = useLoggedInWithOrgContextState();

  const [newProject, setNewProject] = useState<CreateProjectRequest>(initializeProject());
  const [parentProject, setParentProject] = useState<ParentProjectDto | null>();
  const [projectResponsible, setProjectResponsible] = useState<PersonDto>();
  const [sourcingResponsible, setSourcingResponsible] = useState<PersonDto>();

  return (
    <>
      <Flex grow={1} flexDirection="column" overflowY="scroll" shadow="inner" rounded="lg" p="4">
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Project ID")}</FormLabel>
          <Input
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            placeholder={`${t("Project ID")}...`}
            value={newProject.externalId}
            onChange={(e) => setNewProject((p) => ({ ...p, externalId: e.target.value }))}
          />
          <FormHelperText>{`${t("Enter your unique project identifier")} (${t("optional")}).`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Name")}</FormLabel>
          <Input
            size="sm"
            rounded="md"
            backgroundColor="smBackground"
            placeholder={`${t("Name")}...`}
            value={newProject.name}
            onChange={(e) => setNewProject((p) => ({ ...p, name: e.target.value }))}
          />
          <FormHelperText>{`${t("Enter a descriptive project name")}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Project type")}</FormLabel>
          <SingleSelector
            size="sm"
            placeholder={{ text: `${t("Project type")}...`, color: "" }}
            value={projectTypes.find((t) => t.value === newProject.projectType)}
            options={projectTypes}
            onChange={(option) => setNewProject((p) => ({ ...p, projectType: option?.value }))}
          />
          <FormHelperText>{`${t("Select the project type")}.`}</FormHelperText>
        </FormControl>
        <Flex pb="4">
          <DateFormControl
            id="project start date"
            title={t("Start date")}
            isRequiredButNotProvided={false}
            defaultDate={newProject?.startDate}
            helperText={`${t("Select the project start date")}.`}
            errorMessage={null}
            onChange={(d) => setNewProject((p) => ({ ...p, startDate: d ?? undefined }))}
          />
        </Flex>
        <Flex pb="4">
          <DateFormControl
            id="project end date"
            title={t("End date")}
            isRequiredButNotProvided={false}
            defaultDate={newProject?.endDate}
            helperText={`${t("Select the project end date")}.`}
            errorMessage={null}
            onChange={(d) => setNewProject((p) => ({ ...p, endDate: d ?? undefined }))}
          />
        </Flex>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Parent project")}</FormLabel>
          <ProjectSelector
            selectedProject={parentProject}
            onChange={(parent) => {
              setParentProject(parent);
              setNewProject((p) => ({ ...p, parent: parent?.id }));
            }}
          />
          <FormHelperText>{`${t("Select the parent project")}.`}</FormHelperText>
        </FormControl>
        <FormControl pb="4">
          <FormLabel fontSize="sm">{t("Project responsible")}</FormLabel>
          <PersonSelector
            organizationIds={[authState.selectedOrg.id]}
            selectedPerson={projectResponsible}
            placeholder={`${t("Project responsible")}...`}
            roles={organizationProjectRoles}
            onChange={(person) => {
              setProjectResponsible(person ?? undefined);
              setNewProject((p) => ({ ...p, projectResponsible: person?.id }));
            }}
          />
          <FormHelperText>{`${t("Select the person responsible for the project")}. ${t(
            "Only users with project access can be selected"
          )}.`}</FormHelperText>
        </FormControl>
        <FormControl>
          <FormLabel fontSize="sm">{t("Sourcing responsible")}</FormLabel>
          <PersonSelector
            organizationIds={[authState.selectedOrg.id]}
            selectedPerson={sourcingResponsible}
            placeholder={`${t("Sourcing responsible")}...`}
            roles={organizationProjectRoles}
            onChange={(person) => {
              setSourcingResponsible(person ?? undefined);
              setNewProject((p) => ({ ...p, sourcingResponsible: person?.id }));
            }}
          />
          <FormHelperText>{`${t("Select the sourcing responsible for the project")}. ${t(
            "Only users with project access can be selected"
          )}.`}</FormHelperText>
        </FormControl>
      </Flex>
      <Flex pt="4">
        <Button
          size={"sm"}
          variant={"outline"}
          colorScheme="teal"
          leftIcon={<Icon as={FaCheck} />}
          isDisabled={!newProject.name}
          isLoading={isCreatingProject}
          onClick={async () => {
            const res = await createProject({
              createProjectRequest: {
                id: newProject.id ? newProject.id : undefined,
                externalId: newProject.externalId ? newProject.externalId : undefined,
                name: newProject.name,
                startDate: newProject.startDate ? newProject.startDate : undefined,
                endDate: newProject.endDate ? newProject.endDate : undefined,
                parent: newProject.parent ? newProject.parent : undefined,
                projectResponsible: newProject.projectResponsible ? newProject.projectResponsible : undefined,
                sourcingResponsible: newProject.sourcingResponsible ? newProject.sourcingResponsible : undefined,
                projectType: newProject.projectType ? newProject.projectType : undefined,
              },
            });
            if ("error" in res) {
              toast({ status: "error", title: t("Could not create project") ?? "" });
              return;
            }
            setNewProject(initializeProject());
            setShowModal(false);
            navigate(urls.projects.view.go(res.data.id));
          }}
        >
          {t("Save")}
        </Button>
      </Flex>
    </>
  );
};
