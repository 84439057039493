import { Flex, Grid, GridItem, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { t } from "i18next";
import { OrgPublicProfileDto } from "../../../autogen/bff-api";
import { requireBooleanEnvVar } from "../../../config";
import { displayPersonName } from "../../contracts/view-single/sharing/AddExternalParticipantModal";
import { AboutUs } from "./content-items/AboutUs";
import { Certificates } from "./content-items/Certificates";
import { Details } from "./content-items/Details";
import { EsgScoring } from "./content-items/EsgScorecard";
import { LinkedEntries } from "./content-items/LinkedEntries";
import { Location } from "./content-items/Location";
import { Questionnaires } from "./content-items/Questionnaires";
import { Rating } from "./content-items/Rating";
import { Specialties } from "./content-items/Specialties";

interface Props {
  organization: OrgPublicProfileDto;
}

export const Content = ({ organization }: Props) => {
  const showDummyData = requireBooleanEnvVar("VITE_ENABLE_DUMMY_COMPANY_PAGE");
  return (
    <Grid
      width="full"
      maxWidth="full"
      overflow="hidden"
      gridTemplateColumns={"3fr 5fr"}
      columnGap={6}
      rowGap={2}
      templateAreas={`
            "sidebar products"
            `}
    >
      <GridItem area="sidebar">
        <Details organization={organization} />
        <br />
        {organization.linkedEntries.length > 0 && (
          <>
            <LinkedEntries entries={organization.linkedEntries} />
            <br />
          </>
        )}
        {showDummyData && <Certificates />}
        <br />
        {showDummyData && <Questionnaires />}
        <br />
        {showDummyData && <Rating />}
      </GridItem>
      <GridItem area="products" overflowX="hidden" pb="6">
        <AboutUs organization={organization} />
        <br />
        <Specialties organization={organization} />
        <br />
        <Flex
          border="1px solid"
          borderColor="smBorder"
          flexDirection="column"
          alignItems="start"
          justifyContent="start"
          borderRadius="lg"
          padding="25px"
          bg="smBackground"
          width="full"
          maxWidth="full"
          height="auto"
          position="relative"
        >
          <Text fontSize="md" fontWeight="bold" pb="2">
            {t("Contact persons")}
          </Text>
          <Flex
            width="full"
            maxWidth="full"
            overflowX="auto"
            border="1px solid"
            borderColor="smBorder"
            rounded="lg"
            pt="5"
            pb="2.5"
          >
            <Table>
              <Thead>
                <Tr>
                  <Th>{t("Name")}</Th>
                  <Th>{t("Email")}</Th>
                  <Th>{t("Title")}</Th>
                </Tr>
              </Thead>
              <Tbody>
                {(organization.contactPersons?.length ? organization.contactPersons : [organization.owner]).map((p) => (
                  <Tr key={p.id}>
                    <Td>{displayPersonName(p)}</Td>
                    <Td>{p.email}</Td>
                    <Td>{p.corporateTitle}</Td>
                  </Tr>
                ))}
              </Tbody>
            </Table>
          </Flex>
        </Flex>
        <br />
        {showDummyData && <EsgScoring />}
        <br />
        {showDummyData && <Location />}
      </GridItem>
    </Grid>
  );
};
