import { Flex, Icon, Text } from "@chakra-ui/react";
import React from "react";
import { IconType } from "react-icons";

interface Props {
  icon?: IconType;
  text: string;
  backgroundColor?: string;
  color?: string;
  border?: string;
  borderColor?: string;
  children?: React.ReactNode;
}

export const StatusBanner = ({ icon, text, backgroundColor, color, border, borderColor, children }: Props) => {
  return (
    <Flex
      display="flex"
      bg={backgroundColor}
      rounded="md"
      flexDirection="row"
      alignItems="center"
      color={color ?? "smBackground"}
      border={border}
      borderColor={borderColor}
      p="5"
    >
      <Flex>
        {icon && <Icon as={icon} mr="5px" mt="3px" w="20px" h="20px" />}
        <Text fontSize={"lg"} fontWeight="bold">
          {text}
        </Text>
      </Flex>
      {children && (
        <Flex justifyContent="end" pl="5">
          {children}
        </Flex>
      )}
    </Flex>
  );
};
