import { myApi as api } from "../common/redux/my-api";
export const addTagTypes = [
  "Organization",
  "Misc",
  "SourcingEvent",
  "Bid",
  "EmailInvite",
  "Upload",
  "Product",
  "Contract",
  "TextDocument",
  "Document",
  "Industry",
  "Notification",
  "Me",
  "Project",
  "Scim",
  "Search",
] as const;
const injectedRtkApi = api
  .enhanceEndpoints({
    addTagTypes,
  })
  .injectEndpoints({
    endpoints: (build) => ({
      adminListAllEntities: build.query<
        AdminListAllEntitiesApiResponse,
        AdminListAllEntitiesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/admin/all-entities`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: { entityType: queryArg.entityType },
        }),
        providesTags: ["Organization"],
      }),
      adminListOrganizations: build.query<
        AdminListOrganizationsApiResponse,
        AdminListOrganizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/admin/orgs`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Organization"],
      }),
      adminUpdateOrganization: build.mutation<
        AdminUpdateOrganizationApiResponse,
        AdminUpdateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/admin/orgs/${queryArg.orgId}`,
          method: "PATCH",
          body: queryArg.adminUpdateOrganizationRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      getBaseData: build.query<GetBaseDataApiResponse, GetBaseDataApiArg>({
        query: () => ({ url: `/api/base-data` }),
        providesTags: ["Misc"],
      }),
      getTypes: build.query<GetTypesApiResponse, GetTypesApiArg>({
        query: () => ({ url: `/api/base-data/types` }),
        providesTags: ["Misc"],
      }),
      createBasicSourcingEvent: build.mutation<
        CreateBasicSourcingEventApiResponse,
        CreateBasicSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/deps/${queryArg.depId}/events`,
          method: "POST",
          body: queryArg.createSourcingEventRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      getNegotiationRound: build.query<
        GetNegotiationRoundApiResponse,
        GetNegotiationRoundApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/negotiation/${queryArg.negotiationId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["SourcingEvent"],
      }),
      getBasicSourcingEvent: build.query<
        GetBasicSourcingEventApiResponse,
        GetBasicSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["SourcingEvent"],
      }),
      updateBasicSourcingEvent: build.mutation<
        UpdateBasicSourcingEventApiResponse,
        UpdateBasicSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}`,
          method: "PUT",
          body: queryArg.editSourcingEventRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      awardToBid: build.mutation<AwardToBidApiResponse, AwardToBidApiArg>({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/award`,
          method: "POST",
          body: queryArg.awardToBidRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      listReceivedBseBids: build.query<
        ListReceivedBseBidsApiResponse,
        ListReceivedBseBidsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/bids`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Bid"],
      }),
      getReceivedBseBid: build.query<
        GetReceivedBseBidApiResponse,
        GetReceivedBseBidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/bids/${queryArg.bidId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Bid"],
      }),
      completeEvaluation: build.mutation<
        CompleteEvaluationApiResponse,
        CompleteEvaluationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/complete`,
          method: "POST",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      createSourcingEventCopy: build.mutation<
        CreateSourcingEventCopyApiResponse,
        CreateSourcingEventCopyApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/copy`,
          method: "POST",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      createEmailInviteReminder: build.mutation<
        CreateEmailInviteReminderApiResponse,
        CreateEmailInviteReminderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/invites/${queryArg.inviteId}/reminders`,
          method: "POST",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      createNegotiationRound: build.mutation<
        CreateNegotiationRoundApiResponse,
        CreateNegotiationRoundApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/negotiation`,
          method: "POST",
          body: queryArg.createNegotiationRoundRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      openCompletedEvent: build.mutation<
        OpenCompletedEventApiResponse,
        OpenCompletedEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/open`,
          method: "POST",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      publishBasicSourcingEvent: build.mutation<
        PublishBasicSourcingEventApiResponse,
        PublishBasicSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/publishing`,
          method: "PUT",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      updateBseQuestion: build.mutation<
        UpdateBseQuestionApiResponse,
        UpdateBseQuestionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/questions/${queryArg.questionId}`,
          method: "PATCH",
          body: queryArg.updateBseQuestionRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      createBseQuestionCommentAsBuyer: build.mutation<
        CreateBseQuestionCommentAsBuyerApiResponse,
        CreateBseQuestionCommentAsBuyerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/questions/${queryArg.questionId}/comments`,
          method: "POST",
          body: queryArg.createBseQuestionCommentRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      createUploadForBse: build.mutation<
        CreateUploadForBseApiResponse,
        CreateUploadForBseApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/basic/events/${queryArg.eventId}/uploads/${queryArg.uploadId}`,
          method: "POST",
          body: queryArg.createUploadRequestDto,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Upload"],
      }),
      deleteSourcingEvent: build.mutation<
        DeleteSourcingEventApiResponse,
        DeleteSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/events/${queryArg.eventId}`,
          method: "DELETE",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      getProductGroupsForSourcingEvent: build.query<
        GetProductGroupsForSourcingEventApiResponse,
        GetProductGroupsForSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/products/sourcing/groups/${queryArg.sourcingEventId}`,
        }),
        providesTags: ["Product", "SourcingEvent"],
      }),
      listEventsForBuyer: build.query<
        ListEventsForBuyerApiResponse,
        ListEventsForBuyerApiArg
      >({
        query: (queryArg) => ({
          url: `/api/buyer/${queryArg.orgId}/events`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: {
            status: queryArg.status,
            projectId: queryArg.projectId,
            name: queryArg.name,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["SourcingEvent"],
      }),
      createContractFromSourcingEvent: build.mutation<
        CreateContractFromSourcingEventApiResponse,
        CreateContractFromSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/from-sourcing-event`,
          method: "POST",
          body: queryArg.createContractFromSourcingEventRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract", "SourcingEvent"],
      }),
      deleteCustomContractDataField: build.mutation<
        DeleteCustomContractDataFieldApiResponse,
        DeleteCustomContractDataFieldApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/organization/custom-field/${queryArg.orgId}`,
          method: "DELETE",
          body: queryArg.deleteCustomFieldDto,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract", "Organization"],
      }),
      upsertCustomContractDataField: build.mutation<
        UpsertCustomContractDataFieldApiResponse,
        UpsertCustomContractDataFieldApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/organization/custom-field/${queryArg.orgId}`,
          method: "PUT",
          body: queryArg.upsertCustomContractFieldDto,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      upsertContractDataFieldsSection: build.mutation<
        UpsertContractDataFieldsSectionApiResponse,
        UpsertContractDataFieldsSectionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/organization/fields-section/${queryArg.orgId}`,
          method: "POST",
          body: queryArg.contractDataFieldSectionDto,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      signatureWebhook: build.mutation<
        SignatureWebhookApiResponse,
        SignatureWebhookApiArg
      >({
        query: () => ({
          url: `/api/contract/signature/events`,
          method: "POST",
        }),
        invalidatesTags: ["Contract"],
      }),
      deleteContractTaskComment: build.mutation<
        DeleteContractTaskCommentApiResponse,
        DeleteContractTaskCommentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/task/comments/${queryArg.commentId}`,
          method: "DELETE",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      updateContractTaskComment: build.mutation<
        UpdateContractTaskCommentApiResponse,
        UpdateContractTaskCommentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/task/comments/${queryArg.commentId}`,
          method: "PATCH",
          body: queryArg.updateContractTaskCommentRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      deleteContractTask: build.mutation<
        DeleteContractTaskApiResponse,
        DeleteContractTaskApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/tasks/${queryArg.taskId}`,
          method: "DELETE",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      updateContractTask: build.mutation<
        UpdateContractTaskApiResponse,
        UpdateContractTaskApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/tasks/${queryArg.taskId}`,
          method: "PATCH",
          body: queryArg.updateTaskRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      listChangesForContractTask: build.query<
        ListChangesForContractTaskApiResponse,
        ListChangesForContractTaskApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/tasks/${queryArg.taskId}/changes`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      listCommentsForContractTask: build.query<
        ListCommentsForContractTaskApiResponse,
        ListCommentsForContractTaskApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/tasks/${queryArg.taskId}/comments`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      createContractTaskComment: build.mutation<
        CreateContractTaskCommentApiResponse,
        CreateContractTaskCommentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/tasks/${queryArg.taskId}/comments`,
          method: "POST",
          body: queryArg.createContractTaskCommentRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      createContractTextDocument: build.mutation<
        CreateContractTextDocumentApiResponse,
        CreateContractTextDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/text-documents`,
          method: "POST",
          body: queryArg.createContractTextDocumentRequest,
        }),
        invalidatesTags: ["Contract", "TextDocument"],
      }),
      listTextDocumentsForContract: build.query<
        ListTextDocumentsForContractApiResponse,
        ListTextDocumentsForContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contract/text-documents/${queryArg.contractId}`,
        }),
        providesTags: ["Contract", "TextDocument"],
      }),
      downloadMultiIfrsReport: build.query<
        DownloadMultiIfrsReportApiResponse,
        DownloadMultiIfrsReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/ifrs-report`,
          params: { contractIds: queryArg.contractIds },
        }),
        providesTags: ["Contract"],
      }),
      getContractStatistics: build.query<
        GetContractStatisticsApiResponse,
        GetContractStatisticsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/orgs/${queryArg.orgId}/statistics`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      cancelSignedDocument: build.mutation<
        CancelSignedDocumentApiResponse,
        CancelSignedDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/${queryArg.contractId}/cancel-signed-document`,
          method: "POST",
          body: queryArg.cancelSignedDocumentRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      downloadIfrsReport: build.query<
        DownloadIfrsReportApiResponse,
        DownloadIfrsReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/${queryArg.contractId}/ifrs-report`,
        }),
        providesTags: ["Contract"],
      }),
      deleteContractParticipant: build.mutation<
        DeleteContractParticipantApiResponse,
        DeleteContractParticipantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/${queryArg.contractId}/participant`,
          method: "DELETE",
          body: queryArg.deleteContractParticipantRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      listParticipantsForContracts: build.query<
        ListParticipantsForContractsApiResponse,
        ListParticipantsForContractsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/${queryArg.contractId}/participants`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      createContractParticipant: build.mutation<
        CreateContractParticipantApiResponse,
        CreateContractParticipantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/${queryArg.contractId}/participants`,
          method: "POST",
          body: queryArg.createContractParticipantRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      createContractTask: build.mutation<
        CreateContractTaskApiResponse,
        CreateContractTaskApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/${queryArg.contractId}/tasks`,
          method: "POST",
          body: queryArg.createContractTaskRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      createUploadForContract: build.mutation<
        CreateUploadForContractApiResponse,
        CreateUploadForContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/contracts/${queryArg.contractId}/uploads/${queryArg.uploadId}`,
          method: "POST",
          body: queryArg.createUploadRequestDto,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Upload"],
      }),
      listContractFilterOptions: build.query<
        ListContractFilterOptionsApiResponse,
        ListContractFilterOptionsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/deps/${queryArg.orgId}/contract/filter-options`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      listContracts: build.query<ListContractsApiResponse, ListContractsApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/deps/${queryArg.orgId}/contracts`,
            headers: {
              cookie: queryArg.cookie,
              organizationId: queryArg.organizationId,
              personId: queryArg.personId,
            },
            params: {
              title: queryArg.title,
              state: queryArg.state,
              statuses: queryArg.statuses,
              counterparties: queryArg.counterparties,
              owners: queryArg.owners,
              templates: queryArg.templates,
              internalparties: queryArg.internalparties,
              projects: queryArg.projects,
              limit: queryArg.limit,
              skip: queryArg.skip,
            },
          }),
          providesTags: ["Contract"],
        }
      ),
      sendAiChatMessage: build.mutation<
        SendAiChatMessageApiResponse,
        SendAiChatMessageApiArg
      >({
        query: (queryArg) => ({
          url: `/api/docs/analysis`,
          method: "POST",
          body: queryArg.chatCompletionRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Document"],
      }),
      updateDocumentName: build.mutation<
        UpdateDocumentNameApiResponse,
        UpdateDocumentNameApiArg
      >({
        query: (queryArg) => ({
          url: `/api/docs/${queryArg.docId}`,
          method: "PUT",
          body: queryArg.updateDocumentNameRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Document"],
      }),
      startDocumentChat: build.mutation<
        StartDocumentChatApiResponse,
        StartDocumentChatApiArg
      >({
        query: (queryArg) => ({
          url: `/api/docs/${queryArg.docId}/analysis`,
          method: "POST",
          body: queryArg.chatCompletionRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Document"],
      }),
      getDownloadLink: build.query<
        GetDownloadLinkApiResponse,
        GetDownloadLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/docs/${queryArg.docId}/link`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Document"],
      }),
      listDocumentTemplates: build.query<
        ListDocumentTemplatesApiResponse,
        ListDocumentTemplatesApiArg
      >({
        query: () => ({ url: `/api/document-template/text-documents` }),
        providesTags: ["TextDocument"],
      }),
      createDocumentTemplate: build.mutation<
        CreateDocumentTemplateApiResponse,
        CreateDocumentTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/document-template/text-documents`,
          method: "POST",
          body: queryArg.createDocumentTemplateRequest,
        }),
        invalidatesTags: ["TextDocument"],
      }),
      downloadTextDocument: build.mutation<
        DownloadTextDocumentApiResponse,
        DownloadTextDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/download/text-documents`,
          method: "POST",
          body: queryArg.downloadTextDocumentRequest,
        }),
        invalidatesTags: ["TextDocument"],
      }),
      updateEmailStatus: build.mutation<
        UpdateEmailStatusApiResponse,
        UpdateEmailStatusApiArg
      >({
        query: () => ({ url: `/api/email/status/events`, method: "POST" }),
        invalidatesTags: ["Misc"],
      }),
      confirmUpload: build.mutation<
        ConfirmUploadApiResponse,
        ConfirmUploadApiArg
      >({
        query: () => ({
          url: `/api/file-uploads/successful-upload-webhook`,
          method: "POST",
        }),
        invalidatesTags: ["Upload"],
      }),
      searchIndustries: build.query<
        SearchIndustriesApiResponse,
        SearchIndustriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/industries`,
          params: { name: queryArg.name },
        }),
        providesTags: ["Industry"],
      }),
      listMyNotifications: build.query<
        ListMyNotificationsApiResponse,
        ListMyNotificationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/me/notifications`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: {
            limit: queryArg.limit,
            offset: queryArg.offset,
            unseen: queryArg.unseen,
          },
        }),
        providesTags: ["Notification"],
      }),
      listOrganizationUsers: build.query<
        ListOrganizationUsersApiResponse,
        ListOrganizationUsersApiArg
      >({
        query: (queryArg) => ({
          url: `/api/me/organization-users`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: {
            name: queryArg.name,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["Organization"],
      }),
      listOrganizationsForMe: build.query<
        ListOrganizationsForMeApiResponse,
        ListOrganizationsForMeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/me/orgs`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Organization"],
      }),
      createOrganizationForMe: build.mutation<
        CreateOrganizationForMeApiResponse,
        CreateOrganizationForMeApiArg
      >({
        query: (queryArg) => ({
          url: `/api/me/orgs`,
          method: "POST",
          body: queryArg.createOrgRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      markNotificationsAsPeeked: build.mutation<
        MarkNotificationsAsPeekedApiResponse,
        MarkNotificationsAsPeekedApiArg
      >({
        query: (queryArg) => ({
          url: `/api/me/peek/notifications`,
          method: "POST",
          body: queryArg.markNotificationsRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Notification"],
      }),
      markNotificationsAsViewed: build.mutation<
        MarkNotificationsAsViewedApiResponse,
        MarkNotificationsAsViewedApiArg
      >({
        query: (queryArg) => ({
          url: `/api/me/view/notifications`,
          method: "POST",
          body: queryArg.markNotificationsRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Notification"],
      }),
      getAccessToken: build.mutation<
        GetAccessTokenApiResponse,
        GetAccessTokenApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/access-token`,
          method: "POST",
          body: queryArg.getAccessTokenRequest,
        }),
        invalidatesTags: ["Organization"],
      }),
      searchOpenOrgContactInfo: build.query<
        SearchOpenOrgContactInfoApiResponse,
        SearchOpenOrgContactInfoApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/contact-info`,
          params: { name: queryArg.name, number: queryArg["number"] },
        }),
        providesTags: ["Organization"],
      }),
      listContractsForOrg: build.query<
        ListContractsForOrgApiResponse,
        ListContractsForOrgApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/contracts`,
          params: {
            title: queryArg.title,
            state: queryArg.state,
            statuses: queryArg.statuses,
            counterparties: queryArg.counterparties,
            owners: queryArg.owners,
            templates: queryArg.templates,
            internalparties: queryArg.internalparties,
            projects: queryArg.projects,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["Contract"],
      }),
      createApiClient: build.mutation<
        CreateApiClientApiResponse,
        CreateApiClientApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/create-api-client`,
          method: "POST",
          headers: { cookie: queryArg.cookie },
        }),
        invalidatesTags: ["Organization"],
      }),
      createOrganizationEntry: build.mutation<
        CreateOrganizationEntryApiResponse,
        CreateOrganizationEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/entries`,
          method: "POST",
          body: queryArg.createOrganizationEntryRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      getOrganizationEntry: build.query<
        GetOrganizationEntryApiResponse,
        GetOrganizationEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/entries/${queryArg.entryId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Organization"],
      }),
      updateOrganizationEntry: build.mutation<
        UpdateOrganizationEntryApiResponse,
        UpdateOrganizationEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/entries/${queryArg.entryId}`,
          method: "PUT",
          body: queryArg.updateOrganizationEntryRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      linkOrganizationEntry: build.mutation<
        LinkOrganizationEntryApiResponse,
        LinkOrganizationEntryApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/entries/${queryArg.entryId}/link`,
          method: "PUT",
          body: queryArg.linkOrganizationEntryRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      searchOrganizations: build.query<
        SearchOrganizationsApiResponse,
        SearchOrganizationsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/public-profiles`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: { name: queryArg.name, industries: queryArg.industries },
        }),
        providesTags: ["Organization"],
      }),
      getSupplierEmailInvite: build.query<
        GetSupplierEmailInviteApiResponse,
        GetSupplierEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/supplier-invites/${queryArg.inviteId}`,
          params: { secret: queryArg.secret },
        }),
        providesTags: ["EmailInvite"],
      }),
      getOrgUserEmailInvite: build.query<
        GetOrgUserEmailInviteApiResponse,
        GetOrgUserEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/user-invites/${queryArg.inviteId}`,
          params: { secret: queryArg.secret },
        }),
        providesTags: ["EmailInvite"],
      }),
      acceptOrgUserEmailInvite: build.mutation<
        AcceptOrgUserEmailInviteApiResponse,
        AcceptOrgUserEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/org/user-invites/${queryArg.inviteId}/acceptance`,
          method: "PUT",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: { secret: queryArg.secret },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      updateOrganization: build.mutation<
        UpdateOrganizationApiResponse,
        UpdateOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}`,
          method: "PATCH",
          body: queryArg.editOrganizationRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      getOrganization: build.query<
        GetOrganizationApiResponse,
        GetOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/public-profile`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Organization"],
      }),
      listSupplierEmailInvites: build.query<
        ListSupplierEmailInvitesApiResponse,
        ListSupplierEmailInvitesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/supplier-invites`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["EmailInvite"],
      }),
      createSupplierEmailInvite: build.mutation<
        CreateSupplierEmailInviteApiResponse,
        CreateSupplierEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/supplier-invites`,
          method: "POST",
          body: queryArg.createEmailInviteRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      deleteSupplierEmailInvite: build.mutation<
        DeleteSupplierEmailInviteApiResponse,
        DeleteSupplierEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/supplier-invites/${queryArg.inviteId}`,
          method: "DELETE",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      createSupplierEmailInviteReminder: build.mutation<
        CreateSupplierEmailInviteReminderApiResponse,
        CreateSupplierEmailInviteReminderApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/supplier-invites/${queryArg.inviteId}/reminders`,
          method: "POST",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      updateOrganizationTenant: build.mutation<
        UpdateOrganizationTenantApiResponse,
        UpdateOrganizationTenantApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/tenant`,
          method: "PUT",
          body: queryArg.updateOrgTenantRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      listOrgUserEmailInvites: build.query<
        ListOrgUserEmailInvitesApiResponse,
        ListOrgUserEmailInvitesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/user-invites`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["EmailInvite"],
      }),
      createOrgUserEmailInvite: build.mutation<
        CreateOrgUserEmailInviteApiResponse,
        CreateOrgUserEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/user-invites`,
          method: "POST",
          body: queryArg.createOrgUserEmailInviteRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      deleteOrgUserEmailInvite: build.mutation<
        DeleteOrgUserEmailInviteApiResponse,
        DeleteOrgUserEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/user-invites/${queryArg.inviteId}`,
          method: "DELETE",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      updateOrgUserRole: build.mutation<
        UpdateOrgUserRoleApiResponse,
        UpdateOrgUserRoleApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/users/${queryArg.personId}/role`,
          method: "PUT",
          body: queryArg.updateOrgUserRoleRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      deleteOrgUser: build.mutation<
        DeleteOrgUserApiResponse,
        DeleteOrgUserApiArg
      >({
        query: (queryArg) => ({
          url: `/api/orgs/${queryArg.orgId}/users/${queryArg.userId}`,
          method: "PUT",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Organization"],
      }),
      listContractTasksForOrganization: build.query<
        ListContractTasksForOrganizationApiResponse,
        ListContractTasksForOrganizationApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contract/orgs/${queryArg.orgId}/tasks`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: {
            limit: queryArg.limit,
            statuses: queryArg.statuses,
            personIds: queryArg.personIds,
            contractIds: queryArg.contractIds,
          },
        }),
        providesTags: ["Contract"],
      }),
      listContractTemplates: build.query<
        ListContractTemplatesApiResponse,
        ListContractTemplatesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contract/orgs/${queryArg.orgId}/templates`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: { contractId: queryArg.contractId },
        }),
        providesTags: ["Contract"],
      }),
      createContractTemplate: build.mutation<
        CreateContractTemplateApiResponse,
        CreateContractTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contract/orgs/${queryArg.orgId}/templates`,
          method: "POST",
          body: queryArg.upsertContractTemplateRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      getTask: build.query<GetTaskApiResponse, GetTaskApiArg>({
        query: (queryArg) => ({
          url: `/api/owner/contract/tasks/${queryArg.taskId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      updateContractTemplate: build.mutation<
        UpdateContractTemplateApiResponse,
        UpdateContractTemplateApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contract/templates/${queryArg.templateId}`,
          method: "PATCH",
          body: queryArg.upsertContractTemplateRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      getContract: build.query<GetContractApiResponse, GetContractApiArg>({
        query: (queryArg) => ({
          url: `/api/owner/contracts/${queryArg.contractId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      updateContract: build.mutation<
        UpdateContractApiResponse,
        UpdateContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contracts/${queryArg.contractId}`,
          method: "POST",
          body: queryArg.updateContractRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      publishContract: build.mutation<
        PublishContractApiResponse,
        PublishContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contracts/${queryArg.contractId}/publishing`,
          method: "PUT",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      createSignedDocument: build.mutation<
        CreateSignedDocumentApiResponse,
        CreateSignedDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contracts/${queryArg.contractId}/signed-documents`,
          method: "POST",
          body: queryArg.createSignedDocumentRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      listContractTasks: build.query<
        ListContractTasksApiResponse,
        ListContractTasksApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/contracts/${queryArg.contractId}/tasks`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Contract"],
      }),
      createContract: build.mutation<
        CreateContractApiResponse,
        CreateContractApiArg
      >({
        query: (queryArg) => ({
          url: `/api/owner/deps/${queryArg.depId}/contracts`,
          method: "POST",
          body: queryArg.createContractRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Contract"],
      }),
      listPersons: build.query<ListPersonsApiResponse, ListPersonsApiArg>({
        query: (queryArg) => ({
          url: `/api/persons`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: {
            orgIds: queryArg.orgIds,
            name: queryArg.name,
            roles: queryArg.roles,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["Organization"],
      }),
      getMe: build.query<GetMeApiResponse, GetMeApiArg>({
        query: (queryArg) => ({
          url: `/api/persons/me`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Me"],
      }),
      updateMe: build.mutation<UpdateMeApiResponse, UpdateMeApiArg>({
        query: (queryArg) => ({
          url: `/api/persons/me`,
          method: "PATCH",
          body: queryArg.editMeRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Me"],
      }),
      editProductGroup: build.mutation<
        EditProductGroupApiResponse,
        EditProductGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/group`,
          method: "PATCH",
          body: queryArg.editProductGroupDto,
        }),
        invalidatesTags: ["Product", "SourcingEvent"],
      }),
      createProductGroup: build.mutation<
        CreateProductGroupApiResponse,
        CreateProductGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/group`,
          method: "POST",
          body: queryArg.createProductGroupDto,
        }),
        invalidatesTags: ["Product", "SourcingEvent"],
      }),
      deleteProductGroup: build.mutation<
        DeleteProductGroupApiResponse,
        DeleteProductGroupApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/group/${queryArg.productGroupId}`,
          method: "DELETE",
        }),
        invalidatesTags: ["Product", "SourcingEvent"],
      }),
      getProductGroupsForProject: build.query<
        GetProductGroupsForProjectApiResponse,
        GetProductGroupsForProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/api/products/project/groups/${queryArg.projectId}`,
        }),
        providesTags: ["Product", "Project"],
      }),
      getProductTemplates: build.query<
        GetProductTemplatesApiResponse,
        GetProductTemplatesApiArg
      >({
        query: () => ({ url: `/api/products/templates` }),
        providesTags: ["Product"],
      }),
      updateProject: build.mutation<
        UpdateProjectApiResponse,
        UpdateProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/api/project`,
          method: "PATCH",
          body: queryArg.updateProjectRequest,
        }),
        invalidatesTags: ["Project"],
      }),
      createProject: build.mutation<
        CreateProjectApiResponse,
        CreateProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/api/project`,
          method: "POST",
          body: queryArg.createProjectRequest,
        }),
        invalidatesTags: ["Project"],
      }),
      createProjectTextDocument: build.mutation<
        CreateProjectTextDocumentApiResponse,
        CreateProjectTextDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/project/text-document`,
          method: "POST",
          body: queryArg.createProjectTextDocumentRequest,
        }),
        invalidatesTags: ["Project", "TextDocument"],
      }),
      listTextDocumentsForProject: build.query<
        ListTextDocumentsForProjectApiResponse,
        ListTextDocumentsForProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/api/project/text-documents/${queryArg.projectId}`,
        }),
        providesTags: ["Project", "TextDocument"],
      }),
      getProject: build.query<GetProjectApiResponse, GetProjectApiArg>({
        query: (queryArg) => ({ url: `/api/project/${queryArg.projectId}` }),
        providesTags: ["Project"],
      }),
      getProjects: build.query<GetProjectsApiResponse, GetProjectsApiArg>({
        query: (queryArg) => ({
          url: `/api/projects`,
          params: {
            name: queryArg.name,
            status: queryArg.status,
            limit: queryArg.limit,
            skip: queryArg.skip,
          },
        }),
        providesTags: ["Project"],
      }),
      createUploadForProject: build.mutation<
        CreateUploadForProjectApiResponse,
        CreateUploadForProjectApiArg
      >({
        query: (queryArg) => ({
          url: `/api/projects/${queryArg.projectId}/uploads/${queryArg.uploadId}`,
          method: "POST",
          body: queryArg.createUploadRequestDto,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Upload"],
      }),
      getGroups: build.query<GetGroupsApiResponse, GetGroupsApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Groups`,
          headers: { authorization: queryArg.authorization },
        }),
        providesTags: ["Scim"],
      }),
      createGroup: build.mutation<CreateGroupApiResponse, CreateGroupApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Groups`,
          method: "POST",
          body: queryArg.createGroupDto,
          headers: { authorization: queryArg.authorization },
        }),
        invalidatesTags: ["Scim"],
      }),
      deleteGroup: build.mutation<DeleteGroupApiResponse, DeleteGroupApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Groups/${queryArg.groupId}`,
          method: "DELETE",
          headers: { authorization: queryArg.authorization },
        }),
        invalidatesTags: ["Scim"],
      }),
      getGroup: build.query<GetGroupApiResponse, GetGroupApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Groups/${queryArg.groupId}`,
          headers: { authorization: queryArg.authorization },
        }),
        providesTags: ["Scim"],
      }),
      updateGroup: build.mutation<UpdateGroupApiResponse, UpdateGroupApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Groups/${queryArg.groupId}`,
          method: "PATCH",
          body: queryArg.body,
          headers: { authorization: queryArg.authorization },
        }),
        invalidatesTags: ["Scim"],
      }),
      getSchemas: build.query<GetSchemasApiResponse, GetSchemasApiArg>({
        query: () => ({ url: `/api/scim/Schemas` }),
        providesTags: ["Scim"],
      }),
      getToken: build.query<GetTokenApiResponse, GetTokenApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Token`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
            authorization: queryArg.authorization,
          },
        }),
        providesTags: ["Scim"],
      }),
      getUsers: build.query<GetUsersApiResponse, GetUsersApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Users`,
          headers: { authorization: queryArg.authorization },
        }),
        providesTags: ["Scim"],
      }),
      createUser: build.mutation<CreateUserApiResponse, CreateUserApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Users`,
          method: "POST",
          body: queryArg.body,
          headers: { authorization: queryArg.authorization },
        }),
        invalidatesTags: ["Scim"],
      }),
      deleteUser: build.mutation<DeleteUserApiResponse, DeleteUserApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Users/${queryArg.userId}`,
          method: "DELETE",
          headers: { authorization: queryArg.authorization },
        }),
        invalidatesTags: ["Scim"],
      }),
      getUser: build.query<GetUserApiResponse, GetUserApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Users/${queryArg.userId}`,
          headers: { authorization: queryArg.authorization },
        }),
        providesTags: ["Scim"],
      }),
      updateUser: build.mutation<UpdateUserApiResponse, UpdateUserApiArg>({
        query: (queryArg) => ({
          url: `/api/scim/Users/${queryArg.userId}`,
          method: "PATCH",
          headers: { authorization: queryArg.authorization },
        }),
        invalidatesTags: ["Scim"],
      }),
      searchOrganizationsAndEntries: build.query<
        SearchOrganizationsAndEntriesApiResponse,
        SearchOrganizationsAndEntriesApiArg
      >({
        query: (queryArg) => ({
          url: `/api/search/orgs`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: {
            name: queryArg.name,
            limit: queryArg.limit,
            skip: queryArg.skip,
            type: queryArg["type"],
          },
        }),
        providesTags: ["Search"],
      }),
      getSignedDocumentDownloadLink: build.query<
        GetSignedDocumentDownloadLinkApiResponse,
        GetSignedDocumentDownloadLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/signed/docs/${queryArg.docId}/link`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Document"],
      }),
      getSignedAttachmentDownloadLink: build.query<
        GetSignedAttachmentDownloadLinkApiResponse,
        GetSignedAttachmentDownloadLinkApiArg
      >({
        query: (queryArg) => ({
          url: `/api/signed/docs/${queryArg.docId}/link/${queryArg.attachmentId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Document"],
      }),
      createSourcingEventTextDocument: build.mutation<
        CreateSourcingEventTextDocumentApiResponse,
        CreateSourcingEventTextDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/sourcing-event/text-documents`,
          method: "POST",
          body: queryArg.createSourcingEventTextDocumentRequest,
        }),
        invalidatesTags: ["SourcingEvent", "TextDocument"],
      }),
      listTextDocumentsForSourcingEvent: build.query<
        ListTextDocumentsForSourcingEventApiResponse,
        ListTextDocumentsForSourcingEventApiArg
      >({
        query: (queryArg) => ({
          url: `/api/sourcing-event/text-documents/${queryArg.eventId}`,
        }),
        providesTags: ["SourcingEvent", "TextDocument"],
      }),
      getBseBid: build.query<GetBseBidApiResponse, GetBseBidApiArg>({
        query: (queryArg) => ({
          url: `/api/supplier/basic/bids/${queryArg.bidId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Bid"],
      }),
      updateBseBid: build.mutation<UpdateBseBidApiResponse, UpdateBseBidApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/supplier/basic/bids/${queryArg.bidId}`,
            method: "PATCH",
            body: queryArg.editBidRequest,
            headers: {
              cookie: queryArg.cookie,
              organizationId: queryArg.organizationId,
              personId: queryArg.personId,
            },
          }),
          invalidatesTags: ["Bid"],
        }
      ),
      createUploadForBseBid: build.mutation<
        CreateUploadForBseBidApiResponse,
        CreateUploadForBseBidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/bids/${queryArg.bidId}/uploads/${queryArg.uploadId}`,
          method: "POST",
          body: queryArg.createUploadRequestDto,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Upload"],
      }),
      createBseBidDraftAsDepartment: build.mutation<
        CreateBseBidDraftAsDepartmentApiResponse,
        CreateBseBidDraftAsDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/deps/${queryArg.depId}/events/${queryArg.eventId}/bids`,
          method: "POST",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Bid"],
      }),
      getBseSupplierView: build.query<
        GetBseSupplierViewApiResponse,
        GetBseSupplierViewApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["SourcingEvent"],
      }),
      createBseAnnouncement: build.mutation<
        CreateBseAnnouncementApiResponse,
        CreateBseAnnouncementApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/announcements`,
          method: "POST",
          body: queryArg.createBseAnnouncementRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      unDeliverBseBid: build.mutation<
        UnDeliverBseBidApiResponse,
        UnDeliverBseBidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/bids/${queryArg.bidId}/delivery`,
          method: "DELETE",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Bid"],
      }),
      deliverBseBid: build.mutation<
        DeliverBseBidApiResponse,
        DeliverBseBidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/bids/${queryArg.bidId}/delivery`,
          method: "PUT",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Bid"],
      }),
      acceptInvitationToBid: build.mutation<
        AcceptInvitationToBidApiResponse,
        AcceptInvitationToBidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/invites`,
          method: "PATCH",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      declineInvitationToBid: build.mutation<
        DeclineInvitationToBidApiResponse,
        DeclineInvitationToBidApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/invites/decline`,
          method: "PATCH",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      downloadPublicProcurementProtocolPartOne: build.mutation<
        DownloadPublicProcurementProtocolPartOneApiResponse,
        DownloadPublicProcurementProtocolPartOneApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/public-procurement-protocol-part-one`,
          method: "POST",
          body: queryArg.getProcurementProtocolRequest,
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      downloadQaReport: build.query<
        DownloadQaReportApiResponse,
        DownloadQaReportApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/qa-report`,
        }),
        providesTags: ["SourcingEvent"],
      }),
      createBseQuestion: build.mutation<
        CreateBseQuestionApiResponse,
        CreateBseQuestionApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/questions`,
          method: "POST",
          body: queryArg.createBseQuestionRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      createBseQuestionCommentAsSupplier: build.mutation<
        CreateBseQuestionCommentAsSupplierApiResponse,
        CreateBseQuestionCommentAsSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/basic/events/${queryArg.eventId}/questions/${queryArg.questionId}/comments`,
          method: "POST",
          body: queryArg.createBseQuestionCommentRequest,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["SourcingEvent"],
      }),
      markAsViewed: build.mutation<MarkAsViewedApiResponse, MarkAsViewedApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/supplier/basic/events/${queryArg.eventId}/viewing`,
            method: "PUT",
            headers: {
              cookie: queryArg.cookie,
              organizationId: queryArg.organizationId,
              personId: queryArg.personId,
            },
          }),
          invalidatesTags: ["SourcingEvent"],
        }
      ),
      deleteBid: build.mutation<DeleteBidApiResponse, DeleteBidApiArg>({
        query: (queryArg) => ({
          url: `/api/supplier/bids/${queryArg.bidId}`,
          method: "DELETE",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        invalidatesTags: ["Bid"],
      }),
      listBidsCreatedByDepartment: build.query<
        ListBidsCreatedByDepartmentApiResponse,
        ListBidsCreatedByDepartmentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/deps/${queryArg.depId}/bids`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
        }),
        providesTags: ["Bid"],
      }),
      getSeEmailInvite: build.query<
        GetSeEmailInviteApiResponse,
        GetSeEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/event/invites/${queryArg.inviteId}`,
          params: { secret: queryArg.secret },
        }),
        providesTags: ["EmailInvite"],
      }),
      acceptSeEmailInvite: build.mutation<
        AcceptSeEmailInviteApiResponse,
        AcceptSeEmailInviteApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/event/invites/${queryArg.inviteId}/acceptance`,
          method: "PUT",
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: { secret: queryArg.secret },
        }),
        invalidatesTags: ["EmailInvite"],
      }),
      listSeSupplierViews: build.query<
        ListSeSupplierViewsApiResponse,
        ListSeSupplierViewsApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/orgs/${queryArg.orgId}/events`,
          headers: {
            cookie: queryArg.cookie,
            organizationId: queryArg.organizationId,
            personId: queryArg.personId,
          },
          params: { status: queryArg.status },
        }),
        providesTags: ["SourcingEvent"],
      }),
      getProductGroupsAsSupplier: build.query<
        GetProductGroupsAsSupplierApiResponse,
        GetProductGroupsAsSupplierApiArg
      >({
        query: (queryArg) => ({
          url: `/api/supplier/products/sourcing/groups/${queryArg.sourcingEventId}`,
        }),
        providesTags: ["Product", "SourcingEvent"],
      }),
      updateTextDocument: build.mutation<
        UpdateTextDocumentApiResponse,
        UpdateTextDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/text-documents`,
          method: "PUT",
          body: queryArg.updateTextDocumentRequest,
        }),
        invalidatesTags: ["TextDocument"],
      }),
      deleteTextDocument: build.mutation<
        DeleteTextDocumentApiResponse,
        DeleteTextDocumentApiArg
      >({
        query: (queryArg) => ({
          url: `/api/text-documents/${queryArg.id}`,
          method: "DELETE",
        }),
        invalidatesTags: ["TextDocument"],
      }),
      deleteUpload: build.mutation<DeleteUploadApiResponse, DeleteUploadApiArg>(
        {
          query: (queryArg) => ({
            url: `/api/uploads/${queryArg.uploadId}`,
            method: "DELETE",
            headers: {
              cookie: queryArg.cookie,
              organizationId: queryArg.organizationId,
              personId: queryArg.personId,
            },
          }),
          invalidatesTags: ["Upload"],
        }
      ),
    }),
    overrideExisting: false,
  });
export { injectedRtkApi as bffApi };
export type AdminListAllEntitiesApiResponse = /** status 200 OK */ string;
export type AdminListAllEntitiesApiArg = {
  entityType: AdminEntityType;
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type AdminListOrganizationsApiResponse =
  /** status 200 OK */ AdminListOrganizationsResponse;
export type AdminListOrganizationsApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type AdminUpdateOrganizationApiResponse = /** status 200 OK */ OrgDto;
export type AdminUpdateOrganizationApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  adminUpdateOrganizationRequest: AdminUpdateOrganizationRequest;
};
export type GetBaseDataApiResponse = /** status 200 OK */ BaseDataDto;
export type GetBaseDataApiArg = void;
export type GetTypesApiResponse = /** status 200 OK */ AllTypes;
export type GetTypesApiArg = void;
export type CreateBasicSourcingEventApiResponse = /** status 200 OK */ BseDto;
export type CreateBasicSourcingEventApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  depId: string;
  createSourcingEventRequest: CreateSourcingEventRequest;
};
export type GetNegotiationRoundApiResponse =
  /** status 200 OK */ NegotiationRoundDto;
export type GetNegotiationRoundApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  negotiationId: string;
};
export type GetBasicSourcingEventApiResponse = /** status 200 OK */ BseDto;
export type GetBasicSourcingEventApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type UpdateBasicSourcingEventApiResponse = /** status 200 OK */ BseDto;
export type UpdateBasicSourcingEventApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  editSourcingEventRequest: EditSourcingEventRequest;
};
export type AwardToBidApiResponse = /** status 200 OK */ BseDto;
export type AwardToBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  awardToBidRequest: AwardToBidRequest;
};
export type ListReceivedBseBidsApiResponse =
  /** status 200 OK */ ListReceivedBseBidsResponse;
export type ListReceivedBseBidsApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type GetReceivedBseBidApiResponse =
  /** status 200 OK */ ReceivedBseBidDto;
export type GetReceivedBseBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  bidId: string;
};
export type CompleteEvaluationApiResponse = /** status 200 OK */ BseDto;
export type CompleteEvaluationApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type CreateSourcingEventCopyApiResponse = /** status 200 OK */ BseDto;
export type CreateSourcingEventCopyApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type CreateEmailInviteReminderApiResponse = unknown;
export type CreateEmailInviteReminderApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  inviteId: string;
};
export type CreateNegotiationRoundApiResponse =
  /** status 200 OK */ NegotiationRoundDto;
export type CreateNegotiationRoundApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  createNegotiationRoundRequest: CreateNegotiationRoundRequest;
};
export type OpenCompletedEventApiResponse = /** status 200 OK */ BseDto;
export type OpenCompletedEventApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type PublishBasicSourcingEventApiResponse = /** status 200 OK */ BseDto;
export type PublishBasicSourcingEventApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type UpdateBseQuestionApiResponse = /** status 200 OK */ BseDto;
export type UpdateBseQuestionApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  questionId: string;
  updateBseQuestionRequest: UpdateBseQuestionRequest;
};
export type CreateBseQuestionCommentAsBuyerApiResponse =
  /** status 200 OK */ BseDto;
export type CreateBseQuestionCommentAsBuyerApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  questionId: string;
  createBseQuestionCommentRequest: CreateBseQuestionCommentRequest;
};
export type CreateUploadForBseApiResponse = /** status 200 OK */ UploadDto;
export type CreateUploadForBseApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  uploadId: string;
  createUploadRequestDto: CreateUploadRequestDto;
};
export type DeleteSourcingEventApiResponse = unknown;
export type DeleteSourcingEventApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type GetProductGroupsForSourcingEventApiResponse =
  /** status 200 OK */ ProductGroupsDto;
export type GetProductGroupsForSourcingEventApiArg = {
  sourcingEventId: string;
};
export type ListEventsForBuyerApiResponse =
  /** status 200 OK */ ListSeDtosResponse;
export type ListEventsForBuyerApiArg = {
  status: ListSourcingEventsFilterStatus;
  projectId?: string;
  name?: string;
  limit?: number;
  skip?: number;
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type CreateContractFromSourcingEventApiResponse =
  /** status 200 OK */ ContractDto;
export type CreateContractFromSourcingEventApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  createContractFromSourcingEventRequest: CreateContractFromSourcingEventRequest;
};
export type DeleteCustomContractDataFieldApiResponse = unknown;
export type DeleteCustomContractDataFieldApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  deleteCustomFieldDto: DeleteCustomFieldDto;
};
export type UpsertCustomContractDataFieldApiResponse = unknown;
export type UpsertCustomContractDataFieldApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  upsertCustomContractFieldDto: UpsertCustomContractFieldDto;
};
export type UpsertContractDataFieldsSectionApiResponse = unknown;
export type UpsertContractDataFieldsSectionApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  contractDataFieldSectionDto: ContractDataFieldSectionDto;
};
export type SignatureWebhookApiResponse = unknown;
export type SignatureWebhookApiArg = void;
export type DeleteContractTaskCommentApiResponse =
  /** status 200 OK */ ContractTaskCommentDto;
export type DeleteContractTaskCommentApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  commentId: string;
};
export type UpdateContractTaskCommentApiResponse =
  /** status 200 OK */ ContractTaskCommentDto;
export type UpdateContractTaskCommentApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  commentId: string;
  updateContractTaskCommentRequest: UpdateContractTaskCommentRequest;
};
export type DeleteContractTaskApiResponse = /** status 200 OK */ ContractDto;
export type DeleteContractTaskApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  taskId: string;
};
export type UpdateContractTaskApiResponse =
  /** status 200 OK */ ContractTaskDto;
export type UpdateContractTaskApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  taskId: string;
  updateTaskRequest: UpdateTaskRequest;
};
export type ListChangesForContractTaskApiResponse =
  /** status 200 OK */ ListChangesForContractTaskResponse;
export type ListChangesForContractTaskApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  taskId: string;
};
export type ListCommentsForContractTaskApiResponse =
  /** status 200 OK */ ListCommentsForContractTaskResponse;
export type ListCommentsForContractTaskApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  taskId: string;
};
export type CreateContractTaskCommentApiResponse =
  /** status 200 OK */ ContractTaskCommentDto;
export type CreateContractTaskCommentApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  taskId: string;
  createContractTaskCommentRequest: CreateContractTaskCommentRequest;
};
export type CreateContractTextDocumentApiResponse =
  /** status 201 Created */ TextDocumentDto;
export type CreateContractTextDocumentApiArg = {
  createContractTextDocumentRequest: CreateContractTextDocumentRequest;
};
export type ListTextDocumentsForContractApiResponse =
  /** status 200 OK */ TextDocumentsDto;
export type ListTextDocumentsForContractApiArg = {
  contractId: string;
};
export type DownloadMultiIfrsReportApiResponse = unknown;
export type DownloadMultiIfrsReportApiArg = {
  contractIds: string;
};
export type GetContractStatisticsApiResponse =
  /** status 200 OK */ ContractStatisticsDto;
export type GetContractStatisticsApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type CancelSignedDocumentApiResponse = unknown;
export type CancelSignedDocumentApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
  cancelSignedDocumentRequest: CancelSignedDocumentRequest;
};
export type DownloadIfrsReportApiResponse = unknown;
export type DownloadIfrsReportApiArg = {
  contractId: string;
};
export type DeleteContractParticipantApiResponse = unknown;
export type DeleteContractParticipantApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
  deleteContractParticipantRequest: DeleteContractParticipantRequest;
};
export type ListParticipantsForContractsApiResponse =
  /** status 200 OK */ ListParticipantsForContractResponse;
export type ListParticipantsForContractsApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
};
export type CreateContractParticipantApiResponse =
  /** status 200 OK */ ContractDto;
export type CreateContractParticipantApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
  createContractParticipantRequest: CreateContractParticipantRequest;
};
export type CreateContractTaskApiResponse = /** status 200 OK */ ContractDto;
export type CreateContractTaskApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
  createContractTaskRequest: CreateContractTaskRequest;
};
export type CreateUploadForContractApiResponse = /** status 200 OK */ UploadDto;
export type CreateUploadForContractApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
  uploadId: string;
  createUploadRequestDto: CreateUploadRequestDto;
};
export type ListContractFilterOptionsApiResponse =
  /** status 200 OK */ ContractFilterOptionsDto;
export type ListContractFilterOptionsApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type ListContractsApiResponse =
  /** status 200 OK */ ListContractsResponseDto;
export type ListContractsApiArg = {
  title?: string;
  state?: ContractStateDto;
  statuses?: string;
  counterparties?: string;
  owners?: string;
  templates?: string;
  internalparties?: string;
  projects?: string;
  limit: number;
  skip?: number;
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type SendAiChatMessageApiResponse =
  /** status 200 OK */ AnalyzeDocumentResponse;
export type SendAiChatMessageApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  chatCompletionRequest: ChatCompletionRequest;
};
export type UpdateDocumentNameApiResponse =
  /** status 200 OK */ ViewDocumentAsOwnerDto;
export type UpdateDocumentNameApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  docId: string;
  updateDocumentNameRequest: UpdateDocumentNameRequest;
};
export type StartDocumentChatApiResponse =
  /** status 200 OK */ AnalyzeDocumentResponse;
export type StartDocumentChatApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  docId: string;
  chatCompletionRequest: ChatCompletionRequest;
};
export type GetDownloadLinkApiResponse =
  /** status 200 OK */ GetDownloadLinkResponse;
export type GetDownloadLinkApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  docId: string;
};
export type ListDocumentTemplatesApiResponse =
  /** status 200 OK */ TextDocumentsDto;
export type ListDocumentTemplatesApiArg = void;
export type CreateDocumentTemplateApiResponse =
  /** status 201 Created */ TextDocumentDto;
export type CreateDocumentTemplateApiArg = {
  createDocumentTemplateRequest: CreateDocumentTemplateRequest;
};
export type DownloadTextDocumentApiResponse = unknown;
export type DownloadTextDocumentApiArg = {
  downloadTextDocumentRequest: DownloadTextDocumentRequest;
};
export type UpdateEmailStatusApiResponse = unknown;
export type UpdateEmailStatusApiArg = void;
export type ConfirmUploadApiResponse = unknown;
export type ConfirmUploadApiArg = void;
export type SearchIndustriesApiResponse =
  /** status 200 OK */ SearchIndustriesResponse;
export type SearchIndustriesApiArg = {
  name?: string;
};
export type ListMyNotificationsApiResponse =
  /** status 200 OK */ ListNotificationsResponse;
export type ListMyNotificationsApiArg = {
  limit?: number;
  offset?: number;
  unseen?: boolean;
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type ListOrganizationUsersApiResponse =
  /** status 200 OK */ ListOrganizationUsersResponse;
export type ListOrganizationUsersApiArg = {
  name?: string;
  limit: number;
  skip?: number;
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type ListOrganizationsForMeApiResponse =
  /** status 200 OK */ ListOrganizationsForMeResponse;
export type ListOrganizationsForMeApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type CreateOrganizationForMeApiResponse = /** status 200 OK */ OrgDto;
export type CreateOrganizationForMeApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  createOrgRequest: CreateOrgRequest;
};
export type MarkNotificationsAsPeekedApiResponse = unknown;
export type MarkNotificationsAsPeekedApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  markNotificationsRequest: MarkNotificationsRequest;
};
export type MarkNotificationsAsViewedApiResponse = unknown;
export type MarkNotificationsAsViewedApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  markNotificationsRequest: MarkNotificationsRequest;
};
export type GetAccessTokenApiResponse = /** status 200 OK */ AccessTokenDto;
export type GetAccessTokenApiArg = {
  getAccessTokenRequest: GetAccessTokenRequest;
};
export type SearchOpenOrgContactInfoApiResponse =
  /** status 200 OK */ SearchOrganizationResponse;
export type SearchOpenOrgContactInfoApiArg = {
  name?: string;
  number?: string;
};
export type ListContractsForOrgApiResponse =
  /** status 200 OK */ ListContractsResponseDto;
export type ListContractsForOrgApiArg = {
  title?: string;
  state?: ContractStateDto;
  statuses?: string;
  counterparties?: string;
  owners?: string;
  templates?: string;
  internalparties?: string;
  projects?: string;
  limit: number;
  skip?: number;
};
export type CreateApiClientApiResponse =
  /** status 200 OK */ CreateApiClientResponse;
export type CreateApiClientApiArg = {
  cookie?: string;
};
export type CreateOrganizationEntryApiResponse =
  /** status 200 OK */ OrganizationEntryDto;
export type CreateOrganizationEntryApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  createOrganizationEntryRequest: CreateOrganizationEntryRequest;
};
export type GetOrganizationEntryApiResponse =
  /** status 200 OK */ OrganizationEntryDto;
export type GetOrganizationEntryApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  entryId: string;
};
export type UpdateOrganizationEntryApiResponse =
  /** status 200 OK */ OrganizationEntryDto;
export type UpdateOrganizationEntryApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  entryId: string;
  updateOrganizationEntryRequest: UpdateOrganizationEntryRequest;
};
export type LinkOrganizationEntryApiResponse =
  /** status 200 OK */ OrganizationEntryDto;
export type LinkOrganizationEntryApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  entryId: string;
  linkOrganizationEntryRequest: LinkOrganizationEntryRequest;
};
export type SearchOrganizationsApiResponse =
  /** status 200 OK */ SearchOrganizationsResponse;
export type SearchOrganizationsApiArg = {
  name?: string;
  industries?: string;
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type GetSupplierEmailInviteApiResponse =
  /** status 200 OK */ SupplierEmailInviteDto;
export type GetSupplierEmailInviteApiArg = {
  secret: string;
  inviteId: string;
};
export type GetOrgUserEmailInviteApiResponse =
  /** status 200 OK */ OrgUserEmailInviteDto;
export type GetOrgUserEmailInviteApiArg = {
  secret: string;
  inviteId: string;
};
export type AcceptOrgUserEmailInviteApiResponse = /** status 200 OK */ OrgDto;
export type AcceptOrgUserEmailInviteApiArg = {
  secret: string;
  cookie?: string;
  organizationId?: string;
  personId?: string;
  inviteId: string;
};
export type UpdateOrganizationApiResponse = /** status 200 OK */ OrgDto;
export type UpdateOrganizationApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  editOrganizationRequest: EditOrganizationRequest;
};
export type GetOrganizationApiResponse =
  /** status 200 OK */ OrgPublicProfileDto;
export type GetOrganizationApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type ListSupplierEmailInvitesApiResponse =
  /** status 200 OK */ ListOrganizationInvitesResponse;
export type ListSupplierEmailInvitesApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type CreateSupplierEmailInviteApiResponse =
  /** status 200 OK */ SupplierEmailInviteDto;
export type CreateSupplierEmailInviteApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  createEmailInviteRequest: CreateEmailInviteRequest;
};
export type DeleteSupplierEmailInviteApiResponse = unknown;
export type DeleteSupplierEmailInviteApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  inviteId: string;
};
export type CreateSupplierEmailInviteReminderApiResponse = unknown;
export type CreateSupplierEmailInviteReminderApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  inviteId: string;
};
export type UpdateOrganizationTenantApiResponse = /** status 200 OK */ OrgDto;
export type UpdateOrganizationTenantApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  updateOrgTenantRequest: UpdateOrgTenantRequest;
};
export type ListOrgUserEmailInvitesApiResponse =
  /** status 200 OK */ ListOrgUserEmailInvitesResponse;
export type ListOrgUserEmailInvitesApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type CreateOrgUserEmailInviteApiResponse =
  /** status 200 OK */ SupplierEmailInviteDto;
export type CreateOrgUserEmailInviteApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  createOrgUserEmailInviteRequest: CreateOrgUserEmailInviteRequest;
};
export type DeleteOrgUserEmailInviteApiResponse = unknown;
export type DeleteOrgUserEmailInviteApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  inviteId: string;
};
export type UpdateOrgUserRoleApiResponse = /** status 200 OK */ OrgDto;
export type UpdateOrgUserRoleApiArg = {
  cookie?: string;
  organizationId?: string;
  personId: string;
  orgId: string;
  updateOrgUserRoleRequest: UpdateOrgUserRoleRequest;
};
export type DeleteOrgUserApiResponse = /** status 200 OK */ OrgDto;
export type DeleteOrgUserApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  userId: string;
};
export type ListContractTasksForOrganizationApiResponse =
  /** status 200 OK */ ListContractTasksResponseDto;
export type ListContractTasksForOrganizationApiArg = {
  limit?: number;
  statuses?: string;
  personIds?: string;
  contractIds?: string;
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type ListContractTemplatesApiResponse =
  /** status 200 OK */ ListContractTemplatesResponseDto;
export type ListContractTemplatesApiArg = {
  contractId?: string;
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type CreateContractTemplateApiResponse =
  /** status 200 OK */ ContractTemplateDto;
export type CreateContractTemplateApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
  upsertContractTemplateRequest: UpsertContractTemplateRequest;
};
export type GetTaskApiResponse = /** status 200 OK */ ContractTaskDto;
export type GetTaskApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  taskId: string;
};
export type UpdateContractTemplateApiResponse =
  /** status 200 OK */ ContractTemplateDto;
export type UpdateContractTemplateApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  templateId: string;
  upsertContractTemplateRequest: UpsertContractTemplateRequest;
};
export type GetContractApiResponse = /** status 200 OK */ ContractDto;
export type GetContractApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
};
export type UpdateContractApiResponse = /** status 200 OK */ ContractDto;
export type UpdateContractApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
  updateContractRequest: UpdateContractRequest;
};
export type PublishContractApiResponse = /** status 200 OK */ ContractDto;
export type PublishContractApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
};
export type CreateSignedDocumentApiResponse = /** status 200 OK */ ContractDto;
export type CreateSignedDocumentApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
  createSignedDocumentRequest: CreateSignedDocumentRequest;
};
export type ListContractTasksApiResponse =
  /** status 200 OK */ ListContractTasksResponseDto;
export type ListContractTasksApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  contractId: string;
};
export type CreateContractApiResponse = /** status 200 OK */ ContractDto;
export type CreateContractApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  depId: string;
  createContractRequest: CreateContractRequest;
};
export type ListPersonsApiResponse = /** status 200 OK */ ListPersonsResponse;
export type ListPersonsApiArg = {
  orgIds: string[];
  name?: string;
  roles?: (
    | "Owner"
    | "Admin"
    | "SourcingCreator"
    | "SourcingViewer"
    | "ContractCreator"
    | "ContractViewer"
    | "ProjectViewer"
    | "ProjectCreator"
  )[];
  limit: number;
  skip?: number;
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type GetMeApiResponse = /** status 200 OK */ MeDto;
export type GetMeApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type UpdateMeApiResponse = /** status 200 OK */ MeDto;
export type UpdateMeApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  editMeRequest: EditMeRequest;
};
export type EditProductGroupApiResponse = /** status 200 OK */ ProductGroupDto;
export type EditProductGroupApiArg = {
  editProductGroupDto: EditProductGroupDto;
};
export type CreateProductGroupApiResponse =
  /** status 201 Created */ ProductGroupDto;
export type CreateProductGroupApiArg = {
  createProductGroupDto: CreateProductGroupDto;
};
export type DeleteProductGroupApiResponse = unknown;
export type DeleteProductGroupApiArg = {
  productGroupId: string;
};
export type GetProductGroupsForProjectApiResponse =
  /** status 200 OK */ ProductGroupsDto;
export type GetProductGroupsForProjectApiArg = {
  projectId: string;
};
export type GetProductTemplatesApiResponse =
  /** status 200 OK */ ProductTemplatesDto;
export type GetProductTemplatesApiArg = void;
export type UpdateProjectApiResponse = /** status 200 OK */ ProjectDto;
export type UpdateProjectApiArg = {
  updateProjectRequest: UpdateProjectRequest;
};
export type CreateProjectApiResponse = /** status 201 Created */ ProjectDto;
export type CreateProjectApiArg = {
  createProjectRequest: CreateProjectRequest;
};
export type CreateProjectTextDocumentApiResponse =
  /** status 201 Created */ TextDocumentDto;
export type CreateProjectTextDocumentApiArg = {
  createProjectTextDocumentRequest: CreateProjectTextDocumentRequest;
};
export type ListTextDocumentsForProjectApiResponse =
  /** status 200 OK */ TextDocumentsDto;
export type ListTextDocumentsForProjectApiArg = {
  projectId: string;
};
export type GetProjectApiResponse = /** status 200 OK */ ProjectDto;
export type GetProjectApiArg = {
  projectId: string;
};
export type GetProjectsApiResponse = /** status 200 OK */ ProjectsDto;
export type GetProjectsApiArg = {
  name?: string;
  status?: string;
  limit?: number;
  skip?: number;
};
export type CreateUploadForProjectApiResponse = /** status 200 OK */ UploadDto;
export type CreateUploadForProjectApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  projectId: string;
  uploadId: string;
  createUploadRequestDto: CreateUploadRequestDto;
};
export type GetGroupsApiResponse = unknown;
export type GetGroupsApiArg = {
  authorization?: string;
};
export type CreateGroupApiResponse = unknown;
export type CreateGroupApiArg = {
  authorization?: string;
  createGroupDto: CreateGroupDto;
};
export type DeleteGroupApiResponse = unknown;
export type DeleteGroupApiArg = {
  authorization?: string;
  groupId: string;
};
export type GetGroupApiResponse = unknown;
export type GetGroupApiArg = {
  authorization?: string;
  groupId: string;
};
export type UpdateGroupApiResponse = unknown;
export type UpdateGroupApiArg = {
  authorization?: string;
  groupId: string;
  body: Blob;
};
export type GetSchemasApiResponse = unknown;
export type GetSchemasApiArg = void;
export type GetTokenApiResponse = /** status 200 OK */ TokenResponse;
export type GetTokenApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  authorization?: string;
};
export type GetUsersApiResponse = unknown;
export type GetUsersApiArg = {
  authorization?: string;
};
export type CreateUserApiResponse = unknown;
export type CreateUserApiArg = {
  authorization?: string;
  body: Blob;
};
export type DeleteUserApiResponse = unknown;
export type DeleteUserApiArg = {
  authorization?: string;
  userId: string;
};
export type GetUserApiResponse = unknown;
export type GetUserApiArg = {
  authorization?: string;
  userId: string;
};
export type UpdateUserApiResponse = unknown;
export type UpdateUserApiArg = {
  authorization?: string;
  userId: string;
};
export type SearchOrganizationsAndEntriesApiResponse =
  /** status 200 OK */ SearchOrganizationsAndEntriesResponse;
export type SearchOrganizationsAndEntriesApiArg = {
  name?: string;
  limit: number;
  skip?: number;
  type?: OrganizationType;
  cookie?: string;
  organizationId?: string;
  personId?: string;
};
export type GetSignedDocumentDownloadLinkApiResponse =
  /** status 200 OK */ GetDownloadLinkResponse;
export type GetSignedDocumentDownloadLinkApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  docId: string;
};
export type GetSignedAttachmentDownloadLinkApiResponse =
  /** status 200 OK */ GetDownloadLinkResponse;
export type GetSignedAttachmentDownloadLinkApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  docId: string;
  attachmentId: string;
};
export type CreateSourcingEventTextDocumentApiResponse =
  /** status 201 Created */ TextDocumentDto;
export type CreateSourcingEventTextDocumentApiArg = {
  createSourcingEventTextDocumentRequest: CreateSourcingEventTextDocumentRequest;
};
export type ListTextDocumentsForSourcingEventApiResponse =
  /** status 200 OK */ TextDocumentsDto;
export type ListTextDocumentsForSourcingEventApiArg = {
  eventId: string;
};
export type GetBseBidApiResponse = /** status 200 OK */ BseBidDto;
export type GetBseBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  bidId: string;
};
export type UpdateBseBidApiResponse = /** status 200 OK */ BseBidDto;
export type UpdateBseBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  bidId: string;
  editBidRequest: EditBidRequest;
};
export type CreateUploadForBseBidApiResponse = /** status 200 OK */ UploadDto;
export type CreateUploadForBseBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  bidId: string;
  uploadId: string;
  createUploadRequestDto: CreateUploadRequestDto;
};
export type CreateBseBidDraftAsDepartmentApiResponse =
  /** status 200 OK */ BseBidDto;
export type CreateBseBidDraftAsDepartmentApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  depId: string;
  eventId: string;
};
export type GetBseSupplierViewApiResponse =
  /** status 200 OK */ BseSupplierViewDto;
export type GetBseSupplierViewApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type CreateBseAnnouncementApiResponse = /** status 200 OK */ BseDto;
export type CreateBseAnnouncementApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  createBseAnnouncementRequest: CreateBseAnnouncementRequest;
};
export type UnDeliverBseBidApiResponse = /** status 200 OK */ BseBidDto;
export type UnDeliverBseBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  bidId: string;
};
export type DeliverBseBidApiResponse = /** status 200 OK */ BseBidDto;
export type DeliverBseBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  bidId: string;
};
export type AcceptInvitationToBidApiResponse =
  /** status 200 OK */ BseSupplierViewDto;
export type AcceptInvitationToBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type DeclineInvitationToBidApiResponse =
  /** status 200 OK */ BseSupplierViewDto;
export type DeclineInvitationToBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type DownloadPublicProcurementProtocolPartOneApiResponse = unknown;
export type DownloadPublicProcurementProtocolPartOneApiArg = {
  eventId: string;
  getProcurementProtocolRequest: GetProcurementProtocolRequest;
};
export type DownloadQaReportApiResponse = unknown;
export type DownloadQaReportApiArg = {
  eventId: string;
};
export type CreateBseQuestionApiResponse =
  /** status 200 OK */ BseSupplierViewDto;
export type CreateBseQuestionApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  createBseQuestionRequest: CreateBseQuestionRequest;
};
export type CreateBseQuestionCommentAsSupplierApiResponse =
  /** status 200 OK */ BseSupplierViewDto;
export type CreateBseQuestionCommentAsSupplierApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
  questionId: string;
  createBseQuestionCommentRequest: CreateBseQuestionCommentRequest;
};
export type MarkAsViewedApiResponse = /** status 200 OK */ BseSupplierViewDto;
export type MarkAsViewedApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  eventId: string;
};
export type DeleteBidApiResponse = unknown;
export type DeleteBidApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  bidId: string;
};
export type ListBidsCreatedByDepartmentApiResponse =
  /** status 200 OK */ ListBidsResponse;
export type ListBidsCreatedByDepartmentApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  depId: string;
};
export type GetSeEmailInviteApiResponse = /** status 200 OK */ SeEmailInviteDto;
export type GetSeEmailInviteApiArg = {
  secret: string;
  inviteId: string;
};
export type AcceptSeEmailInviteApiResponse = unknown;
export type AcceptSeEmailInviteApiArg = {
  secret: string;
  cookie?: string;
  organizationId?: string;
  personId?: string;
  inviteId: string;
};
export type ListSeSupplierViewsApiResponse =
  /** status 200 OK */ ListSeSupplierViewsResponse;
export type ListSeSupplierViewsApiArg = {
  status: ListSourcingEventsFilterStatus;
  cookie?: string;
  organizationId?: string;
  personId?: string;
  orgId: string;
};
export type GetProductGroupsAsSupplierApiResponse =
  /** status 200 OK */ SupplierProductGroupsDto;
export type GetProductGroupsAsSupplierApiArg = {
  sourcingEventId: string;
};
export type UpdateTextDocumentApiResponse =
  /** status 201 Created */ TextDocumentDto;
export type UpdateTextDocumentApiArg = {
  updateTextDocumentRequest: UpdateTextDocumentRequest;
};
export type DeleteTextDocumentApiResponse = unknown;
export type DeleteTextDocumentApiArg = {
  id: string;
};
export type DeleteUploadApiResponse = /** status 200 OK */ UploadDto;
export type DeleteUploadApiArg = {
  cookie?: string;
  organizationId?: string;
  personId?: string;
  uploadId: string;
};
export type AdminEntityType =
  | "Bid"
  | "Contract"
  | "Document"
  | "EmailInvite"
  | "EmailOutboxItem"
  | "Organization"
  | "Person"
  | "SourcingEvent"
  | "Upload";
export type CallingCodeDto = {
  countryAlpha3Code: string;
  countryName: string;
  value: string;
};
export type PhoneNumberDto = {
  callingCode: CallingCodeDto;
  number: string;
};
export type PersonDto = {
  id: string;
  firstName?: string;
  lastName?: string;
  email: string;
  corporateTitle?: string;
  phoneNumber?: PhoneNumberDto;
};
export type CountryDto = {
  id: string;
  alpha3Code: string;
  name: string;
};
export type IndustryDto = {
  id: string;
  name: string;
};
export type OrganizationSize =
  | "ONE_TO_TEN"
  | "TEN_TO_FIFTY"
  | "FIFTY_TO_TWO_HUNDRED_AND_FIFTY"
  | "TWO_HUNDRED_AND_FIFTY_TO_THOUSAND"
  | "THOUSAND_TO_TEN_THOUSAND"
  | "TEN_THOUSAND_PLUS";
export type SubscriptionDto = {
  hasBuyerAccess: boolean;
  hasContractAccess: boolean;
  hasSignatureAccess: boolean;
  hasBankIdAccess: boolean;
  hasProductAccess: boolean;
  hasProjectAccess: boolean;
  hasAiAccess: boolean;
  hasDocumentTemplateAccess: boolean;
  hasIFRSAccess: boolean;
};
export type DepartmentDto = {
  id: string;
  name: string;
};
export type OrgUserInviteRole =
  | "Admin"
  | "SourcingCreator"
  | "SourcingViewer"
  | "ContractCreator"
  | "ContractViewer"
  | "ProjectViewer"
  | "ProjectCreator";
export type AdminOrgDto = {
  id: string;
  owner: PersonDto;
  name: string;
  country: CountryDto;
  industries: IndustryDto[];
  aboutUs?: string;
  specializations?: string;
  homePageUrl?: string;
  size?: OrganizationSize;
  subscription: SubscriptionDto;
  departments: DepartmentDto[];
  azureTenantId?: string;
  azureUsersDefaultRole: OrgUserInviteRole;
  canSeeDeliveredBidsBeforeDeadline: boolean;
};
export type AdminListOrganizationsResponse = {
  orgs: AdminOrgDto[];
};
export type OrganizationRoleType =
  | "Owner"
  | "Admin"
  | "SourcingCreator"
  | "SourcingViewer"
  | "ContractCreator"
  | "ContractViewer"
  | "ProjectViewer"
  | "ProjectCreator";
export type CustomNumberFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  value?: number;
};
export type CustomAmountFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  amount?: number;
  currency?: string;
};
export type CustomDateFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  date?: string;
};
export type CustomEmailFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  email?: string;
};
export type CustomBooleanFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  isChecked?: boolean;
};
export type CustomEnumFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  options?: string[];
  value?: string;
};
export type CustomMultiEnumFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  options?: string[];
  values?: string[];
};
export type CustomPercentageFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  value?: number;
};
export type CustomPersonFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  person?: PersonDto;
};
export type CustomPhoneFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  number?: string;
  callingCode?: string;
};
export type CustomRichTextFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  text?: string;
};
export type CustomTagFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  tag?: string;
};
export type CustomUrlFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  url?: string;
};
export type CustomTextFieldDto = {
  id: string;
  name: string;
  isInternal: boolean;
  text?: string;
};
export type ContractDataFieldSectionDto = {
  id: string;
  name: string;
  fields: (
    | CustomNumberFieldDto
    | CustomAmountFieldDto
    | CustomDateFieldDto
    | CustomEmailFieldDto
    | CustomBooleanFieldDto
    | CustomEnumFieldDto
    | CustomMultiEnumFieldDto
    | CustomPercentageFieldDto
    | CustomPersonFieldDto
    | CustomPhoneFieldDto
    | CustomRichTextFieldDto
    | CustomTagFieldDto
    | CustomUrlFieldDto
    | CustomTextFieldDto
  )[];
};
export type LeadTimeUnit = "Days" | "Months";
export type LeadTimeDto = {
  unit: LeadTimeUnit;
  amount: number;
};
export type ContractRole = "InternalOwner" | "CounterParty" | "InvitedParty";
export type ContractNotificationReceiversDto = {
  persons: PersonDto[];
  organizationRoles: OrganizationRoleType[];
  contractRoles: ContractRole[];
};
export type ContractDateReminderDto = {
  leadTime: LeadTimeDto;
  receivers: ContractNotificationReceiversDto;
};
export type IntervalUnit = "Days" | "Months";
export type ReminderIntervalDto = {
  unit: IntervalUnit;
  amount: number;
};
export type RecurringContractReminderDto = {
  interval: ReminderIntervalDto;
  receivers: ContractNotificationReceiversDto;
};
export type ContractNotificationSettingsDto = {
  startDateNotifications: ContractDateReminderDto[];
  endDateNotifications: ContractDateReminderDto[];
  warrantyExpirationNotifications: ContractDateReminderDto[];
  renewalDateNotifications: ContractDateReminderDto[];
  terminationDateNotifications: ContractDateReminderDto[];
  noticePeriodNotifications: ContractDateReminderDto[];
  optionExecutionDeadlineNotifications: ContractDateReminderDto[];
  optionStartDateNotifications: ContractDateReminderDto[];
  noEndDateNotifications: RecurringContractReminderDto[];
};
export type ContractTaskRole = "Responsible";
export type ContractTaskNotificationReceiversDto = {
  contractTaskRoles: ContractTaskRole[];
};
export type ContractTaskDateReminderDto = {
  leadTime: LeadTimeDto;
  receivers: ContractTaskNotificationReceiversDto;
};
export type ContractTaskNotificationSettingsDto = {
  dueDateNotifications: ContractTaskDateReminderDto[];
};
export type SourcingEventRole = "Publisher" | "InvitedSupplier";
export type SourcingEventNotificationReceiversDto = {
  persons: PersonDto[];
  organizationRoles: OrganizationRoleType[];
  sourcingEventRoles: SourcingEventRole[];
};
export type SourcingEventDateReminderDto = {
  leadTime: LeadTimeDto;
  receivers: SourcingEventNotificationReceiversDto;
};
export type SourcingEventNotificationSettingsDto = {
  bidDeliveryDeadlineNotifications: SourcingEventDateReminderDto[];
};
export type DefaultNotificationSettingsDto = {
  contractNotificationSettings: ContractNotificationSettingsDto;
  contractTaskNotificationSettings: ContractTaskNotificationSettingsDto;
  sourcingEventNotificationSettings: SourcingEventNotificationSettingsDto;
};
export type OrganizationType = "Organization" | "OrganizationEntry";
export type MinimalOrgDto = {
  id: string;
  name: string;
  organizationNumber?: string;
  type: OrganizationType;
  country: CountryDto;
};
export type ScimGroupDto = {
  id: string;
  displayName: string;
  memberCount: number;
};
export type OrgDto = {
  id: string;
  owner: PersonDto;
  name: string;
  country: CountryDto;
  organizationNumber?: string;
  industries: IndustryDto[];
  aboutUs?: string;
  specializations?: string;
  homePageUrl?: string;
  size?: OrganizationSize;
  subscription: SubscriptionDto;
  departments: DepartmentDto[];
  azureTenantId?: string;
  allowScimUsersOnly?: boolean;
  azureUsersDefaultRole: OrgUserInviteRole;
  canEdit: boolean;
  canManageSupplierInvites: boolean;
  canManageUsers: boolean;
  canCreateEvent: boolean;
  canCreateContract: boolean;
  canCreateProject: boolean;
  hasProductAccess: boolean;
  hasProjectAccess: boolean;
  hasAiAccess: boolean;
  hasDocumentTemplateAccess: boolean;
  hasIFRSAccess: boolean;
  roles: OrganizationRoleType[];
  canOnlySeeCreatedEvents: boolean;
  canOnlySeeCreatedContracts: boolean;
  canSeeDeliveredBidsBeforeDeadline: boolean;
  customContractSections?: {
    [key: string]: {
      id_1?: ContractDataFieldSectionDto;
      id_2?: ContractDataFieldSectionDto;
    };
  };
  defaultNotificationSettings?: DefaultNotificationSettingsDto;
  internalParties?: MinimalOrgDto[];
  scimGroups?: ScimGroupDto[];
  contactPersons?: PersonDto[];
};
export type AdminUpdateOrganizationRequest = {
  name?: string;
  hasContractAccess?: boolean;
  hasBuyerAccess?: boolean;
  hasSignatureAccess?: boolean;
  hasBankIdAccess?: boolean;
  hasProductAccess?: boolean;
  hasProjectAccess?: boolean;
  hasAiAccess?: boolean;
  hasDocumentTemplateAccess?: boolean;
  hasIFRSAccess?: boolean;
  canSeeDeliveredBidsBeforeDeadline?: boolean;
};
export type ContractDataFieldType =
  | "Date"
  | "Financial"
  | "Commercial"
  | "TermsAndConditions"
  | "Other";
export type ContractDataFieldNameDto =
  | "StartDate"
  | "EndDate"
  | "AwardDate"
  | "WarrantyExpirationDate"
  | "RenewalDate"
  | "CounterpartyContactPerson"
  | "DeliveryTerms"
  | "EstimatedValue"
  | "ExternalLink"
  | "InternalReferenceNumber"
  | "LinkedContracts"
  | "OriginSourcingEvent"
  | "PaymentTermsInDays"
  | "PricePerMonth"
  | "PricePerYear"
  | "Priority"
  | "PurchasingPolicy"
  | "Risk"
  | "TotalLiability"
  | "TotalPrice"
  | "TerminationDate"
  | "IfrsValues"
  | "SupplierInfo"
  | "InternalParties"
  | "SupplierCategory"
  | "NoticePeriod"
  | "Options"
  | "Projects";
export type ContractDataFieldDto = {
  type: ContractDataFieldType;
  name: ContractDataFieldNameDto;
};
export type BaseDataDto = {
  callingCodes: CallingCodeDto[];
  countries: CountryDto[];
  contractDataFields: ContractDataFieldDto[];
};
export type DocumentAddedClientEvent = {
  documentId: string;
};
export type ApiErrorDto = {
  errorCode: string;
};
export type AllTypes = {
  documentAddedClientEvent: DocumentAddedClientEvent;
  apiError: ApiErrorDto;
  apiErrorCodes: string[];
};
export type BseStatusDto =
  | "Draft"
  | "Published"
  | "DeadlineHasPassed"
  | "Completed"
  | "Awarded";
export type BseStateNameDto =
  | "Draft"
  | "Published"
  | "Completed"
  | "Awarded"
  | "DeadlineHasPassed";
export type OrgPitchDto = {
  id: string;
  owner: PersonDto;
  name: string;
  country: CountryDto;
  aboutUs?: string;
  specializations?: string;
  homePageUrl?: string;
  size?: OrganizationSize;
};
export type OrganizationInviteStatus =
  | "IsWinner"
  | "IsLoser"
  | "BidWasNotNotSubmitted"
  | "BidIsSubmitted"
  | "InvitationAccepted"
  | "DraftCreated"
  | "InvitationDeclined"
  | "HasSeenEvent"
  | "InvitationSent";
export type OrganizationInviteDto = {
  id: string;
  organizationId: string;
  organizationName: string;
  persons: PersonDto[];
  status: OrganizationInviteStatus;
  bidId?: string;
};
export type EmailInviteLanguage = "En" | "No";
export type EmailInviteStatusDto = "Sent" | "Opened" | "SendingFailed";
export type ContactPersonDto = {
  email: string;
  name: string;
  language: EmailInviteLanguage;
  title?: string;
  phoneNumber?: PhoneNumberDto;
};
export type LinkDto = {
  linkedBy: PersonDto;
  linkedAt: string;
  organizationId: string;
  organizationName: string;
};
export type OrganizationEntryDto = {
  id: string;
  name: string;
  country: CountryDto;
  organizationNumber: string;
  contactPersons?: ContactPersonDto[];
  link?: LinkDto;
};
export type EmailInviteDto = {
  id: string;
  email: string;
  language: EmailInviteLanguage;
  lastReminderSentAt?: string;
  status: EmailInviteStatusDto;
  openedAt?: string;
  organizationEntry?: OrganizationEntryDto;
};
export type ViewDocumentAsOwnerDto = {
  id: string;
  name: string;
  mimeType: string;
  fileExtension: string;
  size: number;
  uploadedAt: string;
  uploadedBy: PersonDto;
  s3Key: string;
  fromUploadId: string;
};
export type UploadDto = {
  id: string;
  fileName: string;
  mimeType: string;
  fileExtension: string;
  url: string;
  renamedXAmzDate: string;
  renamedXAmzSignature: string;
  renamedXAmzAlgorithm: string;
  renamedXAmzCredential: string;
  policy: string;
  key: string;
};
export type DraftFieldsDto = {
  description?: string;
  deadline?: string;
  uploads: UploadDto[];
};
export type WinningProductBidsDto = {
  productId: string;
  bids: string[];
};
export type WinningProductGroupBidsDto = {
  productGroupId: string;
  bids: WinningProductBidsDto[];
};
export type PublishedFieldsDto = {
  description: string;
  deadline: string;
  uploads: UploadDto[];
  bidIds: string[];
  publishedAt: string;
  publishedBy: PersonDto;
  selectedProductGroupBids?: WinningProductGroupBidsDto[];
};
export type Currency = "NOK" | "USD" | "EUR" | "DKK";
export type EventValueDto = {
  value: number;
  currency: Currency;
};
export type AwardedFieldsDto = {
  description: string;
  deadline: string;
  bidIds: string[];
  publishedAt: string;
  publishedBy: PersonDto;
  awardedAt: string;
  awardedBy: PersonDto;
  winningBidIds: string[];
  winningProductBids?: WinningProductGroupBidsDto[];
  eventValue?: EventValueDto;
  messageToWinners?: string;
  messageToLosers?: string;
};
export type BseQuestionStatus = "Unresolved" | "Resolved";
export type BseQuestionCommentDto = {
  id: string;
  createdAt: string;
  content: string;
  commenter: PersonDto;
};
export type BseQuestionDto = {
  id: string;
  status: BseQuestionStatus;
  askedByPerson: PersonDto;
  askedByOrgName: string;
  askedAt: string;
  subject: string;
  content: string;
  comments: BseQuestionCommentDto[];
  announcementId?: string;
};
export type BseAnnouncementDto = {
  id: string;
  createdByPerson: PersonDto;
  createdAt: string;
  title: string;
  content: string;
};
export type InternalMessageDto = {
  id: string;
  value: string;
  createdBy?: PersonDto;
  createdAt: string;
};
export type ProjectStatus = "NotStarted" | "Ongoing" | "Ended";
export type ParentProjectDto = {
  id: string;
  createdAt: string;
  modifiedAt: string;
  externalId?: string;
  name: string;
  startDate?: string;
  endDate?: string;
};
export type ChildProjectDto = {
  id: string;
  createdAt: string;
  modifiedAt: string;
  externalId?: string;
  name: string;
  startDate?: string;
  endDate?: string;
};
export type ProjectTypeName = "BidCalculation" | "Other";
export type ProjectTypeDto = {
  id: string;
  name: ProjectTypeName;
};
export type ProjectDocumentDto = {
  id: string;
  name: string;
  mimeType: string;
  fileExtension: string;
  size: number;
  uploadedAt: string;
  uploadedBy: PersonDto;
  s3Key: string;
  fromUploadId: string;
};
export type ProjectDto = {
  id: string;
  createdAt: string;
  modifiedAt: string;
  status: ProjectStatus;
  externalId?: string;
  name: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  parent?: ParentProjectDto;
  children?: ChildProjectDto[];
  projectResponsible?: PersonDto;
  sourcingResponsible?: PersonDto;
  projectType?: ProjectTypeDto;
  documents?: ProjectDocumentDto[];
  canEdit: boolean;
  uploads?: UploadDto[];
};
export type NegotiationRoundDto = {
  id: string;
  roundNumber: number;
  deadline: string;
  isFinalRound?: boolean;
  createdAt: string;
  modifiedAt: string;
};
export type NegotiationContextDto = {
  rounds: NegotiationRoundDto[];
};
export type BseDto = {
  id: string;
  status: BseStatusDto;
  stateName: BseStateNameDto;
  createdAt: string;
  modifiedAt: string;
  createdBy: PersonDto;
  owningOrganization: OrgPitchDto;
  organizationInvites: OrganizationInviteDto[];
  emailInvites: EmailInviteDto[];
  title: string;
  industries: IndustryDto[];
  documents: ViewDocumentAsOwnerDto[];
  timezone: string;
  referenceId?: string;
  draftFields?: DraftFieldsDto;
  publishedFields?: PublishedFieldsDto;
  awardedFields?: AwardedFieldsDto;
  questions?: BseQuestionDto[];
  announcements: BseAnnouncementDto[];
  canEdit: boolean;
  internalConversation: InternalMessageDto[];
  projects?: ProjectDto[];
  negotiationContext?: NegotiationContextDto;
};
export type CreateSourcingEventRequest = {
  title: string;
  timezone: string;
  projectId?: string;
};
export type EditDescription = {
  value?: string;
};
export type EditDeadline = {
  value?: string;
};
export type EditTimezone = {
  value: string;
};
export type EditReferenceId = {
  value?: string;
};
export type OrganizationInviteInput = {
  organizationId: string;
  personIds: string[];
};
export type AddEmailInviteRequest = {
  email: string;
  language: EmailInviteLanguage;
  organizationEntryId: string;
};
export type EditEventValue = {
  value: EventValueDto;
};
export type EditSourcingEventRequest = {
  title?: string;
  description?: EditDescription;
  deadline?: EditDeadline;
  timezone?: EditTimezone;
  industryIds?: string[];
  editReferenceId?: EditReferenceId;
  removeDocument?: string;
  organizationToInviteInput?: OrganizationInviteInput;
  organizationInviteIdToRemove?: string;
  emailInvitesToAdd?: AddEmailInviteRequest[];
  emailInviteIdToRemove?: string;
  eventValue?: EditEventValue;
  emailInviteSecretOverride?: string;
  selectProductBids?: WinningProductGroupBidsDto[];
  removeProductBids?: WinningProductGroupBidsDto[];
  addInternalMessage?: string;
  editProjects?: string[];
};
export type AwardToBidRequest = {
  bidIds: string[];
  messageToWinners?: string;
  messageToLosers?: string;
  eventValue?: EventValueDto;
};
export type Populater = "Buyer" | "Supplier";
export type NumberFieldDto = {
  id: string;
  name: string;
  populatedBy: Populater;
  isRequired: boolean;
  value?: number;
};
export type TextFieldDto = {
  id: string;
  name: string;
  populatedBy: Populater;
  isRequired: boolean;
  value?: string;
};
export type BooleanFieldDto = {
  id: string;
  name: string;
  populatedBy: Populater;
  isRequired: boolean;
  value?: boolean;
};
export type ProductBidDto = {
  productId: string;
  unitPrice: NumberFieldDto;
  productFields: (TextFieldDto | NumberFieldDto | BooleanFieldDto)[];
};
export type ProductGroupBidDto = {
  productGroupId: string;
  productBids: ProductBidDto[];
};
export type ViewSharedDocumentDto = {
  id: string;
  name: string;
  mimeType: string;
  fileExtension: string;
  size: number;
};
export type ReceivedBseBidDto = {
  id: string;
  owningOrganization: OrgPitchDto;
  forSourcingEventId: string;
  comment: string;
  products?: ProductGroupBidDto[];
  documents: ViewSharedDocumentDto[];
  uniqueForViewDeliveredBasicBidAsBuyerDto: boolean;
  contractId?: string;
};
export type ListReceivedBseBidsResponse = {
  bids: ReceivedBseBidDto[];
};
export type CreateNegotiationRoundRequest = {
  deadline: string;
  isFinalRound?: boolean;
};
export type UpdateBseQuestionRequest = {
  status: BseQuestionStatus;
};
export type CreateBseQuestionCommentRequest = {
  content: string;
};
export type CreateUploadRequestDto = {
  fileName: string;
  fileSize: number;
  fileExtension: string;
  mimeType: string;
};
export type ProductFieldType = "Text" | "Number" | "Boolean";
export type ProductTemplateFieldDto = {
  id: string;
  name: string;
  type: ProductFieldType;
  populatedBy: Populater;
  isRequired: boolean;
};
export type ProductCommentDto = {
  comment: string;
  createdBy?: PersonDto;
  createdAt: string;
  modifiedAt: string;
};
export type ProductDto = {
  id: string;
  quantity: NumberFieldDto;
  productFields: (TextFieldDto | BooleanFieldDto | NumberFieldDto)[];
  conversation?: ProductCommentDto[];
};
export type ProductGroupDto = {
  id: string;
  sourcingEventId?: string;
  projectId?: string;
  name: string;
  originProductGroupId?: string;
  productFields: ProductTemplateFieldDto[];
  products: ProductDto[];
};
export type ProductGroupsDto = {
  productGroups: ProductGroupDto[];
};
export type SeDto = {
  id: string;
  title: string;
  status: BseStatusDto;
  createdAt: string;
  modifiedAt: string;
  createdBy: PersonDto;
  deadline?: string;
  winners?: string[];
  unansweredQuestionsCount: number;
  referenceId?: string;
  projects?: ProjectDto[];
  canEdit: boolean;
  canView: boolean;
};
export type ListSeDtosResponse = {
  events: SeDto[];
  count: number;
};
export type ListSourcingEventsFilterStatus =
  | "All"
  | "Drafts"
  | "Ongoing"
  | "Completed";
export type ContractStateDto = "Draft" | "Published";
export type ContractStatusDto = "Awarded" | "Active" | "Expired" | "Terminated";
export type OrganizationEntryPitchDto = {
  id: string;
  name: string;
};
export type OrgPublicProfileDto = {
  id: string;
  owner: PersonDto;
  name: string;
  country: CountryDto;
  organizationNumber?: string;
  industries: IndustryDto[];
  aboutUs?: string;
  specializations?: string;
  homePageUrl?: string;
  size?: OrganizationSize;
  contactPersons?: PersonDto[];
  linkedEntries: OrganizationEntryPitchDto[];
};
export type CounterpartyDto = {
  organization?: OrgPublicProfileDto;
  organizationEntry?: OrganizationEntryDto;
};
export type CommonTemplateType = "ExpirationDate";
export type ContractTemplateMinDto = {
  id: string;
  name?: string;
  commonType?: CommonTemplateType;
};
export type ContractEditingMetaFieldsDto = {
  uploads: UploadDto[];
};
export type CustomFieldIdentifierDto = {
  sectionId: string;
  fieldId: string;
};
export type CounterpartyContactPersonDto = {
  fullName?: string;
  email?: string;
  phoneNumber?: PhoneNumberDto;
  person?: PersonDto;
};
export type ContractEndDateDto = {
  date?: string;
  hasNoEndDate: boolean;
};
export type LinkedContractDto = {
  id: string;
  title: string;
};
export type OriginSourcingEventDto = {
  id: string;
  title: string;
};
export type ContractPriority =
  | "VeryLow"
  | "BelowAverage"
  | "Average"
  | "AboveAverage"
  | "VeryHigh";
export type ContractRisk =
  | "VeryLow"
  | "BelowAverage"
  | "Average"
  | "AboveAverage"
  | "VeryHigh";
export type TerminationDateDto = {
  date: string;
  reason: string;
};
export type IfrsAdjustmentType = "Monetary" | "Percentage";
export type IfrsAdjustmentDto = {
  monthNumber: number;
  type: IfrsAdjustmentType;
  value: number;
};
export type IfrsValuesDto = {
  startDate?: string;
  startDateSecondPeriod?: string;
  monthlyPayment?: number;
  paymentIntervalInMonths?: number;
  contractLengthInMonths?: number;
  interestRate?: number;
  adjustments: IfrsAdjustmentDto[];
  filled: boolean;
};
export type InternalPartyDto = {
  organizationId: string;
  organizationName: string;
};
export type InternalPartiesDto = {
  parties: InternalPartyDto[];
};
export type SupplierInfoDto = {
  isClimateCommitted: boolean;
  hasSignedCodeOfConduct: boolean;
  hasCustomerContact: boolean;
};
export type Category = "A" | "B" | "C" | "D" | "E";
export type OptionDto = {
  id: string;
  name: string;
  description: string;
  startDate?: string;
  deadline?: string;
  endDate?: string;
  duration?: number;
  isExecuted: boolean;
};
export type ContractDataFieldsDto = {
  missingDataFields: ContractDataFieldNameDto[];
  requiredDataFields: ContractDataFieldDto[];
  addedDataFields: ContractDataFieldDto[];
  customFields?: {
    [key: string]: {
      example_id?: ContractDataFieldSectionDto;
    };
  };
  missingCustomFields?: CustomFieldIdentifierDto[];
  requiredCustomFields?: CustomFieldIdentifierDto[];
  awardDate?: string;
  renewalDate?: string;
  counterpartyContactPerson?: CounterpartyContactPersonDto;
  deliveryTerms?: string;
  estimatedValue?: number;
  endDate?: ContractEndDateDto;
  externalLink?: string;
  industries?: IndustryDto[];
  internalReferenceNumber?: string;
  linkedContracts?: LinkedContractDto[];
  originSourcingEvent?: OriginSourcingEventDto;
  paymentTermsInDays?: number;
  pricePerMonth?: number;
  pricePerYear?: number;
  priority?: ContractPriority;
  purchasingPolicy?: string;
  risk?: ContractRisk;
  startDate?: string;
  totalLiability?: number;
  totalPrice?: number;
  warrantyExpirationDate?: string;
  terminationDate?: TerminationDateDto;
  ifrsValues?: IfrsValuesDto;
  internalParties?: InternalPartiesDto;
  supplierInfo?: SupplierInfoDto;
  supplierCategory?: Category;
  noticePeriod?: number;
  options?: OptionDto[];
  projects?: ProjectDto[];
};
export type SignedDocumentStatus =
  | "Unsigned"
  | "WaitingForAttachments"
  | "PartiallySigned"
  | "Signed"
  | "Canceled"
  | "Expired";
export type DocumentSignerStatusDto =
  | "DocumentEmailNotOpened"
  | "DocumentEmailOpened"
  | "DocumentLinkOpened"
  | "DocumentRead"
  | "DocumentSigned"
  | "EmailSendingFailed";
export type DocumentSignerDto = {
  id: string;
  firstName: string;
  lastName: string;
  email: string;
  status: DocumentSignerStatusDto;
  statusTimestamp?: string;
};
export type SignedAttachmentDto = {
  id: string;
  title?: string;
  description?: string;
  fileName?: string;
};
export type SignedDocumentDto = {
  id: string;
  name: string;
  uploadedAt: string;
  sentForSigningBy: PersonDto;
  status: SignedDocumentStatus;
  signers: DocumentSignerDto[];
  signicatDocumentId: string;
  signingCompleted: boolean;
  deadline?: string;
  attachments?: SignedAttachmentDto[];
};
export type ContractDocumentFieldsDto = {
  documents: ViewDocumentAsOwnerDto[];
  signedDocuments: SignedDocumentDto[];
};
export type ContractNotificationTypeDto =
  | "EndDateReminder"
  | "RenewalDateReminder"
  | "WarrantyExpirationDateReminder"
  | "StartDateReminder"
  | "TerminationDateReminder"
  | "NoticePeriodReminder"
  | "OptionExecutionDeadlineReminder"
  | "OptionStartDateReminder"
  | "NoEndDateReminder";
export type ContractNotificationDurationType = "Days" | "Months";
export type NotificationInAdvanceConfigDto = {
  durationType: ContractNotificationDurationType;
  amount: number;
};
export type ContractNotificationDto = {
  id: string;
  type: ContractNotificationTypeDto;
  inAdvanceConfig?: NotificationInAdvanceConfigDto;
  receivers: PersonDto[];
};
export type ContractNotificationFieldsDto = {
  notifications: ContractNotificationDto[];
};
export type ContractDto = {
  id: string;
  owningOrganizationId: string;
  createdAt: string;
  modifiedAt: string;
  createdBy: PersonDto;
  state: ContractStateDto;
  status?: ContractStatusDto;
  title: string;
  description?: string;
  timezone: string;
  currency: Currency;
  counterparty?: CounterpartyDto;
  internalOwner: PersonDto;
  template?: ContractTemplateMinDto;
  editingMetaFields?: ContractEditingMetaFieldsDto;
  dataFields?: ContractDataFieldsDto;
  documentFields?: ContractDocumentFieldsDto;
  notificationFields?: ContractNotificationFieldsDto;
  canEditEverything: boolean;
  canViewDataFields: boolean;
  canViewDocuments: boolean;
  canViewNotifications: boolean;
  canViewTasks: boolean;
  canCreateTasks: boolean;
  canViewParticipants: boolean;
  canUpdateParticipants: boolean;
  owningOrgHasSignatureAccess: boolean;
};
export type CreateContractFromSourcingEventRequest = {
  eventId: string;
  bidId: string;
};
export type DeleteCustomFieldDto = {
  sectionId: string;
  fieldId: string;
};
export type UpsertCustomContractFieldDto = {
  field: CustomNumberFieldDto;
  sectionId: string;
};
export type ContractTaskCommentDto = {
  id: string;
  text?: string;
  createdBy: PersonDto;
  createdAt: string;
  modifiedAt: string;
  deletedAt?: string;
  canUpdate: boolean;
};
export type UpdateContractTaskCommentRequest = {
  text: string;
};
export type ContractTaskStatus = "Open" | "Completed";
export type ContractTaskStatusDto = "Open" | "Completed" | "Overdue";
export type ResponsibleOrganizationDto = {
  id: string;
  name: string;
};
export type ContractTaskResponsiblePartyDto =
  | "Organization"
  | "PersonInOrganization";
export type ContractTaskDto = {
  id: string;
  status: ContractTaskStatus;
  displayStatus: ContractTaskStatusDto;
  owningOrganizationName: string;
  owningOrganizationId: string;
  title: string;
  description: string;
  dueDate: string;
  responsibleOrganization: ResponsibleOrganizationDto;
  responsiblePerson: PersonDto;
  responsibleParty: ContractTaskResponsiblePartyDto;
  contractId: string;
  contractTitle: string;
  canEdit: boolean;
  canDelete: boolean;
};
export type UpdateTaskRequest = {
  title?: string;
  description?: string;
  dueDate?: string;
  status?: ContractTaskStatus;
  responsiblePerson?: string;
};
export type ContractTaskChangeType =
  | "Description"
  | "DueDate"
  | "ResponsibleParty"
  | "Status"
  | "Title";
export type ContractTaskChangeDto = {
  id: string;
  createdAt: string;
  changedByFullName: string;
  type: ContractTaskChangeType;
  oldValue: string;
  newValue: string;
};
export type ListChangesForContractTaskResponse = {
  changes: ContractTaskChangeDto[];
};
export type ListCommentsForContractTaskResponse = {
  comments: ContractTaskCommentDto[];
};
export type CreateContractTaskCommentRequest = {
  text: string;
};
export type TextDocumentDto = {
  id: string;
  createdAt: string;
  modifiedAt: string;
  owner: string;
  createdBy?: PersonDto;
  modifiedBy?: PersonDto;
  name: string;
  text: string;
  tags: string[];
};
export type CreateContractTextDocumentRequest = {
  name: string;
  text: string;
  tags: any[];
  contractId: string;
};
export type TextDocumentsDto = {
  documents: TextDocumentDto[];
};
export type ContractStatisticsDto = {
  numberOfContracts: number;
  numberOfActiveContracts: number;
  annualValueOfActiveContracts: number;
  numberOfContractsExpiringThisYear: number;
  annualValueOfContractsExpiringThisYear: number;
};
export type CancelSignedDocumentRequest = {
  documentId: string;
  reason: string;
};
export type DeleteContractParticipantRequest = {
  organizationId: string;
  personId?: string;
};
export type ContractOwningOrgDto = {
  id: string;
  name: string;
};
export type ContractRoleType = "Editor" | "Viewer" | "Counterparty";
export type ContractOrgParticipantDto = {
  id: string;
  roleType: ContractRoleType;
  name: string;
};
export type ContractPersonParticipantDto = {
  id: string;
  roleType?: ContractRoleType;
  email: string;
  firstName?: string;
  lastName?: string;
  organizationId: string;
  organizationName: string;
};
export type ListParticipantsForContractResponse = {
  owningOrganization: ContractOwningOrgDto;
  organizationParticipants: ContractOrgParticipantDto[];
  personParticipants: ContractPersonParticipantDto[];
};
export type CreateContractParticipantRequest = {
  organizationId: string;
  personId?: string;
  roleType: ContractRoleType;
  shouldNotifyByEmail: boolean;
};
export type CreateContractTaskRequest = {
  title: string;
  description: string;
  dueDate: string;
  responsiblePersonId: string;
};
export type FilterOptionDto = {
  id: string;
  name: string;
};
export type ContractFilterOptionsDto = {
  counterparties: FilterOptionDto[];
  internalParties: FilterOptionDto[];
  persons: FilterOptionDto[];
  projects: ProjectDto[];
};
export type ContractListItemDto = {
  id: string;
  status?: ContractStatusDto;
  state: ContractStateDto;
  title: string;
  timezone: string;
  currency: Currency;
  counterparty?: CounterpartyDto;
  internalParties?: InternalPartyDto[];
  endDate?: string;
  hasNoEndDate?: boolean;
  estimatedValue?: number;
  template?: ContractTemplateMinDto;
  owningOrganization: OrgPitchDto;
  internalOwner: PersonDto;
  containsIfrsValues: boolean;
  iamParticipant: boolean;
  projects?: ProjectDto[];
};
export type ListContractsResponseDto = {
  contracts: ContractListItemDto[];
};
export type GptRole = "system" | "assistant" | "user" | "function" | "tool";
export type GptMessage = {
  role: GptRole;
  content: string;
};
export type AnalyzeDocumentResponse = {
  messages: GptMessage[];
};
export type ChatCompletionRequest = {
  model: string;
  messages: GptMessage[];
  temperature: number;
};
export type UpdateDocumentNameRequest = {
  name: string;
};
export type GetDownloadLinkResponse = {
  url: string;
};
export type CreateDocumentTemplateRequest = {
  name: string;
  text: string;
  tags: string[];
};
export type DownloadTextDocumentRequest = {
  fileName: string;
  content: string;
};
export type SearchIndustriesResponse = {
  industries: IndustryDto[];
};
export type NotificationTypeDto =
  | "ContractTaskCommentAdded"
  | "ContractTaskDelegated"
  | "ContractTaskDeleted"
  | "ContractTaskDueDateUpdated"
  | "ContractTaskStatusUpdated"
  | "ContractTaskDueDateReminder"
  | "ContractSharedWithOrg"
  | "ContractSharedWithDepartment"
  | "ContractSharedWithPerson"
  | "ContractDocumentCompletedSigning"
  | "ContractDateReminder"
  | "SourcingEventOrganizationInvite"
  | "SourcingEventAnnouncement"
  | "SourcingEventAwarded"
  | "SourcingEventNewQuestion"
  | "SourcingEventNewQuestionComment"
  | "SourcingEventQuestionStatusUpdated"
  | "BidDeliveryDeadline"
  | "BidDelivered";
export type SourcingEventNotificationMetaDto = {
  eventId: string;
  eventTitle: string;
  eventOwningOrganizationId: string;
  eventOwningOrganizationName: string;
};
export type BidNotificationMetaDto = {
  eventId: string;
  eventTitle: string;
  supplierOrganizationName: string;
};
export type SourcingEventQuestionNotificationMetaDto = {
  eventId: string;
  eventTitle: string;
  questionId: string;
  questionSubject: string;
};
export type SourcingEventQuestionCommentNotificationMetaDto = {
  eventId: string;
  eventTitle: string;
  questionId: string;
  questionSubject: string;
  commentAddedByBuyer: boolean;
};
export type SourcingEventAnnouncementNotificationMetaDto = {
  eventId: string;
  eventTitle: string;
  announcementId: string;
  announcementTitle: string;
};
export type ContractNotificationMetaDto = {
  triggeredByFirstName?: string;
  contractName: string;
  contractId: string;
};
export type ContractDateReminderType =
  | "EndDateReminder"
  | "WarrantyExpirationDateReminder"
  | "RenewalDateReminder"
  | "StartDateReminder"
  | "TerminationDateReminder"
  | "NoticePeriodReminder"
  | "OptionExecutionDeadlineReminder"
  | "OptionStartDateReminder"
  | "NoEndDateReminder";
export type ContractReminderNotificationMetaDto = {
  contractName: string;
  contractId: string;
  contractTimezone: string;
  reminderType: ContractDateReminderType;
  date: string;
};
export type ContractTaskNotificationMetaDto = {
  triggeredByFirstName?: string;
  taskId: string;
  taskName: string;
  contractName: string;
  contractId: string;
};
export type ContractDocumentNotificationMetaDto = {
  documentId: string;
  documentName: string;
  contractName: string;
  contractId: string;
};
export type NotificationTypeMetaDto = {
  sourcingEvent?: SourcingEventNotificationMetaDto;
  bid?: BidNotificationMetaDto;
  sourcingEventQuestion?: SourcingEventQuestionNotificationMetaDto;
  sourcingEventQuestionComment?: SourcingEventQuestionCommentNotificationMetaDto;
  sourcingEventAnnouncement?: SourcingEventAnnouncementNotificationMetaDto;
  contract?: ContractNotificationMetaDto;
  contractReminder?: ContractReminderNotificationMetaDto;
  contractTask?: ContractTaskNotificationMetaDto;
  contractDocument?: ContractDocumentNotificationMetaDto;
};
export type NotificationDto = {
  id: string;
  createdAt: string;
  peeked: boolean;
  viewed: boolean;
  type: NotificationTypeDto;
  meta: NotificationTypeMetaDto;
};
export type ListNotificationsResponse = {
  notifications: NotificationDto[];
};
export type OrgUserDto = {
  person: PersonDto;
  roles: OrganizationRoleType[];
};
export type ListOrganizationUsersResponse = {
  users: OrgUserDto[];
  count: number;
};
export type ListOrganizationsForMeResponse = {
  orgs: OrgDto[];
};
export type EmailInviteTypeDto = "Supplier" | "SourcingEvent" | "OrgUser";
export type EmailInviteContextDto = {
  emailInviteId: string;
  emailInviteType: EmailInviteTypeDto;
  secret: string;
};
export type CreateOrgRequest = {
  companyName: string;
  countryId: string;
  organizationNumber?: string;
  industryIds: string[];
  emailInviteContext?: EmailInviteContextDto;
};
export type MarkNotificationsRequest = {
  notificationIds: string[];
};
export type AccessTokenDto = {
  accessToken: string;
  expiresIn: number;
  scope: string;
  tokenType: string;
};
export type GetAccessTokenRequest = {
  organizationId: string;
  personId: string;
};
export type SearchOrganizationResponse = {
  organization: OrgPitchDto;
};
export type CreateApiClientResponse = {
  clientId: string;
  secret: string;
};
export type CreateOrganizationEntryRequest = {
  name: string;
  industryIds: string[];
  countryId: string;
  organizationNumber: string;
};
export type UpdateOrganizationEntryRequest = {
  name?: string;
  countryId?: string;
  organizationNumber?: string;
  addContactPerson?: ContactPersonDto;
  editContactPerson?: ContactPersonDto;
  removeContactPersonEmail?: string;
};
export type LinkOrganizationEntryRequest = {
  organizationId: string;
};
export type SearchOrganizationsResponse = {
  organizations: OrgPublicProfileDto[];
};
export type AcceptedInfoDto = {
  acceptedAt: string;
  organizationName: string;
  personFirstName?: string;
  personLastName?: string;
  personEmail: string;
};
export type SupplierEmailInviteDto = {
  id: string;
  sentAt: string;
  sentBy: PersonDto;
  email: string;
  organizationName: string;
  personFirstName: string;
  personLastName: string;
  acceptedInfo?: AcceptedInfoDto;
};
export type OrgUserEmailInviteDto = {
  id: string;
  sentAt: string;
  sentBy: PersonDto;
  email: string;
  role: OrgUserInviteRole;
  organizationName: string;
};
export type EditName = {
  value: string;
};
export type EditCountry = {
  countryId: string;
};
export type EditAboutUs = {
  value?: string;
};
export type EditSpecializations = {
  value?: string;
};
export type EditHomePageUrl = {
  url?: string;
};
export type EditIndustries = {
  value: string[];
};
export type EditSize = {
  size?: OrganizationSize;
};
export type EditAdmins = {
  value: string[];
};
export type EditAzureUsersDefaultRole = {
  value: OrgUserInviteRole;
};
export type EditInternalParties = {
  parties?: string[];
  selfRegisteredParties?: string[];
};
export type EditContactPersons = {
  persons?: string[];
};
export type EditOrganizationRequest = {
  name?: EditName;
  country?: EditCountry;
  organizationNumber?: string;
  aboutUs?: EditAboutUs;
  specializations?: EditSpecializations;
  homePageUrl?: EditHomePageUrl;
  industries?: EditIndustries;
  size?: EditSize;
  admins?: EditAdmins;
  azureUsersDefaultRole?: EditAzureUsersDefaultRole;
  allowScimUsersOnly?: boolean;
  canOnlySeeCreatedContracts?: boolean;
  canOnlySeeCreatedEvents?: boolean;
  defaultNotificationSettings?: DefaultNotificationSettingsDto;
  internalParties?: EditInternalParties;
  contactPersons?: EditContactPersons;
};
export type ListOrganizationInvitesResponse = {
  invitations: SupplierEmailInviteDto[];
};
export type SupplierInviteParams = {
  personFirstName: string;
  personLastName: string;
  organizationName: string;
};
export type CreateEmailInviteRequest = {
  email: string;
  supplierInvite: SupplierInviteParams;
  language: EmailInviteLanguage;
};
export type UpdateOrgTenantRequest = {
  tenantId?: string;
};
export type ListOrgUserEmailInvitesResponse = {
  invitations: OrgUserEmailInviteDto[];
};
export type CreateOrgUserEmailInviteRequest = {
  email: string;
  role: OrgUserInviteRole;
  language: EmailInviteLanguage;
  emailInviteSecretOverride?: string;
};
export type UpdateOrgUserRoleRequest = {
  newRoles: OrganizationRoleType[];
};
export type ListContractTasksResponseDto = {
  tasks: ContractTaskDto[];
};
export type ContractOwnerContextType = "Common" | "OrganizationOwner";
export type ContractTemplateDto = {
  id: string;
  name?: string;
  description?: string;
  commonType?: CommonTemplateType;
  ownerType: ContractOwnerContextType;
  createdAt: string;
  createdBy?: PersonDto;
  requiredFields: ContractDataFieldDto[];
  requiredCustomDataFields?: {
    [key: string]: {
      id?: ContractDataFieldSectionDto;
    };
  };
};
export type ListContractTemplatesResponseDto = {
  templates: ContractTemplateDto[];
};
export type UpsertContractTemplateRequest = {
  name: string;
  description?: string;
  requiredDataFields: ContractDataFieldNameDto[];
  requiredCustomDataFields: CustomFieldIdentifierDto[];
};
export type CounterpartyInputDto = {
  organizationId?: string;
  organizationEntryId?: string;
};
export type EditCounterparty = {
  counterparty: CounterpartyInputDto;
};
export type EditTemplate = {
  templateId?: string;
};
export type EditInternalOwner = {
  personId: string;
};
export type EditCounterpartyContactPerson = {
  fullName?: string;
  email?: string;
  phone?: PhoneNumberDto;
  personId?: string;
};
export type EditDeliveryTerms = {
  terms?: string;
};
export type EditPurchasingPolicy = {
  policy?: string;
};
export type EditOriginSourcingEvent = {
  eventId?: string;
};
export type EditIndustryIds = {
  industryIds: string[];
};
export type EditInternalReferenceNumber = {
  number?: string;
};
export type EditExternalLink = {
  link?: string;
};
export type EditRisk = {
  risk: ContractRisk;
};
export type EditPriority = {
  priority: ContractPriority;
};
export type EditLinkedContracts = {
  contractIds: string[];
};
export type EditPricePerYear = {
  amount?: number;
};
export type EditPricePerMonth = {
  amount?: number;
};
export type EditTotalPrice = {
  amount?: number;
};
export type EditTotalLiability = {
  amount?: number;
};
export type EditPaymentTermsInDays = {
  days?: number;
};
export type EditEndDate = {
  date?: string;
  hasNoEndDate: boolean;
};
export type EditStartDate = {
  date?: string;
};
export type EditAwardDate = {
  date?: string;
};
export type EditRenewalDate = {
  date?: string;
};
export type EditWarrantyExpirationDate = {
  date?: string;
};
export type EditValueAmount = {
  amount?: number;
};
export type EditIfrsValues = {
  startDate?: string;
  startDateSecondPeriod?: string;
  monthlyPayment?: number;
  paymentIntervalInMonths?: number;
  contractLengthInMonths?: number;
  interestRate?: number;
};
export type EditSupplierInfo = {
  isClimateCommitted: boolean;
  hasSignedCodeOfConduct: boolean;
  hasCustomerContact: boolean;
};
export type EditCurrency = {
  currency: Currency;
};
export type AddTerminationDate = {
  date: string;
  reason: string;
};
export type AddDateReminderNotification = {
  inAdvanceUnit: ContractNotificationDurationType;
  inAdvanceAmount: number;
  receiverIds: string[];
  dateReminderType: ContractDateReminderType;
};
export type AddAdjustment = {
  date: string;
  value: number;
};
export type EditSupplierCategory = {
  category?: Category;
};
export type EditNoticePeriod = {
  duration: number;
};
export type EditOption = {
  id: string;
  name: string;
  description: string;
  startDate?: string;
  deadline?: string;
  endDate?: string;
  duration?: number;
  isExecuted: boolean;
};
export type ExecuteOption = {
  id: string;
};
export type RemoveOption = {
  id: string;
};
export type UpdateContractRequest = {
  title?: string;
  description?: string;
  removeDocument?: string;
  counterparty?: EditCounterparty;
  template?: EditTemplate;
  editProjects?: string[];
  internalOwner?: EditInternalOwner;
  counterpartyContactPerson?: EditCounterpartyContactPerson;
  deliveryTerms?: EditDeliveryTerms;
  purchasingPolicy?: EditPurchasingPolicy;
  originSourcingEvent?: EditOriginSourcingEvent;
  industry?: EditIndustryIds;
  internalReferenceNumber?: EditInternalReferenceNumber;
  externalLink?: EditExternalLink;
  risk?: EditRisk;
  priority?: EditPriority;
  linkedContracts?: EditLinkedContracts;
  pricePerYear?: EditPricePerYear;
  pricePerMonth?: EditPricePerMonth;
  totalPrice?: EditTotalPrice;
  totalLiability?: EditTotalLiability;
  paymentTermsInDays?: EditPaymentTermsInDays;
  endDate?: EditEndDate;
  startDate?: EditStartDate;
  awardDate?: EditAwardDate;
  renewalDate?: EditRenewalDate;
  warrantyExpirationDate?: EditWarrantyExpirationDate;
  timezone?: EditTimezone;
  valueAmount?: EditValueAmount;
  ifrsValues?: EditIfrsValues;
  supplierInfo?: EditSupplierInfo;
  internalParties?: EditInternalParties;
  currency?: EditCurrency;
  terminationDate?: AddTerminationDate;
  addDateReminderNotification?: AddDateReminderNotification;
  removeNotification?: string;
  addDataFields?: ContractDataFieldNameDto[];
  editCustomFields?: {
    [key: string]: {
      id_1?: ContractDataFieldSectionDto;
      id_2?: ContractDataFieldSectionDto;
    };
  };
  removeDataField?: ContractDataFieldNameDto;
  addMonetaryValueAdjustment?: AddAdjustment;
  removeMonetaryValueAdjustment?: string;
  addPercentageValueAdjustment?: AddAdjustment;
  removePercentageValueAdjustment?: string;
  editSupplierCategory?: EditSupplierCategory;
  editNoticePeriod?: EditNoticePeriod;
  editOption?: EditOption;
  executeOption?: ExecuteOption;
  removeOption?: RemoveOption;
};
export type TextDocumentInputDto = {
  textDocumentId: string;
  htmlContent: string;
};
export type DocumentSignerMethod = "NoBankId" | "Handwritten";
export type SignerDto = {
  firstName: string;
  lastName: string;
  email: string;
  signatureMethod: DocumentSignerMethod;
};
export type CreateSignedDocumentRequest = {
  mainDocumentId?: string;
  textDocumentInput?: TextDocumentInputDto;
  attachments?: string[];
  textDocumentAttachments?: TextDocumentInputDto[];
  signers: SignerDto[];
  language: EmailInviteLanguage;
  deadline?: string;
};
export type CreateContractRequest = {
  title: string;
  timezone: string;
  templateId?: string;
  projects?: string[];
};
export type ListPersonsResponse = {
  persons: PersonDto[];
  count: number;
};
export type MeDto = {
  id: string;
  identityId?: string;
  firstName?: string;
  lastName?: string;
  email: string;
  emailVerified: boolean;
  corporateTitle?: string;
  phoneNumber?: PhoneNumberDto;
  canCreateOrg: boolean;
  intercomSecretHash: string;
};
export type EditPhoneNumber = {
  value?: PhoneNumberDto;
};
export type EditMeRequest = {
  firstName?: string;
  lastName?: string;
  corporateTitle?: string;
  phoneNumber?: EditPhoneNumber;
};
export type CreateProductDto = {
  id?: string;
  quantity: NumberFieldDto;
  productFields: (TextFieldDto | NumberFieldDto | BooleanFieldDto)[];
};
export type AddProductDto = {
  product: CreateProductDto;
};
export type AddProductCommentDto = {
  comment: string;
};
export type EditProductDto = {
  id: string;
  quantity?: NumberFieldDto;
  productFields?: (TextFieldDto | BooleanFieldDto | NumberFieldDto)[];
  comment?: AddProductCommentDto;
};
export type DeleteProductDto = {
  productId: string;
};
export type EditProductGroupDto = {
  id: string;
  addProduct?: AddProductDto;
  editProduct?: EditProductDto;
  upsertProducts?: ProductDto[];
  deleteProduct?: DeleteProductDto;
};
export type CreateProductGroupDto = {
  sourcingEventId?: string;
  projectId?: string;
  name: string;
  originProductGroupId?: string;
  productFields: ProductTemplateFieldDto[];
  products: CreateProductDto[];
};
export type ProductTemplateDto = {
  id: string;
  name: string;
  createdAt: string;
  modifiedAt: string;
  productFields: ProductTemplateFieldDto[];
};
export type ProductTemplatesDto = {
  templates: ProductTemplateDto[];
};
export type EditStringValue = {
  value?: string;
};
export type EditLocalDateValue = {
  value?: string;
};
export type UpdateProjectRequest = {
  id: string;
  externalId?: EditStringValue;
  name?: string;
  description?: EditStringValue;
  startDate?: EditLocalDateValue;
  endDate?: EditLocalDateValue;
  parent?: EditStringValue;
  projectResponsible?: EditStringValue;
  sourcingResponsible?: EditStringValue;
  projectType?: EditStringValue;
  documents?: string[];
  removeDocument?: string;
};
export type CreateProjectRequest = {
  id?: string;
  externalId?: string;
  name: string;
  description?: string;
  startDate?: string;
  endDate?: string;
  parent?: string;
  projectResponsible?: string;
  sourcingResponsible?: string;
  projectType?: string;
  documents?: string[];
};
export type CreateProjectTextDocumentRequest = {
  name: string;
  text: string;
  tags: any[];
  projectId: string;
};
export type ProjectsDto = {
  projects: ProjectDto[];
};
export type ScimMember = {
  value: string;
  display?: string;
};
export type MetaDto = {
  resourceType: string;
  location?: string;
};
export type CreateGroupDto = {
  schemas: string[];
  externalId?: string;
  displayName: string;
  members?: ScimMember[];
  meta?: MetaDto;
};
export type TokenResponse = {
  token: string;
};
export type SearchOrganizationsAndEntriesResponse = {
  organizations: MinimalOrgDto[];
  count: number;
};
export type CreateSourcingEventTextDocumentRequest = {
  name: string;
  text: string;
  tags: string[];
  sourcingEventId: string;
};
export type BseBidStatusDto =
  | "InviteNotAccepted"
  | "InviteAccepted"
  | "InviteDeclined"
  | "DraftCreated"
  | "BidDelivered"
  | "DeadlinePassedAndBidDelivered"
  | "DeadlinePassedWithoutBidDelivered"
  | "IsWinner"
  | "IsLoser";
export type BseSupplierViewAwardedFieldsDto = {
  awardedAt: string;
  iamWinningDepartment: boolean;
  message?: string;
};
export type BseSupplierViewDto = {
  id: string;
  status: BseBidStatusDto;
  stateName: BseStateNameDto;
  publishedAt: string;
  modifiedAt: string;
  contactPerson: PersonDto;
  owningOrganization: OrgPublicProfileDto;
  title: string;
  industries: IndustryDto[];
  documents: ViewSharedDocumentDto[];
  description: string;
  deadline: string;
  createdBidId?: string;
  invitationAccepted: boolean;
  awardedFieldsDto?: BseSupplierViewAwardedFieldsDto;
  questions: BseQuestionDto[];
  announcements: BseAnnouncementDto[];
  canDeliverBid: boolean;
  hasRegisteredViewing: boolean;
  canAskQuestions: boolean;
};
export type BseBidDraftFieldsDto = {
  comment?: string;
  products?: ProductGroupBidDto[];
};
export type BseBidDeliveredFieldsDto = {
  comment: string;
  products?: ProductGroupBidDto[];
  deliveredAt: string;
  deliveredBy: PersonDto;
  winningBid?: boolean;
};
export type BseBidDto = {
  id: string;
  status: BseBidStatusDto;
  owningOrganization: OrgPitchDto;
  createdAt: string;
  modifiedAt: string;
  createdBy: PersonDto;
  forEvent: BseSupplierViewDto;
  documents: ViewDocumentAsOwnerDto[];
  uploads: UploadDto[];
  draftFields?: BseBidDraftFieldsDto;
  deliveredFields?: BseBidDeliveredFieldsDto;
  canEdit: boolean;
  uniqueForBseBidDto: boolean;
};
export type EditComment = {
  value?: string;
};
export type RemoveDocument = {
  value: string;
};
export type EditProducts = {
  products: any[];
};
export type EditBidRequest = {
  comment?: EditComment;
  removeDocument?: RemoveDocument;
  editProducts?: EditProducts;
};
export type CreateBseAnnouncementRequest = {
  title: string;
  content: string;
};
export type GetProcurementProtocolRequest = {
  descriptionHtml: string;
};
export type CreateBseQuestionRequest = {
  subject: string;
  content: string;
  announcementId?: string;
};
export type BidDto = {
  id: string;
  status: BseBidStatusDto;
  owningOrganization: OrgDto;
  createdAt: string;
  modifiedAt: string;
  createdBy: PersonDto;
  forEvent: BseSupplierViewDto;
  winningBid?: boolean;
  canEdit: boolean;
  uniqueForBidDto: boolean;
};
export type ListBidsResponse = {
  bids: BidDto[];
};
export type SeEmailInviteDto = {
  id: string;
  sentAt: string;
  sentByPersonFirstName?: string;
  sentByPersonLastName?: string;
  owningOrganizationName: string;
  eventTitle: string;
  eventDeadline: string;
};
export type SeSupplierViewDto = {
  id: string;
  title: string;
  status: BseBidStatusDto;
  createdAt: string;
  modifiedAt: string;
  createdBy: PersonDto;
  deadline: string;
  owningOrganizationName: string;
  contactPerson: PersonDto;
  timezone: string;
};
export type ListSeSupplierViewsResponse = {
  uniqueForListSourcingEventsAsSupplierResponse: boolean;
  events: SeSupplierViewDto[];
};
export type SupplierProductDto = {
  id: string;
  quantity: NumberFieldDto;
  productFields: (TextFieldDto | BooleanFieldDto | NumberFieldDto)[];
};
export type SupplierProductGroupDto = {
  id: string;
  sourcingEventId: string;
  name: string;
  productFields: ProductTemplateFieldDto[];
  products: SupplierProductDto[];
};
export type SupplierProductGroupsDto = {
  productGroups: SupplierProductGroupDto[];
};
export type UpdateTextDocumentRequest = {
  id: string;
  name?: string;
  text?: string;
  tags?: string[];
};
export const {
  useAdminListAllEntitiesQuery,
  useAdminListOrganizationsQuery,
  useAdminUpdateOrganizationMutation,
  useGetBaseDataQuery,
  useGetTypesQuery,
  useCreateBasicSourcingEventMutation,
  useGetNegotiationRoundQuery,
  useGetBasicSourcingEventQuery,
  useUpdateBasicSourcingEventMutation,
  useAwardToBidMutation,
  useListReceivedBseBidsQuery,
  useGetReceivedBseBidQuery,
  useCompleteEvaluationMutation,
  useCreateSourcingEventCopyMutation,
  useCreateEmailInviteReminderMutation,
  useCreateNegotiationRoundMutation,
  useOpenCompletedEventMutation,
  usePublishBasicSourcingEventMutation,
  useUpdateBseQuestionMutation,
  useCreateBseQuestionCommentAsBuyerMutation,
  useCreateUploadForBseMutation,
  useDeleteSourcingEventMutation,
  useGetProductGroupsForSourcingEventQuery,
  useListEventsForBuyerQuery,
  useCreateContractFromSourcingEventMutation,
  useDeleteCustomContractDataFieldMutation,
  useUpsertCustomContractDataFieldMutation,
  useUpsertContractDataFieldsSectionMutation,
  useSignatureWebhookMutation,
  useDeleteContractTaskCommentMutation,
  useUpdateContractTaskCommentMutation,
  useDeleteContractTaskMutation,
  useUpdateContractTaskMutation,
  useListChangesForContractTaskQuery,
  useListCommentsForContractTaskQuery,
  useCreateContractTaskCommentMutation,
  useCreateContractTextDocumentMutation,
  useListTextDocumentsForContractQuery,
  useDownloadMultiIfrsReportQuery,
  useGetContractStatisticsQuery,
  useCancelSignedDocumentMutation,
  useDownloadIfrsReportQuery,
  useDeleteContractParticipantMutation,
  useListParticipantsForContractsQuery,
  useCreateContractParticipantMutation,
  useCreateContractTaskMutation,
  useCreateUploadForContractMutation,
  useListContractFilterOptionsQuery,
  useListContractsQuery,
  useSendAiChatMessageMutation,
  useUpdateDocumentNameMutation,
  useStartDocumentChatMutation,
  useGetDownloadLinkQuery,
  useListDocumentTemplatesQuery,
  useCreateDocumentTemplateMutation,
  useDownloadTextDocumentMutation,
  useUpdateEmailStatusMutation,
  useConfirmUploadMutation,
  useSearchIndustriesQuery,
  useListMyNotificationsQuery,
  useListOrganizationUsersQuery,
  useListOrganizationsForMeQuery,
  useCreateOrganizationForMeMutation,
  useMarkNotificationsAsPeekedMutation,
  useMarkNotificationsAsViewedMutation,
  useGetAccessTokenMutation,
  useSearchOpenOrgContactInfoQuery,
  useListContractsForOrgQuery,
  useCreateApiClientMutation,
  useCreateOrganizationEntryMutation,
  useGetOrganizationEntryQuery,
  useUpdateOrganizationEntryMutation,
  useLinkOrganizationEntryMutation,
  useSearchOrganizationsQuery,
  useGetSupplierEmailInviteQuery,
  useGetOrgUserEmailInviteQuery,
  useAcceptOrgUserEmailInviteMutation,
  useUpdateOrganizationMutation,
  useGetOrganizationQuery,
  useListSupplierEmailInvitesQuery,
  useCreateSupplierEmailInviteMutation,
  useDeleteSupplierEmailInviteMutation,
  useCreateSupplierEmailInviteReminderMutation,
  useUpdateOrganizationTenantMutation,
  useListOrgUserEmailInvitesQuery,
  useCreateOrgUserEmailInviteMutation,
  useDeleteOrgUserEmailInviteMutation,
  useUpdateOrgUserRoleMutation,
  useDeleteOrgUserMutation,
  useListContractTasksForOrganizationQuery,
  useListContractTemplatesQuery,
  useCreateContractTemplateMutation,
  useGetTaskQuery,
  useUpdateContractTemplateMutation,
  useGetContractQuery,
  useUpdateContractMutation,
  usePublishContractMutation,
  useCreateSignedDocumentMutation,
  useListContractTasksQuery,
  useCreateContractMutation,
  useListPersonsQuery,
  useGetMeQuery,
  useUpdateMeMutation,
  useEditProductGroupMutation,
  useCreateProductGroupMutation,
  useDeleteProductGroupMutation,
  useGetProductGroupsForProjectQuery,
  useGetProductTemplatesQuery,
  useUpdateProjectMutation,
  useCreateProjectMutation,
  useCreateProjectTextDocumentMutation,
  useListTextDocumentsForProjectQuery,
  useGetProjectQuery,
  useGetProjectsQuery,
  useCreateUploadForProjectMutation,
  useGetGroupsQuery,
  useCreateGroupMutation,
  useDeleteGroupMutation,
  useGetGroupQuery,
  useUpdateGroupMutation,
  useGetSchemasQuery,
  useGetTokenQuery,
  useGetUsersQuery,
  useCreateUserMutation,
  useDeleteUserMutation,
  useGetUserQuery,
  useUpdateUserMutation,
  useSearchOrganizationsAndEntriesQuery,
  useGetSignedDocumentDownloadLinkQuery,
  useGetSignedAttachmentDownloadLinkQuery,
  useCreateSourcingEventTextDocumentMutation,
  useListTextDocumentsForSourcingEventQuery,
  useGetBseBidQuery,
  useUpdateBseBidMutation,
  useCreateUploadForBseBidMutation,
  useCreateBseBidDraftAsDepartmentMutation,
  useGetBseSupplierViewQuery,
  useCreateBseAnnouncementMutation,
  useUnDeliverBseBidMutation,
  useDeliverBseBidMutation,
  useAcceptInvitationToBidMutation,
  useDeclineInvitationToBidMutation,
  useDownloadPublicProcurementProtocolPartOneMutation,
  useDownloadQaReportQuery,
  useCreateBseQuestionMutation,
  useCreateBseQuestionCommentAsSupplierMutation,
  useMarkAsViewedMutation,
  useDeleteBidMutation,
  useListBidsCreatedByDepartmentQuery,
  useGetSeEmailInviteQuery,
  useAcceptSeEmailInviteMutation,
  useListSeSupplierViewsQuery,
  useGetProductGroupsAsSupplierQuery,
  useUpdateTextDocumentMutation,
  useDeleteTextDocumentMutation,
  useDeleteUploadMutation,
} = injectedRtkApi;
