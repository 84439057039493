import { Button, Flex, Icon, Spinner } from "@chakra-ui/react";
import { t } from "i18next";
import { useMemo } from "react";
import { FaEllipsisH } from "react-icons/fa";

export const Paging = ({
  limit,
  skip,
  setSkip,
  isLoading,
  count,
}: {
  limit: number;
  skip: number;
  setSkip: (skip: number) => void;
  isLoading: boolean;
  count?: number;
}) => {
  const previousPage = useMemo(() => {
    return limit ? skip / limit : 1;
  }, [limit, skip]);

  const currentPage = useMemo(() => {
    return limit ? skip / limit + 1 : 1;
  }, [limit, skip]);

  const nextPage = useMemo(() => {
    return limit ? skip / limit + 2 : 1;
  }, [limit, skip]);

  const lastPage = useMemo(() => {
    return count ? Math.ceil(count / limit) : undefined;
  }, [count, limit]);

  return (
    <Flex alignItems={"center"} justifyContent={"center"} p="4">
      <Flex w="10">{isLoading && <Spinner color="smPrimary" size="sm" />}</Flex>
      <Flex alignItems={"center"} justifyContent={"center"} columnGap={"1"}>
        <Button
          colorScheme="teal"
          variant={"ghost"}
          size={"xs"}
          isDisabled={skip - limit < 0}
          onClick={() => setSkip(skip - limit)}
        >
          {t("Previous")}
        </Button>
        {count && previousPage > 1 ? (
          <>
            <Flex
              borderColor="smPrimary"
              border="1px solid"
              color="smPrimary"
              fontWeight={"bold"}
              fontSize={"xs"}
              rounded="full"
              width="6"
              height="6"
              justifyContent={"center"}
              alignItems={"center"}
              cursor="pointer"
              onClick={() => setSkip(0)}
            >
              {1}
            </Flex>
            <Icon as={FaEllipsisH} color="smPrimary" />
          </>
        ) : null}
        {skip / limit > 0 ? (
          <Flex
            borderColor="smPrimary"
            border="1px solid"
            color="smPrimary"
            fontWeight={"bold"}
            fontSize={"xs"}
            rounded="full"
            width="6"
            height="6"
            justifyContent={"center"}
            alignItems={"center"}
            cursor="pointer"
            onClick={() => setSkip(skip - limit)}
          >
            {previousPage}
          </Flex>
        ) : null}
        <Flex
          backgroundColor="smPrimary"
          color="smBackground"
          fontWeight={"bold"}
          fontSize={"xs"}
          rounded="full"
          width="6"
          height="6"
          justifyContent={"center"}
          alignItems={"center"}
        >
          {currentPage}
        </Flex>
        {!lastPage || nextPage <= lastPage ? (
          <Flex
            borderColor="smPrimary"
            border="1px solid"
            color="smPrimary"
            fontWeight={"bold"}
            fontSize={"xs"}
            rounded="full"
            width="6"
            height="6"
            justifyContent={"center"}
            alignItems={"center"}
            cursor="pointer"
            onClick={() => setSkip(skip + limit)}
          >
            {nextPage}
          </Flex>
        ) : null}
        {count && lastPage && lastPage > nextPage ? (
          <>
            <Icon as={FaEllipsisH} color="smPrimary" />
            <Flex
              borderColor="smPrimary"
              border="1px solid"
              color="smPrimary"
              fontWeight={"bold"}
              fontSize={"xs"}
              rounded="full"
              width="6"
              height="6"
              justifyContent={"center"}
              alignItems={"center"}
              cursor="pointer"
              onClick={() => setSkip(count - (count % limit))}
            >
              {lastPage}
            </Flex>
          </>
        ) : null}
        <Button
          colorScheme="teal"
          variant={"ghost"}
          size={"xs"}
          isDisabled={!!count && count <= skip + limit}
          onClick={() => setSkip(skip + limit)}
        >
          {t("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};
