import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { debounce, uniqBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { OrganizationRoleType, PersonDto, useListPersonsQuery } from "../../autogen/bff-api";
import { displayPersonNameWithEmail } from "../../pages/contracts/view-single/sharing/AddExternalParticipantModal";
import { SingleSelector } from "../input/Selector/SingleSelector";

export const PersonSelector = ({
  organizationIds,
  selectedPerson,
  placeholder,
  onChange,
  onBlur,
  autoFocus = false,
  personsToExclude = [],
  additionalPersons,
  isDisabled = false,
  isClearable = true,
  size = "sm",
  roles,
}: {
  organizationIds: string[];
  selectedPerson?: PersonDto | null;
  personsToExclude?: string[];
  additionalPersons?: PersonDto[];
  placeholder?: string;
  onChange: (person: PersonDto | null) => void | Promise<void>;
  onBlur?: () => void;
  autoFocus?: boolean;
  isDisabled?: boolean;
  isClearable?: boolean;
  size?: "sm" | "md" | "lg";
  roles?: OrganizationRoleType[];
}) => {
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isFetching } = useListPersonsQuery(
    organizationIds.length
      ? {
          orgIds: organizationIds,
          name: debouncedSearchName ? debouncedSearchName : undefined,
          roles,
          limit: 10,
        }
      : skipToken
  );

  const options: { label: string; value: string }[] = useMemo(() => {
    const allPersons = uniqBy([...(data?.persons ?? []), ...(additionalPersons ?? [])], (p) => p.id);
    return (
      allPersons
        .filter((p) => !personsToExclude.includes(p.id))
        .filter((o) => selectedPerson?.id !== o.id)
        .filter(
          (p) =>
            `${p.firstName} ${p.lastName}`.toLowerCase().includes(debouncedSearchName.toLowerCase()) ||
            `${p.lastName} ${p.firstName}`.toLowerCase().includes(debouncedSearchName.toLowerCase()) ||
            p.email.toLowerCase().includes(debouncedSearchName.toLowerCase())
        )
        .map((o) => ({ label: displayPersonNameWithEmail(o), value: o.id })) ?? []
    );
  }, [additionalPersons, data?.persons, personsToExclude, debouncedSearchName, selectedPerson]);

  return (
    <SingleSelector
      size={size}
      placeholder={{ text: placeholder ?? `${t("Select person")}...`, color: "" }}
      value={
        selectedPerson
          ? {
              label: displayPersonNameWithEmail(selectedPerson),
              value: selectedPerson.id,
            }
          : undefined
      }
      inputValue={searchName}
      isLoading={isFetching}
      onInputChange={(name: string) => {
        setSearchName(name);
        handleDebouncedSearchName(name);
      }}
      options={options}
      noMatchingOptionsMessage={t("No persons found") ?? ""}
      onChange={async (o) => {
        const person = data?.persons.find((p) => p.id === o?.value);
        if (o && !person) throw Error("Person not found");
        await onChange(person ?? null);
      }}
      onBlur={onBlur}
      autoFocus={autoFocus}
      isClearable={isClearable}
      isDisabled={isDisabled}
    />
  );
};
