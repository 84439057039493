import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Input, Spinner } from "@chakra-ui/react";
import { debounce } from "lodash";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { ContactPersonForm } from "../../../../../../common/ContactPersonForm";
import { PhoneNumberInput } from "../../../../../../common/input/PhoneNumberInput";
import { SingleSelector } from "../../../../../../common/input/Selector/SingleSelector";
import { PersonSelector } from "../../../../../../common/persons/PersonSelector";
import { useAppDispatch } from "../../../../../../common/redux/hooks";
import { containsError } from "../../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../../useContractState";
import { RemoveDataFieldButton } from "../RemoveDataFieldButton";

interface Props {
  removeDataField?: () => Promise<void>;
  isRequiredButNotProvided: boolean;
}

export const CounterpartyContactPerson = ({ removeDataField, isRequiredButNotProvided }: Props) => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const errorMessage = containsError(contractState, "EditCounterpartyContactPerson") ? t("Update failed") : null;
  const dispatch = useAppDispatch();
  const [fullName, setFullName] = useState<string>();
  const [email, setEmail] = useState<string>();
  const [selectedPhoneNumber, setSelectedPhoneNumber] = useState<PhoneNumberInput | null>(null);

  const counterpartyOrganization = useMemo(
    () => contractState.contract.counterparty?.organization,
    [contractState.contract.counterparty?.organization]
  );

  const counterpartyOrganizationEntry = useMemo(
    () => contractState.contract.counterparty?.organizationEntry,
    [contractState.contract.counterparty?.organizationEntry]
  );

  const counterpartyContactPerson = useMemo(() => {
    return contractState.contract.dataFields.counterpartyContactPerson;
  }, [contractState.contract.dataFields.counterpartyContactPerson]);

  useEffect(() => {
    if (counterpartyContactPerson) {
      if (!selectedPhoneNumber) {
        setSelectedPhoneNumber(
          counterpartyContactPerson.phoneNumber
            ? {
                country: {
                  name: counterpartyContactPerson.phoneNumber.callingCode.countryName,
                  alpha3Code: counterpartyContactPerson.phoneNumber.callingCode.countryAlpha3Code,
                  callingCode: counterpartyContactPerson.phoneNumber.callingCode.value,
                },
                number: counterpartyContactPerson.phoneNumber.number,
              }
            : null
        );
      }
      if (fullName === undefined) setFullName(counterpartyContactPerson.fullName);
      if (email === undefined) setEmail(counterpartyContactPerson.email);
    }
  }, [counterpartyContactPerson, email, fullName, selectedPhoneNumber]);

  const eventHandler = async ({
    personId,
    fullName,
    email,
    phoneNumber,
  }: {
    personId?: string;
    fullName?: string;
    email?: string;
    phoneNumber?: PhoneNumberInput;
  }) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditCounterpartyContactPerson",
          value: {
            fullName: fullName ?? null,
            email: email ?? null,
            phoneNumber: phoneNumber?.country
              ? {
                  callingCode: {
                    countryName: phoneNumber.country.name,
                    countryAlpha3Code: phoneNumber.country.alpha3Code,
                    value: phoneNumber.country.callingCode,
                  },
                  number: phoneNumber.number,
                }
              : null,
            personId: personId ?? null,
          },
        },
      })
    );
    setIsLoading(false);
  };

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedEventHandler = useMemo(() => debounce(eventHandler, 300), []);

  useEffect(() => {
    return () => {
      debouncedEventHandler.cancel();
    };
  }, [debouncedEventHandler]);

  const counterpartyPersonIsSelected = (): boolean => {
    if (contractState.contract.counterparty?.organization) return counterpartyContactPerson?.person !== null;
    return false;
  };

  return (
    <Box position="relative">
      <Box position="absolute" right="0" top="0" zIndex="1">
        <RemoveDataFieldButton removeDataField={removeDataField} />
      </Box>
      <FormControl isInvalid={errorMessage !== null} isRequired={isRequiredButNotProvided}>
        <FormLabel htmlFor={"value"} py="2">
          {t("Counterparty contact person")} {isLoading && <Spinner size="xs" />}
        </FormLabel>
        <Flex width="full">
          <Flex borderRadius={"10px"} width="full" flexDirection={"column"}>
            {counterpartyOrganization && (
              <>
                <PersonSelector
                  placeholder={`${t("Select contact person")}...`}
                  organizationIds={[counterpartyOrganization.id]}
                  selectedPerson={counterpartyContactPerson?.person}
                  onChange={(p) => {
                    eventHandler({ personId: p?.id });
                  }}
                />
                <Input
                  mt="1"
                  backgroundColor="smBackground"
                  placeholder={t("Full name") ?? ""}
                  type="text"
                  size="sm"
                  rounded="md"
                  value={fullName ?? ""}
                  isDisabled={counterpartyPersonIsSelected()}
                  onChange={(e) => {
                    setFullName(e.target.value);
                    debouncedEventHandler({ fullName: e.target.value });
                  }}
                />
                <Input
                  backgroundColor="smBackground"
                  mt="1"
                  placeholder={t("Email") ?? ""}
                  type="text"
                  size="sm"
                  rounded="md"
                  value={email ?? ""}
                  isDisabled={counterpartyPersonIsSelected()}
                  onChange={(e) => {
                    setEmail(e.target.value);
                    debouncedEventHandler({ email: e.target.value });
                  }}
                />
                <Box mt="1">
                  <PhoneNumberInput
                    bg="smBackground"
                    size="sm"
                    onChange={(phoneNumber) => {
                      setSelectedPhoneNumber(phoneNumber);
                      debouncedEventHandler({ phoneNumber: phoneNumber ?? undefined });
                    }}
                    value={selectedPhoneNumber}
                    isDisabled={counterpartyPersonIsSelected()}
                  />
                </Box>
              </>
            )}
            {counterpartyOrganizationEntry && (
              <>
                <SingleSelector
                  placeholder={{ text: `${t("Select contact person")}...`, color: "black" }}
                  value={
                    counterpartyContactPerson?.email
                      ? {
                          value: counterpartyContactPerson.email ?? "",
                          label: `${counterpartyContactPerson.fullName ?? ""} ${
                            counterpartyContactPerson.email ? `(${counterpartyContactPerson.email})` : ""
                          }`,
                        }
                      : undefined
                  }
                  onChange={(option) => {
                    setEmail(option?.value);
                    eventHandler({ email: option?.value });
                  }}
                  options={
                    counterpartyOrganizationEntry.contactPersons?.map((p) => ({
                      value: p.email,
                      label: `${p.name} (${p.email})`,
                    })) ?? []
                  }
                />
                <ContactPersonForm
                  showFormInitially={!counterpartyOrganizationEntry.contactPersons?.length}
                  organizationEntry={counterpartyOrganizationEntry}
                  onSubmit={({ email }) => {
                    setEmail(email);
                    debouncedEventHandler({ email });
                  }}
                />
              </>
            )}
          </Flex>
        </Flex>
        <FormHelperText>{t("Provide contact info")}</FormHelperText>
        <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
      </FormControl>
    </Box>
  );
};
