import { Button, Flex, Image, Spacer, useColorMode, useMediaQuery } from "@chakra-ui/react";
import { useNavigate } from "react-router-dom";
import { assets } from "../../../assets/assets";
import { urls } from "../../../urls";
import { LgNavbar } from "./LgNavbar/LgNavbar";
import { SmNavbar } from "./SmNavbar";

export const Navbar = () => {
  const navigate = useNavigate();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const theme = useColorMode();

  return (
    <Flex height="full" width="full" maxWidth="container.xl" flexDirection={"row"} alignItems="center" px="2">
      <Button
        variant={"unstyled"}
        ml={{ base: "5px", md: 0 }}
        onClick={() => {
          navigate(urls.home);
        }}
      >
        <Image src={theme.colorMode === "dark" ? assets.logo_dark : assets.logo} alt="Logo" h="60%" />
      </Button>
      <Spacer />
      {isLargerThan768 ? <LgNavbar /> : <SmNavbar />}
    </Flex>
  );
};
