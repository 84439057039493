import {
  Accordion,
  AccordionButton,
  AccordionIcon,
  AccordionItem,
  AccordionPanel,
  Box,
  Button,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { AdminOrgDto, useAdminListOrganizationsQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { DebugPrint } from "../DebugPrint";
import { AdminEditOrganizationModal } from "./AdminEditOrganizationModal";
import { AdminImpersonateUserModal } from "./AdminImpersonateUserModal";

export const OrgsList = () => {
  const authState = useLoggedInWithOrgContextState();
  const { data } = useAdminListOrganizationsQuery({
    organizationId: authState.selectedOrg.id,
  });
  const [updateOrganization, setUpdateOrganization] = useState<AdminOrgDto>();
  const [impersonateOrganization, setImpersonateOrganization] = useState<AdminOrgDto>();

  if (!data) return <div>{t("Loading...")}</div>;

  return (
    <>
      {updateOrganization && (
        <AdminEditOrganizationModal
          organization={updateOrganization}
          onClose={() => setUpdateOrganization(undefined)}
        />
      )}
      {impersonateOrganization && (
        <AdminImpersonateUserModal
          organization={impersonateOrganization}
          onClose={() => setImpersonateOrganization(undefined)}
        />
      )}
      <Accordion allowMultiple reduceMotion>
        {data.orgs.map((org) => {
          return (
            <AccordionItem key={org.id}>
              <h2>
                <AccordionButton>
                  <Box as="span" flex="1" textAlign="left">
                    {org.name}
                  </Box>
                  <AccordionIcon />
                </AccordionButton>
              </h2>
              <AccordionPanel pb={4}>
                <Button mb="10px" variant={"solid"} colorScheme="blue" onClick={() => setUpdateOrganization(org)}>
                  {t("Edit")}
                </Button>
                <Button
                  mb="10px"
                  variant={"solid"}
                  colorScheme="purple"
                  onClick={() => setImpersonateOrganization(org)}
                >
                  {t("Impersonate")}
                </Button>
                <DebugPrint data={org} />
              </AccordionPanel>
            </AccordionItem>
          );
        })}
      </Accordion>
    </>
  );
};
