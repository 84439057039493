import { Flex, Icon, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaSearch } from "react-icons/fa";
import { useParams } from "react-router-dom";
import {
  BseStateNameDto,
  BseStatusDto,
  BseSupplierViewDto,
  useGetBseSupplierViewQuery,
  useMarkAsViewedMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { Layout } from "../../../../common/layout/Layout";
import { ProcessBar } from "../../../../common/ProcessBar";
import { BseSupplierTab, useQueryParams } from "../../../../common/useQueryParams";
import { CONTENT_WIDTH, requireBooleanEnvVar } from "../../../../config";
import { EventDialog } from "./EventDialog";
import { BseSupplierViewEventInfo } from "./EventInfo";
import { EventMeta } from "./EventMeta";

const getCurrentStep = (event: BseSupplierViewDto, stateToStepMap: { [state in BseStatusDto]: string }) =>
  stateToStepMap[event.stateName];

export const BseSupplierView = () => {
  const { eventId } = useParams();
  const authState = useLoggedInWithOrgContextState();

  const [tabIndex, setTabIndex] = useState(0);
  const params = useQueryParams();
  const { t } = useTranslation();
  const displayer = useApiError();
  const [markAsViewed] = useMarkAsViewedMutation();
  const { data: event, error, isLoading } = useGetBseSupplierViewQuery(eventId ? { eventId } : skipToken);

  const stateToStepMap = useMemo(() => {
    const stateToStepMap: { [state in BseStateNameDto]: string } = {
      Draft: t("Request created"),
      Published: t("Receiving bids"),
      DeadlineHasPassed: t("Evaluating bids"),
      Completed: t("Evaluating bids"),
      Awarded: t("Event completed"),
    };
    return stateToStepMap;
  }, [t]);

  useEffect(() => {
    const run = async () => {
      if (event && event.stateName === "Published" && !event.hasRegisteredViewing) {
        await markAsViewed({
          organizationId: authState.selectedOrg.id,
          eventId: event.id,
        });
      }
    };

    run();
  }, [authState.selectedOrg.id, event, markAsViewed]);

  if (error) {
    if (isFetchBaseQueryError(error) && error.status === 403) return displayer.showNoAccess();
    else return displayer.show(error);
  }

  return (
    <>
      {event && <EventDialog event={event} />}
      <Layout>
        <Flex width="full" justifyContent="center" px="5">
          <Flex flexDirection="column" height="full" width={`${CONTENT_WIDTH}`} maxWidth={`${CONTENT_WIDTH}`}>
            <Flex flexDirection="column" justifyContent="center">
              {isLoading && (
                <Flex flexDirection="column" justifyContent="center">
                  <ProcessBar steps={Array.from(new Set(Object.values(stateToStepMap)))} currentStep="" />
                  <Flex width="full" flexDirection="column" alignItems="center" px="2" py="10">
                    <Flex flexDirection="column" rowGap="2" justifyContent="space-between" alignItems="center">
                      <Skeleton
                        width="60"
                        height="6"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="48"
                        height="6"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" px="2" pt="8">
                    <Flex width="full" columnGap="2" justifyContent="center" pt="2" pb="12">
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                    <Flex flexDirection="column" rowGap="4" pt="2">
                      <Skeleton
                        width="50%"
                        height="40"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="full"
                        height="40"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
              {event && (
                <>
                  <ProcessBar
                    steps={Array.from(new Set(Object.values(stateToStepMap)))}
                    currentStep={getCurrentStep(event, stateToStepMap)}
                    loopTo={
                      requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS")
                        ? [{ step: stateToStepMap.Published, description: t("Negotiation") }]
                        : undefined
                    }
                  />
                  <EventMeta data={event} />
                  <Flex flexDirection="column">
                    <Tabs
                      mt="20px"
                      w="100%"
                      index={tabIndex}
                      onChange={(index) => {
                        setTabIndex(index);
                        params.setSelectedBseTab(indexToQueryValue(index));
                      }}
                      variant="line"
                      colorScheme="teal"
                    >
                      <TabList justifyContent="center">
                        <Tab cursor="default">
                          <Icon as={FaSearch} mr="1" />
                          {t("Request")}
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <BseSupplierViewEventInfo data={event} />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Flex>
                </>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Layout>
    </>
  );
};

const indexToQueryValue = (index: number): BseSupplierTab => {
  if (index === 0) {
    return "request";
  } else if (index === 1) {
    return "qa";
  } else if (index === 2) {
    return "announcements";
  } else {
    throw Error("Invalid tab index");
  }
};
