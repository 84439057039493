import {
  Button,
  Flex,
  Heading,
  Icon,
  IconButton,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Spinner,
  Text,
} from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaChevronLeft, FaChevronRight, FaClock, FaCopy, FaEdit, FaRedoAlt } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  BseDto,
  useCreateNegotiationRoundMutation,
  useCreateSourcingEventCopyMutation,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayDate } from "../../../../common/formatting/displayDate";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { requireBooleanEnvVar } from "../../../../config";
import { urls } from "../../../../urls";
import { isCompletedBse, isPublishedBse } from "../../typeguards";

interface Props {
  event: BseDto;
}

export const EventMeta = ({ event }: Props) => {
  const navigate = useNavigate();
  const { t } = useTranslation();
  const displayer = useApiError();

  const [createEventCopy, { isLoading: isLoadingEventCopy }] = useCreateSourcingEventCopyMutation();
  const [startNegotiationRound, { isLoading: isStartingNegotiationRound }] = useCreateNegotiationRoundMutation();

  const [roundNumber, setRoundNumber] = useState(0);

  const currentRound = useMemo(
    () => (roundNumber ? event.negotiationContext?.rounds[roundNumber - 1] : undefined),
    [event.negotiationContext?.rounds, roundNumber]
  );

  const deadline = currentRound?.deadline ?? event?.awardedFields?.deadline ?? event?.publishedFields?.deadline;

  return (
    <>
      <Flex justifyContent="space-between">
        <Flex alignItems="center">
          {requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS") && (
            <>
              <IconButton
                aria-label="previous round"
                icon={<Icon as={FaChevronLeft} />}
                size="sm"
                variant="ghost"
                cursor="pointer"
                colorScheme="teal"
                isDisabled={roundNumber === 0}
                onClick={() => setRoundNumber(roundNumber - 1)}
              />
              <Text fontWeight="semibold" mx="2">
                {roundNumber === 0 ? t("Original sourcing event") : `${t("Round")} ${roundNumber}`}
              </Text>
              <IconButton
                aria-label="next round"
                icon={<Icon as={FaChevronRight} />}
                size="sm"
                variant="ghost"
                cursor="pointer"
                colorScheme="teal"
                isDisabled={roundNumber >= (event.negotiationContext?.rounds.length ?? 0)}
                onClick={() => setRoundNumber(roundNumber + 1)}
              />
            </>
          )}
        </Flex>
        <Menu>
          <MenuButton as={Button} variant="ghost">
            <Flex columnGap={1} cursor="pointer">
              <Flex width="1.5" height="1.5" backgroundColor="smText" rounded="full"></Flex>
              <Flex width="1.5" height="1.5" backgroundColor="smText" rounded="full"></Flex>
              <Flex width="1.5" height="1.5" backgroundColor="smText" rounded="full"></Flex>
            </Flex>
          </MenuButton>
          <MenuList>
            <MenuItem
              isDisabled={!event.canEdit || !isPublishedBse(event)}
              icon={<Icon as={FaEdit} />}
              onClick={() => navigate(urls.events.edit.go(event.id).intro)}
            >
              <Explanation
                enabled={!event.canEdit}
                text={t("Only admins and creators can edit events") ?? ""}
                shouldWrapChildren
              >
                {t("Edit event")}
              </Explanation>
            </MenuItem>
            <MenuItem
              isDisabled={!event.canEdit}
              icon={<Icon as={FaCopy} />}
              onClick={async () => {
                const res = await createEventCopy({ eventId: event.id });
                if ("data" in res) navigate(urls.events.edit.go(res.data.id).intro);
                else displayer.trigger(res.error);
              }}
            >
              <Explanation enabled={!event.canEdit} text={t("Only admins and creators can copy events") ?? ""}>
                {t("Copy event")}
                {isLoadingEventCopy && <Spinner />}
              </Explanation>
            </MenuItem>
            {requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS") && (
              <>
                <MenuItem
                  icon={<Icon as={FaRedoAlt} />}
                  isDisabled={!event.canEdit || !(isPublishedBse(event) || isCompletedBse(event))}
                  onClick={async () => {
                    const res = await startNegotiationRound({
                      eventId: event.id,
                      createNegotiationRoundRequest: {
                        deadline: new Date().toISOString(),
                      },
                    }).unwrap();
                    navigate(urls.events.editNegotiation.go({ eventId: event.id, negotiationId: res.id }).intro);
                  }}
                >
                  {t("New negotiation")} {isStartingNegotiationRound && <Spinner />}
                </MenuItem>
                {currentRound ? (
                  <MenuItem
                    isDisabled={!event.canEdit}
                    icon={<Icon as={FaEdit} />}
                    onClick={() =>
                      navigate(
                        urls.events.editNegotiation.go({ eventId: event.id, negotiationId: currentRound.id }).intro
                      )
                    }
                  >
                    {isPublishedBse(event) && (
                      <Explanation
                        enabled={!event.canEdit}
                        text={t("Only admins and creators can edit negotiation rounds") ?? ""}
                        shouldWrapChildren
                      >
                        {t("Edit negotiation round")}
                      </Explanation>
                    )}
                  </MenuItem>
                ) : null}
              </>
            )}
          </MenuList>
        </Menu>
      </Flex>
      <Flex width="full" justifyContent="center" pb="10">
        <Flex flexDirection="column" grow="1">
          <Heading as="h2" size="md" width="full" textAlign="center">
            {event.title}
          </Heading>
          <Flex width="full" justifyContent="center" alignItems="center" pt="2">
            <Icon as={FaClock} color="teal" _dark={{ color: "teal.200" }} w="4" h="4" mr="2" />
            <Text fontSize={"md"} color="teal" _dark={{ color: "teal.200" }} whiteSpace="nowrap">
              {t("Bid deadline")}: {deadline ? displayDateWithTime(deadline, t("at")) : null}
            </Text>
          </Flex>
          <Text textAlign="center" fontSize="sm" color="smMuted" pt="1">
            {t("Created by")} <b>{`${event.createdBy.firstName} ${event.createdBy.lastName}`} </b>
            {t("on")} {displayDate(event.createdAt)}.
          </Text>
        </Flex>
      </Flex>
    </>
  );
};
