import { Box, Button, Icon } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import moment, { Moment } from "moment";
import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { FaBan, FaCheckCircle, FaEdit } from "react-icons/fa";
import { BseSupplierViewDto, useDeclineInvitationToBidMutation, useGetBseBidQuery } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { Explanation } from "../../../../common/support/Explanation/Explanation";
import { EditBidDraftButton } from "../../../bids/view-all/EditBidDraftButton";
import { StatusBanner } from "./StatusBanner";
import { CreateDraftButton } from "./buttons/CreateDraftButton";
import { EditDeliveredBidButton } from "./buttons/EditDeliveredBidButton";
import { ViewBidButton } from "./buttons/ViewBidButton";

interface Props {
  event: BseSupplierViewDto;
}

export const BseBidBanner = ({ event }: Props) => {
  const { t } = useTranslation();
  const displayer = useApiError();

  const {
    data: createdBid,
    error,
    isLoading,
  } = useGetBseBidQuery(event.createdBidId ? { bidId: event.createdBidId } : skipToken);

  useEffect(() => {
    if (error) displayer.trigger(error);
  }, [displayer, error]);

  if (isLoading)
    return (
      <StatusBanner text={`${t("Loading bid status")}...`} border="1px solid" borderColor="teal.100" color="smPrimary">
        <Button size="sm" isDisabled leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}>
          {t("Edit")}
        </Button>
        <Button size="sm" isDisabled leftIcon={<Icon as={FaEdit} w="15px" h="15px" />}>
          {t("Decline")}
        </Button>
      </StatusBanner>
    );

  switch (event.status) {
    case "DeadlinePassedAndBidDelivered":
      if (!createdBid) throw Error("In deadline passed and bid delivered state, but no bid found.");
      return (
        <StatusBanner
          text={`${t("Your bid is delivered")}! ${t("The deadline has now passed")}.`}
          border="1px solid"
          borderColor="teal.100"
          color="smPrimary"
        >
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "BidDelivered":
      if (!createdBid) throw Error("In bid delivered state, but no bid found.");
      return (
        <StatusBanner
          text={`${t("Your bid is delivered")}.`}
          border="1px solid"
          borderColor="smBorder"
          color="smPrimary"
        >
          {!deadlineHasPassed(moment(event.deadline)) && <EditDeliveredBidButton bid={createdBid} />}
          <Box width="2"></Box>
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "DraftCreated":
      if (!createdBid) throw Error("In draft created state, but no draft found.");
      return (
        <StatusBanner text={t("A draft has been created")} border="1px solid" borderColor="teal.100" color="smPrimary">
          <EditBidDraftButton bid={createdBid} size="sm" />
          <DeclineButton eventId={event.id} />
        </StatusBanner>
      );
    case "InviteAccepted":
    case "InviteNotAccepted":
      return (
        <StatusBanner
          text={t("Do you intend to deliver a bid?")}
          border="teal 1px solid"
          borderColor="teal.100"
          color="smPrimary"
        >
          <CreateDraftButton event={event} shouldAcceptInvite={false} />
          <DeclineButton eventId={event.id} />
        </StatusBanner>
      );
    case "DeadlinePassedWithoutBidDelivered":
      return <StatusBanner text={t("Deadline has passed, no bid was delivered")} backgroundColor="gray.400" />;
    case "IsLoser":
      if (!createdBid) throw Error("In IsLoser state, but no bid found.");
      return (
        <StatusBanner text={t("You lost this event")} border="teal 1px solid" borderColor="teal.100" color="smPrimary">
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "IsWinner":
      if (!createdBid) throw Error("In IsWinner state, but no bid found.");
      return (
        <StatusBanner
          icon={FaCheckCircle}
          text={t("You won this event!")}
          border="teal 1px solid"
          borderColor="teal.100"
          color="smPrimary"
        >
          <ViewBidButton bid={createdBid} />
        </StatusBanner>
      );
    case "InviteDeclined":
      return (
        <StatusBanner
          icon={FaBan}
          text={t("Invitation declined")}
          border="1px solid"
          borderColor="purple.100"
          color="smTertiary"
        ></StatusBanner>
      );
  }
};

const deadlineHasPassed = (deadline: Moment): boolean => {
  return moment().isAfter(deadline);
};

export const DeclineButton = ({ eventId }: { eventId: string }) => {
  const [declineInvitation, { isLoading: isDeclining }] = useDeclineInvitationToBidMutation();

  return (
    <Explanation text={t("Decline the invitation to indicate that you will not deliver a bid")}>
      <Button
        colorScheme="red"
        variant="outline"
        leftIcon={<Icon as={FaBan} />}
        onClick={() => declineInvitation({ eventId })}
        isLoading={isDeclining}
        size="sm"
        ml="2"
      >
        {t("Decline")}
      </Button>
    </Explanation>
  );
};
