import { TextDocumentDto, ViewDocumentAsOwnerDto, ViewSharedDocumentDto } from "../../autogen/bff-api";

export const isViewDocumentAsOwnerDto = (doc?: Record<string, unknown>): doc is ViewDocumentAsOwnerDto =>
  !!doc &&
  !!doc.id &&
  !!doc.name &&
  !!doc.mimeType &&
  !!doc.fileExtension &&
  !!doc.size &&
  !!doc.uploadedAt &&
  !!doc.uploadedBy &&
  !!doc.s3Key &&
  !!doc.fromUploadId;

export const isViewSharedDocumentDto = (doc?: Record<string, unknown>): doc is ViewSharedDocumentDto =>
  !!doc && !!doc.id && !!doc.name && !!doc.mimeType && !!doc.fileExtension && !!doc.size;

export const isTextDocument = (doc?: Record<string, unknown>): doc is TextDocumentDto =>
  !!doc && !!doc.id && !!doc.createdAt && !!doc.modifiedAt && !!doc.owner && !!doc.name && !!doc.text && !!doc.tags;
