import { Box, Flex, Icon, IconButton, Tag, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useEffect, useState } from "react";
import { FaArrowRight } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import {
  ParentProjectDto,
  PersonDto,
  ProjectDto,
  ProjectTypeDto,
  ProjectTypeName,
  useUpdateProjectMutation,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { SingleSelector } from "../../../common/input/Selector/SingleSelector";
import { TipTap } from "../../../common/input/TipTap/TipTap";
import { PersonSelector } from "../../../common/persons/PersonSelector";
import { urls } from "../../../urls";
import { displayPersonNameWithEmail } from "../../contracts/view-single/sharing/AddExternalParticipantModal";
import {
  organizationEditProjectRoles,
  organizationEditSourcingRoles,
} from "../../organizations/current/manage-users/utils";
import { ProfileImageRounded } from "../../sourcing-events/buyer/view-all/ProfileImageRounded";
import { ProjectSelector } from "../ProjectSelector";
import { useProjectTypes } from "../useProjectTypes";

export const ProjectDetails = ({ project, refetch }: { project: ProjectDto; refetch: () => void }) => {
  const [updateProject, { isLoading }] = useUpdateProjectMutation();
  const projectTypes = useProjectTypes();
  const navigate = useNavigate();
  const authState = useLoggedInWithOrgContextState();

  const [description, setDescription] = useState<string>();

  const [editProjectResponsible, setEditProjectResponsible] = useState(false);
  const [projectResponsible, setProjectResponsible] = useState<PersonDto>();

  const [editSourcingResponsible, setEditSourcingResponsible] = useState(false);
  const [sourcingResponsible, setSourcingResponsible] = useState<PersonDto>();

  const [editProjectType, setEditProjectType] = useState(false);
  const [projectType, setProjectType] = useState<ProjectTypeDto>();

  const [editParentProject, setEditParentProject] = useState(false);
  const [parentProject, setParentProject] = useState<ParentProjectDto | null>();

  useEffect(() => {
    setDescription(undefined);
    setEditProjectResponsible(false);
    setProjectResponsible(undefined);
    setEditSourcingResponsible(false);
    setSourcingResponsible(undefined);
    setEditProjectType(false);
    setProjectType(undefined);
    setEditParentProject(false);
    setParentProject(undefined);
  }, [project.id]);

  useEffect(() => {
    if (description === undefined) setDescription(project.description ?? "");
  }, [description, project.description]);

  useEffect(() => {
    if (projectResponsible === undefined) setProjectResponsible(project.projectResponsible);
  }, [project.projectResponsible, projectResponsible]);

  useEffect(() => {
    if (sourcingResponsible === undefined) setSourcingResponsible(project.sourcingResponsible);
  }, [project.sourcingResponsible, sourcingResponsible]);

  useEffect(() => {
    if (projectType === undefined) setProjectType(project.projectType);
  }, [project.projectType, projectType]);

  useEffect(() => {
    if (parentProject === undefined) setParentProject(project.parent);
  }, [parentProject, project.parent]);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateProject = useCallback(
    debounce(
      async ({
        description,
        projectResponsible,
        sourcingResponsible,
        projectType,
        parentProject,
      }: {
        description?: string;
        projectResponsible?: PersonDto;
        sourcingResponsible?: PersonDto;
        projectType?: ProjectTypeDto;
        parentProject?: ParentProjectDto | null;
      }) => {
        await updateProject({
          updateProjectRequest: {
            id: project.id,
            description: description !== undefined ? { value: description } : undefined,
            projectResponsible: projectResponsible !== undefined ? { value: projectResponsible.id } : undefined,
            sourcingResponsible: sourcingResponsible !== undefined ? { value: sourcingResponsible.id } : undefined,
            projectType: projectType !== undefined ? { value: projectType.id } : undefined,
            parent: parentProject !== undefined ? { value: parentProject?.id } : undefined,
          },
        });
        refetch();
      },
      200
    ),
    [project.id, updateProject]
  );

  const handleUpdateProject = useCallback(
    ({
      description,
      projectResponsible,
      sourcingResponsible,
      projectType,
      parentProject,
    }: {
      description?: string;
      projectResponsible?: PersonDto;
      sourcingResponsible?: PersonDto;
      projectType?: ProjectTypeDto;
      parentProject?: ParentProjectDto | null;
    }) => {
      if (projectResponsible !== undefined) setProjectResponsible(projectResponsible);
      if (sourcingResponsible !== undefined) setSourcingResponsible(sourcingResponsible);
      if (projectType !== undefined) setProjectType(projectType);
      if (parentProject !== undefined) setParentProject(parentProject);
      debouncedUpdateProject({
        projectResponsible,
        sourcingResponsible,
        projectType,
        parentProject,
        description,
      });
    },
    [debouncedUpdateProject]
  );

  return (
    <>
      <Text fontWeight="bold" pb="1">
        {t("Description")}
      </Text>
      {description !== undefined && (
        <TipTap
          content={description}
          editable={project.canEdit}
          onUpdate={(value) => handleUpdateProject({ description: value })}
        />
      )}
      {editProjectResponsible ? (
        <Box maxWidth={{ md: "60%" }} py="3">
          <Text fontWeight="bold" pb="1">
            {t("Project responsible")}
          </Text>
          <PersonSelector
            organizationIds={[authState.selectedOrg.id]}
            selectedPerson={projectResponsible}
            roles={organizationEditProjectRoles}
            onChange={(person) => {
              handleUpdateProject({ projectResponsible: person ?? undefined });
              setEditProjectResponsible(false);
            }}
            onBlur={() => setEditProjectResponsible(false)}
            autoFocus
          />
        </Box>
      ) : (
        <Box py="4">
          <Text fontWeight="bold" pb="1">
            {t("Project responsible")}
          </Text>
          <Flex
            alignItems="center"
            cursor={project.canEdit ? "pointer" : "auto"}
            onClick={() => {
              if (project.canEdit) setEditProjectResponsible(true);
            }}
          >
            <ProfileImageRounded
              name={projectResponsible?.firstName ?? ""}
              width="5"
              backgroundColor="smSecondary"
              fontSize="xx-small"
            />
            {projectResponsible ? (
              <Text fontSize="md" pl="2">
                {displayPersonNameWithEmail(projectResponsible)}
              </Text>
            ) : (
              <Text fontStyle="italic" fontSize="md" color="smMuted" pl="2">
                {t("Project responsible not provided")}
              </Text>
            )}
          </Flex>
        </Box>
      )}
      {editSourcingResponsible ? (
        <Box maxWidth={{ md: "60%" }} pb="3">
          <Text fontWeight="bold" pb="1">
            {t("Sourcing responsible")}
          </Text>
          <PersonSelector
            organizationIds={[authState.selectedOrg.id]}
            selectedPerson={sourcingResponsible}
            roles={organizationEditSourcingRoles}
            onChange={(person) => {
              handleUpdateProject({ sourcingResponsible: person ?? undefined });
              setEditSourcingResponsible(false);
            }}
            onBlur={() => setEditSourcingResponsible(false)}
            autoFocus
          />
        </Box>
      ) : (
        <Box pb="4">
          <Text fontWeight="bold" pb="1">
            {t("Sourcing responsible")}
          </Text>
          <Flex
            alignItems="center"
            cursor={project.canEdit ? "pointer" : "auto"}
            onClick={() => {
              if (project.canEdit) setEditSourcingResponsible(true);
            }}
          >
            <ProfileImageRounded
              name={sourcingResponsible?.firstName ?? ""}
              width="5"
              backgroundColor="smTertiary"
              fontSize="xx-small"
            />
            {sourcingResponsible ? (
              <Text fontSize="md" pl="2">
                {displayPersonNameWithEmail(sourcingResponsible)}
              </Text>
            ) : (
              <Text fontStyle="italic" color="smMuted" pl="2">
                {t("Sourcing responsible not provided")}
              </Text>
            )}
          </Flex>
        </Box>
      )}
      {editProjectType ? (
        <Box maxWidth={{ md: "40%" }} pb="3">
          <Text fontWeight="bold" pb="1">
            {t("Project type")}
          </Text>
          <SingleSelector
            size="sm"
            isLoading={isLoading}
            value={projectType ? { label: t(projectType.name), value: projectType.id } : undefined}
            options={projectTypes}
            onChange={(o) => {
              const projectTypeOption = projectTypes.find((p) => p.value === o?.value);
              if (!projectTypeOption) throw Error("Selected project type not found");
              handleUpdateProject({
                projectType: {
                  id: projectTypeOption.value,
                  name: projectTypeOption.label as ProjectTypeName,
                },
              });
              setEditProjectType(false);
            }}
            onBlur={() => setEditProjectType(false)}
            autoFocus
          />
        </Box>
      ) : (
        <Box pb="4">
          <Text fontWeight="bold" pb="1">
            {t("Project type")}
          </Text>
          <Tag
            cursor={project.canEdit ? "pointer" : "auto"}
            onClick={() => {
              if (project.canEdit) setEditProjectType(true);
            }}
          >
            {projectType?.name ? t(projectType.name) : t("Project type not provided")}
          </Tag>
        </Box>
      )}
      <Text fontWeight="bold" pb="1">
        {t("Parent project")}
      </Text>
      {editParentProject ? (
        <Flex maxWidth="60%" pb="3">
          <ProjectSelector
            selectedProject={parentProject}
            projectsToExclude={[project.id]}
            onChange={(p) => {
              handleUpdateProject({ parentProject: p ?? null });
              setEditParentProject(false);
            }}
            onBlur={() => setEditParentProject(false)}
            autoFocus={true}
          />
        </Flex>
      ) : (
        <Box cursor="pointer" pb="4">
          {parentProject ? (
            <Flex>
              <Tag
                cursor={project.canEdit ? "pointer" : "auto"}
                onClick={() => {
                  if (project.canEdit) setEditParentProject(true);
                }}
              >
                {`${parentProject.externalId ? `${parentProject.externalId} - ` : ""}${parentProject.name}`}
              </Tag>
              <IconButton
                aria-label="go to project"
                icon={<Icon as={FaArrowRight} />}
                variant={"link"}
                colorScheme="cyan"
                onClick={() => navigate(urls.projects.view.go(parentProject?.id ?? ""))}
              />
            </Flex>
          ) : (
            <Text
              fontStyle="italic"
              color="smMuted"
              onClick={() => {
                if (project.canEdit) setEditParentProject(true);
              }}
            >
              {t("Parent project not provided")}
            </Text>
          )}
        </Box>
      )}
      <Text fontWeight="bold" pb="1">
        {t("Child projects")}
      </Text>
      {project.children && (
        <Flex flexDirection="column" rowGap="1" pb="4">
          {project.children.map((c) => (
            <Flex key={c.id}>
              <Tag>{`${c.externalId ? `${c.externalId} - ` : ""}${c.name}`}</Tag>
              <IconButton
                aria-label="go to project"
                icon={<Icon as={FaArrowRight} />}
                variant={"link"}
                colorScheme="cyan"
                onClick={() => navigate(urls.projects.view.go(c?.id ?? ""))}
              />
            </Flex>
          ))}
        </Flex>
      )}
      {!project.children?.length && (
        <Text fontStyle="italic" color="smMuted" pb="4">
          {t("Child projects not provided")}
        </Text>
      )}
    </>
  );
};
