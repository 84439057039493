import {
  Box,
  Button,
  Flex,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalHeader,
  ModalOverlay,
  useToast,
} from "@chakra-ui/react";
import { FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { OrganizationEntryDto, useCreateOrganizationEntryMutation } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { Country } from "./Country";
import { Name } from "./Name";
import { OrganizationNumber } from "./OrganizationNumber";

interface FormValues {
  name: string;
  country: string;
  organizationNumber: string;
}

interface ModalProps {
  onConfirm: (newEntry: OrganizationEntryDto) => Promise<void>;
  onClose: () => void;
}

export const CreateOrganizationEntryModal = ({ onConfirm, onClose }: ModalProps) => {
  const toast = useToast();
  const { t } = useTranslation();
  const [create, { isLoading }] = useCreateOrganizationEntryMutation();
  const apiErrorDisplayer = useApiError();
  const methods = useForm<FormValues>();

  const submit = async (values: FormValues) => {
    create({
      createOrganizationEntryRequest: {
        name: values.name,
        industryIds: [],
        countryId: values.country,
        organizationNumber: values.organizationNumber,
      },
    })
      .unwrap()
      .then((orgEntry) => {
        toast({
          title: t("New organization entry created!"),
          status: "success",
          position: "top-right",
        });
        onConfirm(orgEntry);
        onClose();
      })
      .catch((e) => {
        apiErrorDisplayer.trigger(e);
      });
  };

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Add unregistered company")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <FormProvider {...methods}>
            <form onSubmit={methods.handleSubmit(submit)}>
              <Box mt="15px">
                <Name />
              </Box>
              <Box mt="15px">
                <Country />
              </Box>
              <Box mt="15px">
                <OrganizationNumber />
              </Box>
              <Flex mt="30px" justifyContent={"end"} mb="10px">
                <Button type="submit" colorScheme="teal" isLoading={isLoading}>
                  {t("Add organization")}
                </Button>
              </Flex>
            </form>
          </FormProvider>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
