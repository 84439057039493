import { Box, Icon, IconButton, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useMemo } from "react";
import { useTranslation } from "react-i18next";
import { FaTimes } from "react-icons/fa";
import { EmailInviteDto, OrganizationInviteDto } from "../../../../../autogen/bff-api";
import { OrganizationTypeTag } from "../../../../../common/organization/OrganizationTypeTag";
import { useConfirmationModal } from "../../../../../common/useConfirmationModal";
import { displayPersonNameWithEmail } from "../../../../contracts/view-single/sharing/AddExternalParticipantModal";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";

interface Props {
  orgInvites: OrganizationInviteDto[];
  orgEntryInvites: EmailInviteDto[];
  removeOrgInvite: (inviteId: string) => Promise<void>;
  removeOrgEntryInvite: (inviteId: string) => Promise<void>;
}

export const OrganizationInvitesTable = ({
  orgInvites,
  orgEntryInvites,
  removeOrgInvite,
  removeOrgEntryInvite,
}: Props) => {
  const { t } = useTranslation();
  const eventState = useBasicSourcingEventState();
  const confirmationModal = useConfirmationModal();

  const groupedOrgEntryInvites = useMemo(() => {
    const invites: { [entryId: string]: EmailInviteDto[] } = {};
    eventState.emailInvites.value.forEach((invite) => {
      const invitesForOrgEntry = invites[invite.organizationEntry?.id ?? ""] ?? [];
      invites[invite.organizationEntry?.id ?? ""] = [...invitesForOrgEntry, invite];
    });
    return invites;
  }, [eventState.emailInvites.value]);

  const mangualRemovalConfirmationRequired = (): boolean => {
    switch (eventState.event.status) {
      case "Awarded":
      case "Draft":
        return false;
      case "Completed":
      case "DeadlineHasPassed":
      case "Published":
        return true;
    }
  };

  return (
    <Box height="full" width="full" border="1px solid" borderColor="smBorder" rounded="md" pb="2.5">
      {confirmationModal.modal}
      <Table variant="simple">
        <Thead>
          <Tr>
            <Th>{t("Organization name")}</Th>
            <Th>{t("Type")}</Th>
            <Th>{t("Recipients")}</Th>
            <Th>{t("Remove")}</Th>
          </Tr>
        </Thead>
        <Tbody>
          <>
            {orgInvites.map((invite) => (
              <Tr key={invite.id}>
                <Td verticalAlign="top">
                  <Text fontSize={"sm"}>{invite.organizationName}</Text>
                </Td>
                <Td verticalAlign="top">
                  <OrganizationTypeTag type="Organization" />
                </Td>
                <Td verticalAlign="top">
                  {invite.persons.map((p) => (
                    <Text key={p.id} fontSize={"sm"}>
                      {displayPersonNameWithEmail(p)}
                    </Text>
                  ))}
                </Td>
                <Td verticalAlign="top">
                  <IconButton
                    aria-label="remove organization"
                    icon={<Icon as={FaTimes} />}
                    size="sm"
                    variant="ghost"
                    onClick={() => {
                      if (mangualRemovalConfirmationRequired()) {
                        confirmationModal.show({
                          title: t("Confirm removal"),
                          description: t(
                            "You are live, and the selected organizations wil immediately be removed from the event"
                          ),
                          onConfirmed: () => removeOrgInvite(invite.id),
                          confirmButtonColor: "blue",
                          confirmButtonText: t("Send") ?? "",
                        });
                      } else removeOrgInvite(invite.id);
                    }}
                  />
                </Td>
              </Tr>
            ))}
            {Object.values(groupedOrgEntryInvites).map((invites) => (
              <Tr key={invites[0].organizationEntry?.id ?? "no_org_entry"}>
                <Td verticalAlign="top">
                  <Text fontSize={"sm"}>{invites[0].organizationEntry?.name ?? t("No organization")}</Text>
                </Td>
                <Td verticalAlign="top">
                  <OrganizationTypeTag type="OrganizationEntry" />
                </Td>
                <Td verticalAlign="top">
                  {invites.map((invite) => (
                    <Text key={invite.email} fontSize={"sm"}>
                      {invite.email}
                    </Text>
                  ))}
                </Td>
                <Td verticalAlign="top" textAlign="center">
                  {invites.map((invite) => (
                    <IconButton
                      key={invite.id}
                      aria-label="remove email invite"
                      icon={<Icon as={FaTimes} />}
                      size="xs"
                      variant="ghost"
                      maxHeight="5"
                      display="block"
                      ml="1"
                      onClick={() => {
                        if (mangualRemovalConfirmationRequired()) {
                          confirmationModal.show({
                            title: t("Confirm removal"),
                            description: t(
                              "You are live, and the selected person will immediately be removed from the event"
                            ),
                            onConfirmed: () => removeOrgEntryInvite(invite.id),
                            confirmButtonColor: "blue",
                            confirmButtonText: t("Send") ?? "",
                          });
                        } else removeOrgEntryInvite(invite.id);
                      }}
                    />
                  ))}
                </Td>
              </Tr>
            ))}
          </>
          {[...orgInvites, ...orgEntryInvites].length === 0 && (
            <Tr>
              <Td colSpan={4} width={"100%"} textAlign="center">
                {t("No invites have been added")}
              </Td>
            </Tr>
          )}
        </Tbody>
      </Table>
    </Box>
  );
};
