import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { PersonDto } from "../../autogen/bff-api";
import { PersonSelector } from "./PersonSelector";

interface Props<T extends { person: PersonDto | null } & FieldValues> {
  label: string;
  helperText?: string;
  control: Control<T>;
  name: Path<T>;
  placeholderText: string;
  required?: {
    value: boolean;
    message: string;
  };
  errorMessage: string | undefined;
  size?: "sm" | "md";
  isDisabled?: boolean;
  isLoading?: boolean;
  organizationIds: string[];
  additionalPersons?: PersonDto[];
}

export const PersonSelectorForm = <T extends { person: PersonDto | null }>({
  label,
  helperText,
  control,
  name,
  placeholderText,
  required,
  errorMessage,
  size,
  isDisabled,
  organizationIds,
  additionalPersons,
}: Props<T>) => {
  return (
    <FormControl id={name} rounded="md" isRequired={required?.value === true} isInvalid={errorMessage !== undefined}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          return (
            <PersonSelector
              organizationIds={organizationIds}
              isDisabled={isDisabled}
              isClearable
              size={size}
              onChange={(value) => onChange(value)}
              selectedPerson={value as PersonDto}
              placeholder={placeholderText}
              additionalPersons={additionalPersons}
            />
          );
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
