import { Flex, Icon, IconButton, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep, isEqual } from "lodash";
import { useCallback } from "react";
import { FaTrash } from "react-icons/fa";
import {
  DefaultNotificationSettingsDto,
  SourcingEventDateReminderDto,
  useUpdateOrganizationMutation,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch } from "../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../common/redux/reducers/authStateReducer";
import { initSettings } from "./NotificationSettings";

export const BidDeliveryDeadlineSetting = ({ setting }: { setting: SourcingEventDateReminderDto }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();

  const [updateOrg, { isLoading }] = useUpdateOrganizationMutation();

  const leadTimeUnits = {
    Days: t("days"),
    Months: t("months"),
  };

  const deleteSetting = useCallback(async () => {
    const settings: DefaultNotificationSettingsDto =
      cloneDeep(authState.selectedOrg.defaultNotificationSettings) ?? initSettings();
    settings.sourcingEventNotificationSettings.bidDeliveryDeadlineNotifications =
      settings.sourcingEventNotificationSettings.bidDeliveryDeadlineNotifications.filter((s) => !isEqual(s, setting));
    const response = await updateOrg({
      orgId: authState.selectedOrg.id,
      editOrganizationRequest: {
        defaultNotificationSettings: settings,
      },
    });
    if ("data" in response) {
      dispatch(updateSelectedOrg(response.data as OrgDto));
    }
  }, [authState.selectedOrg.defaultNotificationSettings, authState.selectedOrg.id, dispatch, setting, updateOrg]);

  return (
    <Flex flexDirection={"column"} fontSize={"sm"} backgroundColor={"gray.50"} rounded="md" p="2" my="1">
      <Flex justifyContent={"space-between"} width="full">
        <Text>{t("Time of notification")}</Text>
        <IconButton
          aria-label="delete setting"
          icon={<Icon as={FaTrash} />}
          size={"xs"}
          isLoading={isLoading}
          onClick={() => deleteSetting()}
        />
      </Flex>
      <Text fontWeight={"bold"}>
        {setting.leadTime.amount} {leadTimeUnits[setting.leadTime.unit]} {t("before date")}
      </Text>
      <Text>{t("Individual receivers")}</Text>
      {setting.receivers.persons.map((person) => (
        <Text key={person.id} fontWeight={"bold"}>
          {person.firstName} {person.lastName}
        </Text>
      ))}
      <Text>{t("Organization role receivers")}</Text>
      {setting.receivers.organizationRoles.map((role) => (
        <Text key={role} fontWeight={"bold"}>
          {role}
        </Text>
      ))}
      <Text>{t("Contract role receivers")}</Text>
      {setting.receivers.sourcingEventRoles.map((role) => (
        <Text key={role} fontWeight={"bold"}>
          {role}
        </Text>
      ))}
    </Flex>
  );
};
