import {
  Box,
  Button,
  Flex,
  Input,
  Modal,
  ModalBody,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Spinner,
  Table,
  Tbody,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { debounce } from "lodash";
import { useCallback } from "react";
import { useTranslation } from "react-i18next";
import {
  OrganizationEntryDto,
  useGetOrganizationEntryQuery,
  useUpdateOrganizationEntryMutation,
} from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { isCreator } from "../../../../common/auth/utils";
import { useApiError } from "../../../../common/errors/useApiError";
import { displayPhoneNumber } from "../../../../common/formatting/displayPhoneNumber";
import { ModalSkeleton } from "../../../../common/ModalSkeleton";
import { OrganizationTypeTag } from "../../../../common/organization/OrganizationTypeTag";

interface Props {
  entryId: string;
  onClose: () => void;
  linkWithExistingOrg?: () => void;
}

export const OrganizationEntryModal = ({ entryId, onClose, linkWithExistingOrg }: Props) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const errorDisplayer = useApiError();

  const canUpdateOrgEntries = isCreator(authState.selectedOrg.roles);

  const { data: entry, isLoading } = useGetOrganizationEntryQuery({ entryId });

  const [updateOrgEntry, { isLoading: isUpdating }] = useUpdateOrganizationEntryMutation();

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const debouncedUpdateOrgEntry = useCallback(
    debounce(async (entry: OrganizationEntryDto) => {
      await updateOrgEntry({
        entryId: entry.id,
        updateOrganizationEntryRequest: {
          ...entry,
          countryId: entry.country.id,
        },
      })
        .unwrap()
        .catch((e) => {
          console.log(e);
          errorDisplayer.trigger(e);
        });
    }, 300),
    []
  );

  return (
    <Modal isOpen={true} onClose={onClose} size="3xl">
      <ModalOverlay />
      <ModalContent maxHeight="80%" overflowY="auto" pb="2.5">
        <ModalHeader>
          {entry && (
            <>
              {canUpdateOrgEntries ? (
                <Flex align="center">
                  <Input
                    defaultValue={entry.name}
                    onChange={(e) => {
                      const name = e.target.value;
                      if (name) debouncedUpdateOrgEntry({ ...entry, name });
                    }}
                  />
                  {isUpdating && <Spinner color="smMuted" ml="-8" />}
                </Flex>
              ) : (
                entry.name
              )}
            </>
          )}
          {!entry && isLoading && `${t("Loading")}...`}
          <Box mt="5px">
            <OrganizationTypeTag type="OrganizationEntry" />
          </Box>
        </ModalHeader>
        <ModalBody>
          {entry && (
            <Flex flexDirection={"column"} rounded="lg">
              <Text fontWeight="bold">{t("Country")}</Text>
              <Text>{entry.country.name}</Text>
              <Text fontWeight="bold" pt="5">
                {t("Organization number")}
              </Text>
              {canUpdateOrgEntries ? (
                <Flex align="center" pt="1">
                  <Input
                    backgroundColor="smBackground"
                    defaultValue={entry.organizationNumber}
                    onChange={(e) => {
                      const organizationNumber = e.target.value;
                      if (organizationNumber) debouncedUpdateOrgEntry({ ...entry, organizationNumber });
                    }}
                  />
                  {isUpdating && <Spinner color="smMuted" ml="-8" />}
                </Flex>
              ) : (
                <Text>{entry.name}</Text>
              )}
              <Text fontSize="md" fontWeight="bold" pt="5" pb="2">
                {t("Contact persons")}
              </Text>
              {entry.contactPersons?.length ? (
                <Flex
                  width="full"
                  maxWidth="full"
                  overflowX="auto"
                  border="1px solid"
                  borderColor="smBorder"
                  rounded="lg"
                  pt="5"
                  pb="2.5"
                >
                  <Table>
                    <Thead>
                      <Tr>
                        <Th>{t("Name")}</Th>
                        <Th>{t("Title")}</Th>
                        <Th>{t("Email")}</Th>
                        <Th>{t("Phone number")}</Th>
                      </Tr>
                    </Thead>
                    <Tbody>
                      {entry.contactPersons.map((p) => (
                        <Tr key={p.email}>
                          <Td>{p.name}</Td>
                          <Td>{p.title}</Td>
                          <Td>{p.email}</Td>
                          <Td>{p.phoneNumber ? displayPhoneNumber(p.phoneNumber) : ""}</Td>
                        </Tr>
                      ))}
                    </Tbody>
                  </Table>
                </Flex>
              ) : (
                <Text as="span" fontSize={"sm"} color="smMuted">
                  <i>{t("Not provided")}.</i>
                </Text>
              )}
            </Flex>
          )}
          {!entry && isLoading && <ModalSkeleton />}
        </ModalBody>
        {!entry?.link && linkWithExistingOrg && canUpdateOrgEntries && (
          <ModalFooter mt="-10px">
            <Button variant="solid" colorScheme={"purple"} onClick={linkWithExistingOrg}>
              {t("Link with existing org")}
            </Button>
          </ModalFooter>
        )}
      </ModalContent>
    </Modal>
  );
};
