import { Box, Flex, Text } from "@chakra-ui/react";
import { PhoneNumberDto } from "../../../../autogen/bff-api";
import { displayPhoneNumber } from "../../../../common/formatting/displayPhoneNumber";

export enum BidStatus {
  INVITATION_SENT = "INVITATION_SENT",
  EVENT_SEEN = "EVENT_SEEN",
  INTEND_TO_BID = "INTEND_TO_BID",
  SUBMITTED = "SUBMITTED",
}

interface Props {
  fullName?: string;
  email: string;
  phoneNumber: PhoneNumberDto | null;
  corporateTitle: string | null;
}

export const EmployeeInfo = ({ fullName, email, phoneNumber, corporateTitle }: Props) => {
  return (
    <Box pb="2">
      <Flex flexDirection={"column"} bg="smBackgroundSecondary" rounded={"md"} p="2">
        {fullName?.trim() && (
          <Text fontSize="sm">
            {fullName}&nbsp;
            {corporateTitle && `(${corporateTitle})`}
          </Text>
        )}
        <Text fontSize="sm" color="smMuted">
          {email}
        </Text>
        {phoneNumber && (
          <Text fontSize="sm" color="smMuted">
            {displayPhoneNumber(phoneNumber)}
          </Text>
        )}
      </Flex>
    </Box>
  );
};
