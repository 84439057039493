import { createAsyncThunk } from "@reduxjs/toolkit";
import { bffApi, EditBidRequest, NumberFieldDto } from "../../../../autogen/bff-api";
import { isBseBidDraft } from "../../../../pages/bids/typeguards";
import { ProductField } from "../../../types";
import { BasicBidState } from "../../reducers/basicBidReducer";
import { RootState } from "../../store";

export const editBasicBidThunk = createAsyncThunk(
  "basicBid/edit",
  async (
    props: {
      command: EditBasicBidCommand;
    },
    { dispatch, getState }
  ): Promise<BasicBidState | null> => {
    const currentState = getState() as RootState;

    const currentBidState = currentState.basicBid.bid;
    if (!currentBidState) throw Error("No basic bid state.");

    const response = dispatch(
      bffApi.endpoints.updateBseBid.initiate({
        bidId: currentBidState.id,
        editBidRequest: getEditRequest(props.command),
      })
    );
    response.reset();
    const result = await response;

    if ("data" in result && result.data) {
      const body = result.data;
      if (!isBseBidDraft(body)) throw Error("Invalid state: Bid is not draft.");

      return {
        id: currentBidState.id,
        lastChanged: new Date().toISOString(),
        forEvent: currentBidState.forEvent,
        comment: {
          value: body.draftFields.comment ?? null,
          errorMessage: currentBidState.comment.errorMessage,
        },
        productGroupBids: {
          value: body.draftFields.products ?? null,
          errorMessage: currentBidState.productGroupBids.errorMessage,
        },
        documents: {
          value: body.documents,
          errorMessage: currentBidState.documents.errorMessage,
        },
        uploads: {
          value: body.uploads,
          errorMessage: currentBidState.uploads.errorMessage,
        },
      };
    } else {
      switch (props.command.type) {
        case "EditComment":
          return {
            ...currentBidState,
            comment: {
              value: currentBidState.comment.value,
              errorMessage: "Invalid comment update.",
            },
          };
        case "EditProducts":
          return currentBidState;
      }
    }
  }
);

const getEditRequest = (command: EditBasicBidCommand): EditBidRequest => {
  switch (command.type) {
    case "EditComment":
      return {
        comment: {
          value: command.value ?? undefined,
        },
      };
    case "EditProducts":
      return {
        editProducts: {
          products: command.value?.products ?? [],
        },
      };
  }
};

type EditComment = {
  type: "EditComment";
  value: string | null;
};

type EditProducts = {
  type: "EditProducts";
  value: {
    products: {
      productGroupId: string;
      productBids: { productId: string; unitPrice: NumberFieldDto; productFields: ProductField[] }[];
    }[];
  };
};

export type EditBasicBidCommand = EditComment | EditProducts;
