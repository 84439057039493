import { Grid, GridItem, Heading, Input, InputGroup, InputLeftElement } from "@chakra-ui/react";
import { ListSourcingEventsFilterStatus, useListEventsForBuyerQuery } from "../../../../autogen/bff-api";
import { CONTENT_WIDTH } from "../../../../config";

import { Search2Icon } from "@chakra-ui/icons";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useEffect, useRef, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { Layout } from "../../../../common/layout/Layout";
import { Paging } from "../../../../common/Paging";
import { SourcingEventsTableForBuyer } from "./SourcingEventsTableForBuyer";

interface Props {
  title: string;
  status: ListSourcingEventsFilterStatus;
}

export const EventsForBuyer = ({ title, status }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const navigate = useNavigate();
  const location = useLocation();

  const prevPathnameRef = useRef(location.pathname);

  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(location.search);
    return {
      name: params.get("name") || "",
      skip: +(params.get("skip") || 0),
      limit: +(params.get("limit") || 10),
    };
  });

  useEffect(() => {
    if (prevPathnameRef.current !== location.pathname) {
      setFilters({
        name: "",
        skip: 0,
        limit: 10,
      });
      prevPathnameRef.current = location.pathname;
    }
  }, [location.pathname]);

  useEffect(() => {
    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value || value === 0) params.set(key, `${value}`);
    });

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [filters, location.pathname, navigate]);

  const [searchString, setSearchString] = useState(filters.name);

  const { data, error, isLoading, isFetching } = useListEventsForBuyerQuery({
    orgId: authState.selectedOrg.id,
    name: filters.name,
    status,
    limit: filters.limit,
    skip: filters.skip,
  });

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchString = useCallback(
    debounce(
      (searchString) =>
        setFilters((prev) => ({
          ...prev,
          name: searchString,
          skip: 0,
        })),
      300
    ),
    [setFilters]
  );

  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`"leftContent content rightContent"`}
      >
        <GridItem area={"content"}>
          <Grid height="full" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"} pb="5">
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid"} borderColor="smBorder" px="2">
              <Heading as="h2" size="lg">
                {title}
              </Heading>
            </GridItem>
            <GridItem pt="5" px="2">
              <InputGroup size="sm">
                <InputLeftElement pointerEvents="none">
                  <Search2Icon />
                </InputLeftElement>
                <Input
                  type="text"
                  size="sm"
                  placeholder={t("Sourcing event title") ?? ""}
                  rounded="md"
                  value={searchString}
                  onChange={(e) => {
                    setSearchString(e.target.value);
                    handleDebouncedSearchString(e.target.value);
                  }}
                />
              </InputGroup>
              <Paging
                isLoading={isLoading || isFetching}
                count={data?.count}
                limit={filters.limit}
                skip={filters.skip}
                setSkip={(skip) => setFilters((prev) => ({ ...prev, skip }))}
              />
              <SourcingEventsTableForBuyer status={status} events={data?.events} error={error} isLoading={isLoading} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
