import { Button, Grid, GridItem, Icon } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaArrowLeft, FaBuilding, FaCubes, FaFolderOpen, FaInfo, FaPaperPlane } from "react-icons/fa";
import { useQueryParams } from "../../../../common/useQueryParams";

interface Props {
  navigateToIntro: () => void;
  navigateToProducts?: () => void;
  navigateToDocs: () => void;
  navigateToParties: () => void;
  navigateToReview: () => void;
  navigateToEventView: () => void;
}

export const Navigation = ({
  navigateToIntro,
  navigateToProducts,
  navigateToDocs,
  navigateToParties,
  navigateToReview,
  navigateToEventView,
}: Props) => {
  const params = useQueryParams();
  const currentStep = params.get("step");
  const { t } = useTranslation();
  return (
    <Grid height="full" gridTemplateColumns={"1fr"} gridTemplateRows={"1fr"}>
      <GridItem>
        <Button
          leftIcon={<Icon as={FaInfo} w="15px" h="15px" />}
          colorScheme="teal"
          variant={currentStep === "intro" ? "solid" : "outline"}
          size="xs"
          w="100%"
          display={"flex"}
          justifyContent="start"
          onClick={navigateToIntro}
        >
          {t("Introduction")}
        </Button>
        {navigateToProducts && (
          <Button
            leftIcon={<Icon as={FaCubes} w="15px" h="15px" />}
            colorScheme="teal"
            variant={currentStep === "products" ? "solid" : "outline"}
            size={"xs"}
            w="100%"
            display={"flex"}
            justifyContent="start"
            mt="1.5"
            onClick={navigateToProducts}
          >
            {t("Products")}
          </Button>
        )}
        <Button
          leftIcon={<Icon as={FaFolderOpen} w="15px" h="15px" />}
          colorScheme="teal"
          variant={currentStep === "docs" ? "solid" : "outline"}
          size={"xs"}
          w="100%"
          display={"flex"}
          justifyContent="start"
          mt="1.5"
          onClick={navigateToDocs}
        >
          {t("Documents")}
        </Button>
        <Button
          leftIcon={<Icon as={FaBuilding} w="15px" h="15px" />}
          colorScheme="teal"
          variant={currentStep === "parties" ? "solid" : "outline"}
          size={"xs"}
          w="100%"
          display={"flex"}
          justifyContent="start"
          mt="1.5"
          onClick={navigateToParties}
        >
          {t("Invited parties")}
        </Button>
        <Button
          leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" />}
          colorScheme="teal"
          variant={currentStep === "review" ? "solid" : "outline"}
          size={"xs"}
          w="100%"
          display={"flex"}
          justifyContent="start"
          mt="1.5"
          onClick={navigateToReview}
        >
          {t("Review & Go Live")}
        </Button>
        <Button
          leftIcon={<Icon as={FaArrowLeft} w="15px" h="15px" color="smMuted" />}
          colorScheme="gray"
          variant="solid"
          size="xs"
          marginRight={1}
          width="full"
          display="flex"
          justifyContent="start"
          mt="1.5"
          onClick={navigateToEventView}
        >
          {t("Back to event")}
        </Button>
      </GridItem>
    </Grid>
  );
};
