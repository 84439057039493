import {
  Button,
  Flex,
  FormControl,
  FormErrorMessage,
  FormHelperText,
  FormLabel,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalFooter,
  ModalHeader,
  ModalOverlay,
  Select,
  useToast,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FormProvider, useForm } from "react-hook-form";
import {
  ContractDto,
  ContractNotificationDurationType,
  ContractNotificationTypeDto,
  PersonDto,
  useListParticipantsForContractsQuery,
  useUpdateContractMutation,
} from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { PersonsSelectorForm } from "../../../../common/persons/PersonsSelectorForm";
import { NotificationTimeSelector } from "./NotificationTimeSelector";

interface Props {
  data: ContractDto;
  onClose: () => void;
}

interface FormValues {
  event: ContractNotificationTypeDto;
  notificationTimeAmount: number;
  notificationTimeUnit: ContractNotificationDurationType;
  persons: PersonDto[];
}

export const ContractNotificationModal = ({ data, onClose }: Props) => {
  const methods = useForm<FormValues>({
    defaultValues: {
      event: "EndDateReminder",
      notificationTimeUnit: "Days",
    },
  });
  const [isLoading, setIsLoading] = useState(false);
  const [addNotification] = useUpdateContractMutation();
  const toast = useToast();
  const displayer = useApiError();
  const { data: participants } = useListParticipantsForContractsQuery({ contractId: data.id });

  const create = async (values: FormValues) => {
    setIsLoading(true);
    const result = await addNotification({
      contractId: data.id,
      updateContractRequest: {
        addDateReminderNotification: {
          inAdvanceUnit: values.notificationTimeUnit,
          inAdvanceAmount: values.notificationTimeAmount,
          receiverIds: values.persons.map((p) => p.id),
          dateReminderType: values.event,
        },
      },
    });
    setIsLoading(false);
    if ("data" in result) {
      toast({
        title: t("Notification added!"),
        status: "success",
      });
      onClose();
    } else {
      displayer.trigger(result.error);
    }
  };

  const eventType = methods.watch("event");

  const notificationNames = {
    EndDateReminder: t("End date reminder"),
    RenewalDateReminder: t("Renewal date reminder"),
    WarrantyExpirationDateReminder: t("Warranty expiration date reminder"),
    StartDateReminder: t("Start date reminder"),
    TerminationDateReminder: t("Termination date reminder"),
    NoticePeriodReminder: t("Notice period reminder"),
    OptionExecutionDeadlineReminder: t("Option execution deadline reminder"),
    OptionStartDateReminder: t("Option start date reminder"),
    NoEndDateReminder: t("Contract without end date reminder"),
  };

  return (
    <Modal isOpen={true} onClose={onClose} size="xl">
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Add new reminder")}</ModalHeader>
        <ModalCloseButton />
        <FormProvider {...methods}>
          <form onSubmit={methods.handleSubmit(create)}>
            <ModalBody>
              <FormControl id="event" mt="5px" isInvalid={methods.formState.errors.event != null}>
                <FormLabel>{t("Reminder type")}</FormLabel>
                <Select
                  {...methods.register("event", {
                    required: {
                      value: true,
                      message: t("Please select a reminder type"),
                    },
                  })}
                >
                  {notificationOptions.map((o) => (
                    <option key={o} value={o}>
                      {notificationNames[o]}
                    </option>
                  ))}
                </Select>
                <FormHelperText>{t("The type of reminder that will be sent")}</FormHelperText>
                <FormErrorMessage>{methods.formState.errors.event?.message}</FormErrorMessage>
              </FormControl>
              <Flex pt="6">
                {participants && (
                  <PersonsSelectorForm<"persons", FormValues>
                    organizationIds={[
                      ...[participants.owningOrganization.id],
                      ...(participants?.organizationParticipants.map((p) => p.id) ?? []),
                    ]}
                    label={t("Receivers")}
                    control={methods.control}
                    name="persons"
                    placeholderText={`${t("Select receivers")}...`}
                    errorMessage={methods.formState.errors.persons?.message}
                    additionalPersons={participants.personParticipants}
                  />
                )}
              </Flex>
              {isDateReminder(eventType) && (
                <Flex pt="6">
                  <NotificationTimeSelector />
                </Flex>
              )}
            </ModalBody>
            <ModalFooter>
              <Button variant="ghost" mr={3} onClick={onClose} isDisabled={isLoading}>
                {t("Cancel")}
              </Button>
              <Button
                type="submit"
                variant="solid"
                colorScheme={"blue"}
                isLoading={isLoading}
                isDisabled={!methods.formState.isValid}
              >
                {t("Create")}
              </Button>
            </ModalFooter>
          </form>
        </FormProvider>
      </ModalContent>
    </Modal>
  );
};

const isDateReminder = (eventType: ContractNotificationTypeDto) => {
  return (
    eventType === "EndDateReminder" ||
    eventType === "RenewalDateReminder" ||
    eventType === "WarrantyExpirationDateReminder" ||
    eventType === "StartDateReminder" ||
    eventType === "TerminationDateReminder" ||
    eventType === "OptionStartDateReminder" ||
    eventType === "OptionExecutionDeadlineReminder"
  );
};

const notificationOptions: ContractNotificationTypeDto[] = [
  "EndDateReminder",
  "RenewalDateReminder",
  "WarrantyExpirationDateReminder",
  "StartDateReminder",
  "TerminationDateReminder",
  "OptionExecutionDeadlineReminder",
  "OptionStartDateReminder",
];
