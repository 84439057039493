import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  Link,
  Spinner,
  Tag,
  Text,
  useMediaQuery,
} from "@chakra-ui/react";
import { useCallback, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useNavigate } from "react-router-dom";
import { useGetProductGroupsAsSupplierQuery } from "../../../autogen/bff-api";
import { LastChangedTag } from "../../../common/editing/LastChangedTag/LastChangedTag";
import { useQueryParams } from "../../../common/useQueryParams";
import { urls } from "../../../urls";
import { useBasicBidState } from "../../sourcing-events/useBasicBidEventState";
import { Details } from "./Details";
import styles from "./EditBasicBidForm.module.css";
import { Navigation } from "./Navigation";
import { Review } from "./Review";
import { Products } from "./products/Products";

export const EditBasicBidForm = () => {
  const params = useQueryParams();
  const currentStep = params.get("step");
  const navigate = useNavigate();
  const bidState = useBasicBidState();
  const { t } = useTranslation();
  const { data, isLoading } = useGetProductGroupsAsSupplierQuery({ sourcingEventId: bidState.forEvent.id });

  const [showDrawer, setShowDrawer] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const [isProductsVisible, setIsProductsVisible] = useState<boolean>(false);

  useEffect(() => {
    setIsProductsVisible(!!data?.productGroups?.length);
  }, [data?.productGroups?.length]);

  const navigateToDetails = useCallback(
    () => navigate(urls.bids.edit.go(bidState.id).details),
    [bidState.id, navigate]
  );
  const navigateToProducts = isProductsVisible ? () => navigate(urls.bids.edit.go(bidState.id).products) : undefined;
  const navigateToReview = () => navigate(urls.bids.edit.go(bidState.id).review);
  const navigateToEventView = () => navigate(urls.events.supplierView.go(bidState.forEvent.id));

  useEffect(() => {
    if (!currentStep) navigateToDetails();
  }, [currentStep, navigateToDetails]);

  const getTitle = (step: string): string => {
    if (step === "details") return t("Bid details");
    if (step === "review") return t("Review & Send");
    if (step === "products") return t("Products");
    throw Error("Unknown step");
  };

  if (isLoading)
    return (
      <Flex width="full" justifyContent={"center"} alignItems={"center"} pt="10">
        <Spinner color="teal" />
      </Flex>
    );

  return (
    <>
      <Drawer placement={"bottom"} onClose={() => setShowDrawer(false)} isOpen={showDrawer}>
        <DrawerOverlay />
        <DrawerContent borderTop="1px solid" borderColor="smBorder">
          <DrawerBody>
            <Navigation
              navigateToDetails={navigateToDetails}
              navigateToProducts={navigateToProducts}
              navigateToReview={navigateToReview}
              navigateToEventView={navigateToEventView}
            />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        display={isLargerThan768 ? "none" : "flex"}
        position={"fixed"}
        zIndex={1}
        bottom="0px"
        width="full"
        height="60px"
        borderTop={"1px solid"}
        borderColor="smBorder"
        alignItems="center"
        bg="smBackground"
      >
        <Button variant="solid" colorScheme="blue" width="full" mx="2.5" onClick={() => setShowDrawer(true)}>
          {t("Change step")}
        </Button>
      </Flex>
      <Flex justifyContent={"center"} px="2">
        <Flex width="full" maxWidth="container.xl" justifyContent={"center"}>
          <Grid className={styles.grid} height="full" marginTop={"9px"} width={"full"} maxWidth="full">
            <GridItem area="menuNav" alignItems="end" borderBottom={"1px solid"} borderColor="smBorder">
              <Box display="flex" width="full"></Box>
            </GridItem>
            <GridItem area="contentNav" alignItems="end" borderBottom={"1px solid"} borderColor="smBorder">
              <Box display="flex" width="full" flexDirection={"column"}>
                <Box display={"flex"} pb="2">
                  {currentStep && (
                    <Heading as="h2" size="md" mr="10px">
                      {getTitle(currentStep)}
                    </Heading>
                  )}
                </Box>
                <Box>
                  <Tag colorScheme={"orange"} mr="10px" size={"md"} mb="5px">
                    {t("Draft")}
                  </Tag>
                  <LastChangedTag timestamp={bidState.lastChanged} />
                </Box>
                <Flex flexDirection={"row"}>
                  <Text fontSize={"md"} color="smMuted" mt="3px">
                    {t("Bid for")}{" "}
                    <Link
                      variant={"ghost"}
                      fontWeight={"bold"}
                      color="teal"
                      href={urls.events.supplierView.go(bidState.forEvent.id)}
                    >
                      {bidState.forEvent.title}
                    </Link>{" "}
                    {t("created by")} <b>{bidState.forEvent.owningOrganization.name}</b>
                  </Text>
                </Flex>
              </Box>
            </GridItem>
            <GridItem height="full" area="menu" display={isLargerThan768 ? "block" : "none"}>
              <Navigation
                navigateToDetails={navigateToDetails}
                navigateToProducts={navigateToProducts}
                navigateToReview={navigateToReview}
                navigateToEventView={navigateToEventView}
              />
            </GridItem>
            <GridItem height="full" maxWidth="full" area="content" overflow={"hidden"}>
              <Box height="full" width={"full"}>
                <Box display={currentStep === "details" ? undefined : "none"} width="full">
                  <Details nextStep={navigateToProducts ?? navigateToReview} />
                </Box>
                <Box display={currentStep === "products" ? undefined : "none"} maxWidth="full" overflow={"hidden"}>
                  <Products nextStep={navigateToReview} previousStep={navigateToDetails} />
                </Box>
                <Box display={currentStep === "review" ? undefined : "none"}>
                  <Review previousStep={navigateToProducts ?? navigateToDetails} />
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </Flex>
      </Flex>
    </>
  );
};
