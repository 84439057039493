import { Box, Flex, Heading, Icon, Text, useColorMode } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { FaClock } from "react-icons/fa";
import { BseSupplierViewDto } from "../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../common/formatting/displayDateWithTime";
import { BidInvitationStatus } from "../../../bids/view-all/BidInvitationStatus";
import { BseBidBanner } from "./BseBidBanner";

interface Props {
  data: BseSupplierViewDto;
}

export const EventMeta = ({ data }: Props) => {
  const { t } = useTranslation();

  const theme = useColorMode();

  return (
    <Flex flexDirection="column" width="full" alignItems="center">
      <Flex alignItems="center">
        <Heading as="h2" size="md">
          {data.title}
        </Heading>
        <Box pl="2.5">
          <BidInvitationStatus status={data.status} />
        </Box>
      </Flex>
      <Flex alignItems="center" pt="2">
        <Icon as={FaClock} color={theme.colorMode === "dark" ? "teal.200" : "teal"} w="14px" h="14px" />
        <Text fontSize={"md"} color={theme.colorMode === "dark" ? "teal.200" : "teal"} pl="1">
          {t("Bid deadline")}: {displayDateWithTime(data.deadline, t("at"))}
        </Text>
      </Flex>
      <Box pt="8">
        <BseBidBanner event={data} />
      </Box>
    </Flex>
  );
};
