import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Heading,
  Tag,
  Text,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate, useParams, useSearchParams } from "react-router-dom";
import { useGetBasicSourcingEventQuery, useGetNegotiationRoundQuery } from "../../../../autogen/bff-api";
import { EntityViewSkeleton } from "../../../../common/EntityViewSkeleton";
import { Layout } from "../../../../common/layout/Layout";
import { useSub } from "../../../../common/subscription/useSub";
import { urls } from "../../../../urls";
import { Navigation } from "./Navigation";

export const EditNegotiationPage = () => {
  const navigate = useNavigate();
  const params = useParams();
  const eventId = params.eventId;
  const negotiationId = params.negotiationId;
  const [searchParams] = useSearchParams();
  const currentStep = searchParams.get("step");

  const [showDrawer, setShowDrawer] = useState(false);

  const { data: event, isLoading: isLoadingEvent } = useGetBasicSourcingEventQuery(eventId ? { eventId } : skipToken);
  const { data: negotiationRound, isLoading: isLoadingNegotiationRound } = useGetNegotiationRoundQuery(
    negotiationId ? { negotiationId } : skipToken
  );

  useEffect(() => {
    if (!currentStep && eventId && negotiationId) {
      navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).intro);
    }
  }, [currentStep, eventId, navigate, negotiationId]);

  if (!eventId || !negotiationId) return <Text>{t("Invalid URL")}</Text>;

  if (isLoadingEvent || isLoadingNegotiationRound) return <EntityViewSkeleton />;

  if (!event) return <Text>{t("Someting went wrong when loading sourcing event")}</Text>;

  return (
    <Layout>
      <Drawer placement={"bottom"} onClose={() => setShowDrawer(false)} isOpen={showDrawer}>
        <DrawerOverlay />
        <DrawerContent borderTop="1px solid" borderColor="smBorder">
          <DrawerBody>
            <NegotiationNavigation eventId={eventId} negotiationId={negotiationId} />
          </DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        display={{ base: "flex", md: "none" }}
        position="fixed"
        zIndex={1}
        bottom="0px"
        width="full"
        height="60px"
        borderTop={"1px solid"}
        borderColor="smBorder"
        alignItems="center"
        bg="smBackground"
      >
        <Button variant={"solid"} colorScheme="blue" width="full" mx="2.5" onClick={() => setShowDrawer(true)}>
          {t("Change step")}
        </Button>
      </Flex>
      <Flex width="full" justifyContent="center">
        <Flex
          flexDirection="column"
          alignItems="center"
          width="container.xl"
          maxWidth="container.xl"
          overflow="hidden"
          pt="2.5"
          px="2"
        >
          <Flex columnGap="5" width="full">
            <Box width="48" borderBottom="1px solid" borderColor="smBorder" />
            <Flex grow="1" borderBottom="1px solid" borderColor="smBorder" pb="2">
              <Box>
                <Heading width="full" as="h2" size="md" mb="2">
                  {event.title}
                </Heading>
                <Tag>
                  {t("Negotiation round")} {negotiationRound?.roundNumber}
                </Tag>
              </Box>
            </Flex>
          </Flex>
          <Flex columnGap="5" width="full" pt="10">
            <Box width="48">
              <NegotiationNavigation eventId={eventId} negotiationId={negotiationId} />
            </Box>
            <Flex grow="1" maxWidth="full">
              <Box display={currentStep === "intro" ? undefined : "none"}>
                Intro placeholder. TBI:
                - Edit negotiation description
                - Edit negotiation deadline
                </Box>
              <Box display={currentStep === "products" ? undefined : "none"} maxWidth="full">
                Products placeholder. TBI:
                - Edit products
              </Box>
              <Box display={currentStep === "docs" ? undefined : "none"}>Docs placeholder. TBI:
              - Edit docs</Box>
              <Box display={currentStep === "parties" ? undefined : "none"}>
                Invited parties placeholder. TBI:
                - Select invited parties
                - Add message for each participant
                </Box>
              <Box display={currentStep === "review" ? undefined : "none"}>Review. TBI:
                - Description
                - Deadline
                - Products
                - Docs
                - Invited parties
              </Box>
            </Flex>
          </Flex>
        </Flex>
      </Flex>
    </Layout>
  );
};

export const NegotiationNavigation = ({ eventId, negotiationId }: { eventId: string; negotiationId: string }) => {
  const navigate = useNavigate();
  const subscriptions = useSub();
  return (
    <Navigation
      navigateToIntro={() => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).intro)}
      navigateToProducts={() =>
        subscriptions.hasProductAccess
          ? navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).products)
          : undefined
      }
      navigateToDocs={() => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).docs)}
      navigateToParties={() => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).parties)}
      navigateToReview={() => navigate(urls.events.editNegotiation.go({ eventId, negotiationId }).review)}
      navigateToEventView={() => navigate(urls.events.view.go(eventId))}
    />
  );
};
