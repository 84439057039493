import { Button, Flex, useToast } from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import {
  ContractTaskDto,
  PersonDto,
  useListParticipantsForContractsQuery,
  useUpdateContractTaskMutation,
} from "../../../../../../autogen/bff-api";
import { PersonSelector } from "../../../../../../common/persons/PersonSelector";

interface Props {
  task: ContractTaskDto;
  onEditComleted: (task: ContractTaskDto) => void;
  onCancel: () => void;
}

export const EditResponsiblePerson = ({ task, onEditComleted, onCancel }: Props) => {
  const [edit] = useUpdateContractTaskMutation();
  const [isLoadingResponsiblePerson, setIsLoadingResponsiblePerson] = useState(false);
  const toast = useToast();
  const [person, setPerson] = useState<PersonDto>(task.responsiblePerson);

  const { data: participants, isLoading: isLoadingParticipants } = useListParticipantsForContractsQuery({
    contractId: task.contractId,
  });

  if (isLoadingParticipants) return <div>{t("Loading...")}</div>;

  if (!participants) return <div>{t("Something is wrong")}...</div>;

  return (
    <Flex flexDirection={"column"} width="full" mt="1">
      <PersonSelector
        organizationIds={[
          ...[participants.owningOrganization.id],
          ...(participants?.organizationParticipants.map((p) => p.id) ?? []),
        ]}
        selectedPerson={person}
        onChange={(value) => {
          if (value) setPerson(value);
        }}
        additionalPersons={participants.personParticipants}
        placeholder={t("Select responsible person") ?? ""}
      />
      <Flex pt="2.5">
        <Button
          id="save-responsible-person-button"
          variant="solid"
          colorScheme="teal"
          size="xs"
          isLoading={isLoadingResponsiblePerson}
          onClick={async () => {
            setIsLoadingResponsiblePerson(true);
            const result = await edit({
              taskId: task.id,
              updateTaskRequest: {
                responsiblePerson: person.id,
              },
            });
            setIsLoadingResponsiblePerson(false);
            if ("data" in result) onEditComleted(result.data);
            else toast({ title: t("Could not edit responsible person"), status: "error" });
          }}
        >
          {t("Save")}
        </Button>
        <Button variant={"solid"} colorScheme="gray" size="xs" ml="5px" onClick={onCancel}>
          {t("Cancel")}
        </Button>
      </Flex>
    </Flex>
  );
};
