import { Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay } from "@chakra-ui/react";
import { BseAnnouncementDto, BseQuestionDto, BseQuestionStatus } from "../../../../../autogen/bff-api";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";
import { SupplierQuestionComments } from "./SupplierQuestionComments";
import { SupplierQuestionMeta } from "./SupplierQuestionMeta";

interface Props {
  canManageQuestions: boolean;
  addCommentDisabledReason: string | null;
  isOpen: boolean;
  close: () => void;
  question: BseQuestionDto;
  announcements: BseAnnouncementDto[];
  addComment: (values: { comment: string; createAnnouncementDraft: boolean }) => Promise<void>;
  changeStatus: (stauts: BseQuestionStatus) => Promise<boolean>;
}

export const SupplierQuestionModal = ({
  canManageQuestions,
  addCommentDisabledReason,
  isOpen,
  close,
  question,
  announcements,
  addComment,
  changeStatus,
}: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={close} size="3xl">
      <ModalOverlay />
      <ModalContent height={"80%"} maxHeight={"80%"} overflow={"hidden"} p="5">
        <ModalCloseButton />
        <ModalBody height={"full"}>
          <Flex flexDirection={"column"} height="full">
            <Heading as="h2" size="md" py="2">
              {displayWrappedWithEllipsis(question.subject, 60)}
            </Heading>
            <SupplierQuestionMeta
              canChangeQuestionStatus={canManageQuestions}
              announcements={announcements}
              question={question}
              changeStatus={changeStatus}
            />
            <SupplierQuestionComments
              addCommentDisabledReason={addCommentDisabledReason}
              addComment={addComment}
              question={question}
              showCreateAnnouncementDraft={true}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
