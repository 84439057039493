import { Box, Button, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { MinimalOrgDto } from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { OrganizationSelector } from "../../../../../common/organization/OrganizationSelector";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { CreateOrganizationEntryModal } from "../../../../organizations/entry/create-entry/CreateOrganizationEntryModal";
import { useBasicSourcingEventState } from "../../../useBasicSourcingEventState";
import { InviteOrganizationEntryModal } from "./InviteOrganizationEntryModal";
import { InviteOrganizationModal } from "./InviteOrganizationModal";
import { OrganizationInvitesTable } from "./OrganizationInvitesTable";

interface Props {
  nextStep: () => void;
  previousStep: () => void;
}

export const InvitedParties = ({ nextStep, previousStep }: Props) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const eventState = useBasicSourcingEventState();
  const errorMessage = eventState.organizationInvites.errorMessage;
  const { t } = useTranslation();
  const [selectedOrganization, setSelectedOrganization] = useState<MinimalOrgDto>();
  const [selectedOrganizationEntry, setSelectedOrganizationEntry] = useState<MinimalOrgDto>();
  const [showCreateEntryModal, setShowCreateEntryModal] = useState(false);

  const selectedOrgIds = useMemo(
    () =>
      [
        ...eventState.organizationInvites.value.map((i) => i.organizationId),
        ...eventState.emailInvites.value.map((i) => i.organizationEntry?.id),
      ].filter((id) => !!id) as string[],
    [eventState.emailInvites.value, eventState.organizationInvites.value]
  );

  const orgsToExclude = useMemo(
    () => [authState.selectedOrg.id, ...selectedOrgIds],
    [authState.selectedOrg.id, selectedOrgIds]
  );

  const removeOrgInvite = async (inviteId: string) => {
    await dispatch(editBseThunk({ command: { type: "RemoveOrganizationInvite", value: inviteId } }));
  };

  const removeOrgEntryInvite = async (inviteId: string) => {
    await dispatch(editBseThunk({ command: { type: "RemoveEmailInvite", value: inviteId } }));
  };

  return (
    <>
      {showCreateEntryModal && (
        <CreateOrganizationEntryModal
          onConfirm={async (organization) => {
            setSelectedOrganizationEntry({ type: "OrganizationEntry", ...organization });
          }}
          onClose={() => setShowCreateEntryModal(false)}
        />
      )}
      <InviteOrganizationModal
        eventStatus={eventState.event.status}
        minimalOrg={selectedOrganization}
        onClose={() => setSelectedOrganization(undefined)}
      />
      <InviteOrganizationEntryModal
        eventStatus={eventState.event.status}
        minimalOrg={selectedOrganizationEntry}
        onClose={() => setSelectedOrganizationEntry(undefined)}
      />
      <FormControl id="registeredCompanies" pt="8" height="full" isInvalid={errorMessage !== null}>
        <FormLabel>{t("Invite supplier")}</FormLabel>
        <Flex data-testid="search-for-company-name" width="full" px="0.5">
          <OrganizationSelector
            organization={selectedOrganization ?? selectedOrganizationEntry}
            orgsToExclude={orgsToExclude}
            onChange={async ({ organization }) => {
              if (organization?.type === "Organization") setSelectedOrganization(organization);
              else if (organization?.type === "OrganizationEntry") setSelectedOrganizationEntry(organization);
              else {
                setSelectedOrganization(undefined);
                setSelectedOrganizationEntry(undefined);
              }
            }}
          />
        </Flex>
        <FormHelperText px="0.5">
          {t("Please select an existing company or")}{" "}
          <Button
            variant={"link"}
            colorScheme="blue"
            fontWeight={"bold"}
            fontSize="sm"
            onClick={() => {
              setShowCreateEntryModal(true);
            }}
          >
            {t("create a new company entry")}
          </Button>
        </FormHelperText>
        <FormErrorMessage>{errorMessage}</FormErrorMessage>
        <Box pt="5">
          <OrganizationInvitesTable
            orgInvites={eventState.organizationInvites.value}
            orgEntryInvites={eventState.emailInvites.value}
            removeOrgInvite={(inviteId: string) => removeOrgInvite(inviteId)}
            removeOrgEntryInvite={(inviteId: string) => removeOrgEntryInvite(inviteId)}
          />
        </Box>
      </FormControl>
      <Flex pt="10">
        <Button variant={"outline"} mr="5px" w="100%" colorScheme={"teal"} onClick={previousStep}>
          {t("Previous")}
        </Button>
        <Button variant={"solid"} w="100%" colorScheme={"teal"} onClick={nextStep}>
          {t("Next")}
        </Button>
      </Flex>
    </>
  );
};
