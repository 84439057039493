import { useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { setSourcingEvent } from "../../../../common/redux/reducers/basicSourcingEventReducer";
import { loadBseThunk } from "../../../../common/redux/thunks/basic-sourcing-event/load-bse-thunk";
import { EditBasicSourcingEventForm } from "./EditBasicSourcingEventForm";

export const EditBasicSourcingEvent = () => {
  const params = useParams();
  const dispatch = useAppDispatch();
  const [eventLoaded, setEventLoaded] = useState(false);
  const eventState = useAppSelector((state) => state.basicSourcingEvent.state);
  const { t } = useTranslation();

  useEffect(() => {
    if (params.eventId) {
      dispatch(loadBseThunk({ eventId: params.eventId })).then(() => {
        setEventLoaded(true);
      });
    }

    return () => {
      dispatch(setSourcingEvent(null));
    };
  }, [dispatch, params.eventId]);

  if (!params.eventId) return <div>{t("404 event not found")}</div>;
  if (eventState && eventLoaded) return <EditBasicSourcingEventForm />;
  else return null;
};
