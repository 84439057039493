import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { OrganizationInviteStatus } from "../../../../autogen/bff-api";

export const InvitedOrganizationStatusTag = ({ status }: { status: OrganizationInviteStatus }) => {
  const { t } = useTranslation();
  const getTagMeta = (status: OrganizationInviteStatus): { color: string; text: string } => {
    switch (status) {
      case "BidIsSubmitted":
        return {
          color: "purple",
          text: t("Bid submitted"),
        };
      case "BidWasNotNotSubmitted":
        return {
          color: "gray",
          text: t("No bid submitted"),
        };
      case "InvitationAccepted":
        return {
          color: "teal",
          text: t("Intends to bid"),
        };
      case "InvitationSent":
        return {
          color: "gray",
          text: t("Has not seen event"),
        };
      case "HasSeenEvent":
        return {
          color: "orange",
          text: t("Has seen event"),
        };
      case "IsLoser":
        return {
          color: "red",
          text: t("Loser"),
        };
      case "IsWinner":
        return {
          color: "green",
          text: t("Winner!"),
        };
      case "DraftCreated":
        return {
          color: "teal",
          text: t("Draft created"),
        };
      case "InvitationDeclined":
        return {
          color: "gray",
          text: t("Invitaion declined"),
        };
    }
  };

  const tagMeta = getTagMeta(status);

  return (
    <Tag colorScheme={tagMeta.color} size="sm" textAlign="center" py="2" mr="2.5">
      {tagMeta.text}
    </Tag>
  );
};
