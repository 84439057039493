import {
  Box,
  Button,
  Flex,
  FormControl,
  FormHelperText,
  FormLabel,
  Icon,
  Spinner,
  Switch,
  Table,
  Td,
  Text,
  Th,
  Thead,
  Tr,
} from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaDownload, FaEye } from "react-icons/fa";
import { utils, write } from "xlsx";
import {
  BseDto,
  ReceivedBseBidDto,
  useCompleteEvaluationMutation,
  useGetProductGroupsForSourcingEventQuery,
  useListReceivedBseBidsQuery,
  useOpenCompletedEventMutation,
  useUpdateBasicSourcingEventMutation,
} from "../../../../../autogen/bff-api";
import { ConversationModal, Message } from "../../../../../common/ConversationModal";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { useAppDispatch, useAppSelector } from "../../../../../common/redux/hooks";
import { selectAllBids, setSourcingEvent } from "../../../../../common/redux/reducers/basicSourcingEventReducer";
import {
  mapDtoToProductGroup,
  setProductGroups,
  updateProductGroup,
} from "../../../../../common/redux/reducers/productReducer";
import { editProductGroupThunk } from "../../../../../common/redux/thunks/product/editProductGroupThunk";
import { Product, ProductDraft, ProductGroup } from "../../../../../common/types";
import { eventIsAwarded } from "../../../eventIsAwarded";
import { eventIsCompleted } from "../../../eventIsCompleted";
import { requireDeadlineHasPassed } from "../../../requireDeadlineHasPassed";
import { ViewBidAsBuyerModal } from "../ViewBidAsBuyerModal";
import {
  findProductBid,
  getQuotesDelivered,
  getQuotesDeliveredInBid,
  getQuotesRequested,
  getSelectedOrWinningBids,
  getSumOfBestPrices,
  getSumOfSelectedPrices,
  getTotalPrice,
  getTotalProductPrice,
  isBestTotalPrice,
} from "./BidEvaluationUtils";
import { EvaluationTable } from "./EvaluationTable";

export const BidEvalutation = ({ sourcingEvent }: { sourcingEvent: BseDto }) => {
  const dispatch = useAppDispatch();
  const authState = useLoggedInWithOrgContextState();
  const { data } = useGetProductGroupsForSourcingEventQuery({ sourcingEventId: sourcingEvent.id });
  const [updateSourcingEvent] = useUpdateBasicSourcingEventMutation();
  const [completeEvent] = useCompleteEvaluationMutation();
  const [openCompletedEvent] = useOpenCompletedEventMutation();

  const [productConversation, setProductConversation] = useState<{
    conversation: Message[];
    title: string;
    productGroup: ProductGroup;
    product: Product | ProductDraft;
  }>();

  const [bidIdToView, setBidIdToView] = useState<string>();

  useEffect(() => {
    dispatch(setProductGroups(data?.productGroups.map((group) => mapDtoToProductGroup(group))));
  }, [data, dispatch]);

  const productGroups = useAppSelector((state) => state.product.data.productGroups);

  const { data: bidsResponse, error, isLoading } = useListReceivedBseBidsQuery({ eventId: sourcingEvent.id });

  const bids = useMemo(
    () =>
      bidsResponse?.bids.map((bid) => ({
        ...bid,
        products: bid.products
          ?.filter(({ productGroupId }) => productGroups.map((g) => g.id).includes(productGroupId))
          .map((groupBid) => ({
            ...groupBid,
            productBids: groupBid.productBids.filter((p) =>
              productGroups
                .find((g) => g.id === groupBid.productGroupId)
                ?.products?.map((p) => p.id)
                .includes(p.productId)
            ),
          })),
      })),
    [bidsResponse?.bids, productGroups]
  );

  const selectAllBidsFromSupplier = (bid: ReceivedBseBidDto) => {
    dispatch(selectAllBids(bid));
    updateSourcingEvent({
      eventId: sourcingEvent.id,
      editSourcingEventRequest: {
        selectProductBids: bid.products?.map((groupBid) => ({
          productGroupId: groupBid.productGroupId,
          bids: groupBid.productBids.map((productBid) => ({
            productId: productBid.productId,
            bids: [bid.id],
          })),
        })),
      },
    });
  };

  const handleComment = async ({
    group,
    product,
    comment,
  }: {
    group: ProductGroup;
    product: Product | ProductDraft;
    comment: string;
  }) => {
    const updatedGroup = cloneDeep(group);
    if (!updatedGroup.products) throw Error("No products found - could not handle comment.");
    const productIndex = updatedGroup.products.findIndex((p) => p.id === product.id);
    if (productIndex && productIndex < 0) throw Error(`Product w id=${product.id} not found.`);
    const updatedProduct = updatedGroup.products[productIndex];
    const message = {
      comment,
      createdAt: moment().toISOString(),
      modifiedAt: moment().toISOString(),
      createdBy: authState.me,
    };
    productConversation?.conversation.push({
      id: `${productConversation.conversation.length}`,
      value: message.comment,
      createdAt: message.createdAt,
      createdBy: `${message.createdBy.firstName} ${message.createdBy.lastName}`,
    });
    updatedProduct?.conversation?.push(message);
    dispatch(updateProductGroup(updatedGroup));
    await dispatch(
      editProductGroupThunk({
        id: group.id,
        editProduct: {
          // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
          id: updatedProduct.id!,
          comment: { comment },
        },
      })
    );
  };

  const generateReport = useCallback(() => {
    if (!productGroups?.length || !bids) throw Error("Could not generate report. No product groups found.");
    const workbook = utils.book_new();
    const selectedBids = getSelectedOrWinningBids(sourcingEvent);
    for (const group of productGroups) {
      const headings = group.productFields.filter((f) => f.populatedBy === "Buyer").map((field) => field.name);
      headings.push(t("Quantity"));
      headings.push(t("Unit Price"));
      headings.push(t("Total price"));
      headings.push(t("Awarded to"));
      headings.push(...group.productFields.filter((f) => f.populatedBy === "Supplier").map((f) => f.name));
      const table: (string | number | undefined | boolean | null)[][] = [headings];
      if (!group.products) continue;
      const selectedGroupBidIds = selectedBids?.find((s) => s.productGroupId === group.id);
      for (const product of group.products) {
        if (!product.id) throw Error("No product id");
        const selectedProductBidIds = selectedGroupBidIds?.bids.find((b) => b.productId === product.id)?.bids;
        const selectedBids = bids.filter((bid) => selectedProductBidIds?.includes(bid.id));
        const selectedGroupBids = selectedBids?.map((b) => ({
          bid: b,
          groupBid: b.products?.find((p) => p.productGroupId === group.id),
          supplierName: b.owningOrganization.name,
        }));
        for (const selectedGroupBid of selectedGroupBids) {
          const productRow = group.productFields
            .filter((f) => f.populatedBy === "Buyer")
            .map((field) => product.productFields.find((f) => f.id === field.id)?.value);
          productRow.push(product.quantity.value);
          const productBid = findProductBid({ productId: product.id, groupId: group.id, bid: selectedGroupBid.bid });
          productRow.push(productBid?.unitPrice.value);
          if (selectedBids) {
            productRow.push(getTotalProductPrice({ group, productId: product.id, bid: selectedGroupBid.groupBid }));
          }
          productRow.push(selectedGroupBid.supplierName);
          productRow.push(
            ...group.productFields
              .filter((f) => f.populatedBy === "Supplier")
              .map((field) => productBid?.productFields.find((f) => f.id === field.id)?.value)
          );
          table.push(productRow);
        }
      }
      const sheet = utils.aoa_to_sheet(table);
      utils.book_append_sheet(workbook, sheet, group.name.slice(0, 31));
    }
    const arrayBuffer = write(workbook, { bookType: "xlsx", type: "buffer" });
    const blob = new Blob([arrayBuffer], { type: "application/octet-stream" });
    const url = window.URL.createObjectURL(blob);

    const a = document.createElement("a");
    a.href = url;
    a.download = `${sourcingEvent.title}.xlsx`;

    a.click();

    window.URL.revokeObjectURL(url);
  }, [bids, productGroups, sourcingEvent]);

  if (isLoading)
    return (
      <Flex w="full" height="full" minHeight={"96"} justify={"center"} alignItems={"center"}>
        <Spinner />
      </Flex>
    );

  if (error) return <p>{t("Something went wrong, our team is on it!")}</p>;

  if (!productGroups?.length)
    return (
      <Text color="gray.500" textAlign={"center"} pt="8">
        {t("No products to evaluate found for this event")}.
      </Text>
    );

  if (!bids?.length)
    return (
      <Text color="gray.500" textAlign={"center"} pt="8">
        {t("No bids delivered for this event.")}
      </Text>
    );

  return (
    <>
      <ConversationModal
        isOpen={!!productConversation}
        title={productConversation?.title}
        conversation={productConversation?.conversation.map((msg) => ({
          id: msg.id,
          value: msg.value,
          createdBy: msg.createdBy,
          createdAt: moment(msg.createdAt).format("DD MMM YY hh.mm"),
        }))}
        onClose={() => setProductConversation(undefined)}
        handleMessage={(comment: string) => {
          if (!productConversation) return;
          handleComment({ comment, product: productConversation.product, group: productConversation.productGroup });
        }}
        isLoading={false}
        canComment={sourcingEvent.canEdit}
      />
      {bidIdToView && (
        <ViewBidAsBuyerModal
          eventId={sourcingEvent.id}
          bidId={bidIdToView}
          isOpen={bidIdToView !== undefined}
          onClose={() => setBidIdToView(undefined)}
        />
      )}
      <FormControl display="flex" justifyContent="center" alignItems={"center"} flexDirection={"column"} py="10">
        <FormLabel htmlFor="email-alerts" m="0">
          {t("Complete Evaluation")}
        </FormLabel>
        <Switch
          size={"lg"}
          mt="2"
          colorScheme="teal"
          disabled={eventIsAwarded(sourcingEvent) || !requireDeadlineHasPassed(sourcingEvent)}
          isChecked={eventIsCompleted(sourcingEvent)}
          onChange={async (e) => {
            if (e.target.checked) {
              const res = await completeEvent({ eventId: sourcingEvent.id });
              if ("data" in res) dispatch(setSourcingEvent(res.data));
            } else {
              const res = await openCompletedEvent({ eventId: sourcingEvent.id });
              if ("data" in res) dispatch(setSourcingEvent(res.data));
            }
          }}
        />
        <FormHelperText pb="8">
          {eventIsCompleted(sourcingEvent)
            ? t("The evaluation is completed")
            : t("Toggle to complete the evaluation with the current price selection")}
        </FormHelperText>
      </FormControl>
      <Table>
        <Thead>
          <Tr>
            <Th p="1"></Th>
            {bids.map((bid) => (
              <Th key={bid.id} p="1" textAlign={"center"}>
                {bid.owningOrganization.name}
              </Th>
            ))}
            <Th textAlign={"center"}>{t("Best prices")}</Th>
            <Th textAlign={"center"}>{t("Selected")}</Th>
          </Tr>
          {productGroups && (
            <>
              <Tr fontWeight={"bold"}>
                <Td>{t("SUM Total")}</Td>
                {bids.map((bid) => (
                  <Td
                    key={bid.id}
                    textAlign={"center"}
                    cursor={"pointer"}
                    color={isBestTotalPrice({ bid, bids, groups: productGroups }) ? "green.500" : "yellow.500"}
                    onClick={() => selectAllBidsFromSupplier(bid)}
                  >
                    {getTotalPrice({ bid, groups: productGroups })?.toLocaleString()}
                  </Td>
                ))}
                <Td textAlign={"center"}>{getSumOfBestPrices({ groups: productGroups, bids })?.toLocaleString()}</Td>
                <Td textAlign={"center"}>
                  {getSumOfSelectedPrices({
                    groups: productGroups,
                    bids,
                    sourcingEvent,
                  }).toLocaleString()}
                </Td>
              </Tr>
              <Tr>
                <Td py="1">{t("Product quotes")}</Td>
                {bids.map((bid) => (
                  <Td
                    key={bid.id}
                    textAlign={"center"}
                    color={
                      getQuotesDeliveredInBid({ bid }) >= getQuotesRequested(productGroups) ? "green.500" : "yellow.500"
                    }
                  >
                    {getQuotesDeliveredInBid({ bid })}/{getQuotesRequested(productGroups)}
                  </Td>
                ))}
                <Td
                  textAlign={"center"}
                  color={
                    getQuotesDelivered({ groups: productGroups, bids }) >= getQuotesRequested(productGroups)
                      ? "green.500"
                      : "yellow.500"
                  }
                >
                  {getQuotesDelivered({ groups: productGroups, bids })}/{getQuotesRequested(productGroups)}
                </Td>
                <Td></Td>
              </Tr>
              <Tr>
                <Td></Td>
                {bids.map((bid) => (
                  <Td key={bid.id} textAlign={"center"} py="1">
                    <Button
                      size="sm"
                      colorScheme="teal"
                      variant="ghost"
                      leftIcon={<Icon as={FaEye} />}
                      onClick={() => setBidIdToView(bid.id)}
                    >
                      {t("View bid")}
                    </Button>
                  </Td>
                ))}
                <Td></Td>
                <Td></Td>
              </Tr>
            </>
          )}
        </Thead>
      </Table>
      <Box p="4" mt="20" rounded="lg">
        <Box overflow={"auto"}>
          {productGroups?.map((group) => (
            <EvaluationTable
              key={group.id}
              sourcingEvent={sourcingEvent}
              bids={bids}
              group={group}
              setProductConversation={setProductConversation}
            />
          ))}
        </Box>
      </Box>
      {(eventIsCompleted(sourcingEvent) || authState.selectedOrg.canSeeDeliveredBidsBeforeDeadline) && (
        <Flex justifyContent={"end"} pb="4" pr="4">
          <Button colorScheme="teal" variant={"outline"} onClick={generateReport}>
            {t("Report")} <Icon as={FaDownload} ml="2" />
          </Button>
        </Flex>
      )}
    </>
  );
};
