import { FormControl, FormErrorMessage, FormHelperText, FormLabel } from "@chakra-ui/react";
import { Control, Controller, FieldValues, Path } from "react-hook-form";
import { PersonDto } from "../../autogen/bff-api";
import { PersonsSelector } from "./PersonsSelector";

interface Props<K extends string, T extends FieldValues & Record<K, PersonDto[]>> {
  label: string;
  helperText?: string;
  control: Control<T>;
  name: Path<T>;
  placeholderText: string;
  required?: {
    value: boolean;
    message: string;
  };
  errorMessage: string | undefined;
  size?: "sm" | "md";
  isDisabled?: boolean;
  isLoading?: boolean;
  organizationIds: string[] | null;
  additionalPersons?: PersonDto[];
}

export const PersonsSelectorForm = <K extends string, T extends FieldValues & Record<K, PersonDto[]>>({
  label,
  helperText,
  control,
  name,
  placeholderText,
  required,
  errorMessage,
  size,
  organizationIds,
  additionalPersons,
}: Props<K, T>) => {
  return (
    <FormControl id={name} rounded="md" isRequired={required?.value === true} isInvalid={errorMessage !== undefined}>
      <FormLabel htmlFor={name}>{label}</FormLabel>
      <Controller
        name={name}
        control={control}
        rules={{ required }}
        render={({ field: { onChange, value } }) => {
          return (
            <PersonsSelector
              organizationIds={organizationIds}
              size={size}
              onChange={(value) => onChange(value)}
              selectedPersons={value as PersonDto[]}
              placeholder={placeholderText}
              additionalPersons={additionalPersons}
            />
          );
        }}
      />
      {helperText && <FormHelperText>{helperText}</FormHelperText>}
      <FormErrorMessage>{errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
