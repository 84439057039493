import {
  AwardedFieldsDto,
  BseDto,
  BseSupplierViewAwardedFieldsDto,
  BseSupplierViewDto,
  DraftFieldsDto,
  PublishedFieldsDto,
} from "../../autogen/bff-api";

export const isBse = (event: BseDto | BseSupplierViewDto): event is BseDto => {
  return (event as BseDto).emailInvites !== undefined;
};

export const isBseSupplierView = (event: BseDto | BseSupplierViewDto): event is BseDto => {
  return (event as BseSupplierViewDto).contactPerson !== undefined;
};

export const isBseDraft = (event: BseDto): event is BseDto & { draftFields: DraftFieldsDto } => {
  return event.draftFields !== null;
};

export const isPublishedBse = (event: BseDto): event is BseDto & { publishedFields: PublishedFieldsDto } => {
  return event.publishedFields !== null;
};

export const isCompletedBse = (event: BseDto) => event.stateName === "Completed";

export const isAwardedBse = (event: BseDto): event is BseDto & { awardedFields: AwardedFieldsDto } => {
  return event.awardedFields !== null;
};

export const isPublishedOrAwardedBse = (
  event: BseDto
): event is (BseDto & { publishedFields: PublishedFieldsDto }) | (BseDto & { awardedFields: AwardedFieldsDto }) => {
  return event.publishedFields !== null || event.awardedFields !== null;
};

export const isAwardedBseSupplierView = (
  event: BseSupplierViewDto
): event is BseSupplierViewDto & {
  awardedFieldsDto: BseSupplierViewAwardedFieldsDto;
} => {
  return event.awardedFieldsDto !== null;
};
