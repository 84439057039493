import { Button, Modal, ModalBody, ModalCloseButton, ModalContent, ModalHeader, ModalOverlay } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { AdminOrgDto } from "../../../autogen/bff-api";
import { setImpersonatedPersonId } from "../../../common/local-storage/impersonated-person";
import { clearOrganizationId } from "../../../common/local-storage/selected-organization";

interface Props {
  organization: AdminOrgDto;
  onClose: () => void;
}

export const AdminImpersonateUserModal = ({ organization, onClose }: Props) => {
  const { t } = useTranslation();

  return (
    <Modal isOpen={true} onClose={onClose}>
      <ModalOverlay />
      <ModalContent>
        <ModalHeader>{t("Edit organization")}</ModalHeader>
        <ModalCloseButton />
        <ModalBody pb={6}>
          <Button
            colorScheme="blue"
            variant={"solid"}
            onClick={() => {
              setImpersonatedPersonId(organization.owner.id);
              clearOrganizationId();
              window.location.reload();
            }}
          >
            {organization.owner.email}
          </Button>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
