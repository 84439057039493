import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { debounce, uniqBy } from "lodash";
import { useCallback, useMemo, useState } from "react";
import { OrganizationRoleType, PersonDto, useListPersonsQuery } from "../../autogen/bff-api";
import { displayPersonNameWithEmail } from "../../pages/contracts/view-single/sharing/AddExternalParticipantModal";
import { MultiSelector } from "../input/Selector/MultiSelector";
import { SelectorOptions } from "../input/Selector/SelectorValue";

export const PersonsSelector = ({
  organizationIds,
  selectedPersons,
  placeholder,
  onChange,
  personsToExclude = [],
  additionalPersons,
  size = "sm",
  roles,
}: {
  organizationIds: string[] | null;
  selectedPersons?: PersonDto[];
  personsToExclude?: string[];
  additionalPersons?: PersonDto[];
  placeholder?: string;
  onChange: (persons: PersonDto[]) => void | Promise<void>;
  size?: "sm" | "md";
  roles?: OrganizationRoleType[];
}) => {
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isFetching } = useListPersonsQuery(
    organizationIds?.length
      ? {
          orgIds: organizationIds,
          name: debouncedSearchName ? debouncedSearchName : undefined,
          roles,
          limit: 10,
        }
      : skipToken
  );

  const options: SelectorOptions = useMemo(() => {
    const allPersons = uniqBy([...(data?.persons ?? []), ...(additionalPersons ?? [])], (p) => p.id);
    return (
      allPersons
        .filter((p) => !personsToExclude.includes(p.id))
        .filter((o) => !selectedPersons?.map((sp) => sp.id).includes(o.id))
        .filter(
          (p) =>
            `${p.firstName} ${p.lastName}`.toLowerCase().includes(debouncedSearchName.toLowerCase()) ||
            `${p.lastName} ${p.firstName}`.toLowerCase().includes(debouncedSearchName.toLowerCase()) ||
            p.email.toLowerCase().includes(debouncedSearchName.toLowerCase())
        )
        .map((o) => ({ label: displayPersonNameWithEmail(o), value: o.id })) ?? []
    );
  }, [additionalPersons, data?.persons, personsToExclude, debouncedSearchName, selectedPersons]);

  return (
    <MultiSelector
      size={size}
      placeholder={{ text: placeholder ?? `${t("Select person")}...`, color: "" }}
      value={
        selectedPersons?.map((p) => ({
          label: displayPersonNameWithEmail(p),
          value: p.id,
        })) ?? []
      }
      inputValue={searchName}
      isLoading={isFetching}
      onInputChange={(name: string) => {
        setSearchName(name);
        handleDebouncedSearchName(name);
      }}
      options={options}
      onChange={async (o) => {
        const selectedIds = o.map((o) => o.value);
        const selectablePersons = uniqBy(
          [...(selectedPersons ?? []), ...(data?.persons ?? []), ...(additionalPersons ?? [])],
          (p) => p.id
        );
        const persons = selectablePersons.filter((p) => selectedIds.includes(p.id));
        if (o && selectedIds.length !== persons.length) throw Error("Illegal state: Persons not found");
        await onChange(persons ?? []);
      }}
      noOptionsAvailableMessage={t("No persons")}
    />
  );
};
