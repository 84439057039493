import { Flex, Spinner } from "@chakra-ui/react";
import React, { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { Navigate, Route, Routes } from "react-router-dom";
import { useGetBaseDataQuery } from "./autogen/bff-api";
import { Errors } from "./autogen/errors";
import { useAppDispatch, useAppSelector } from "./common/redux/hooks";
import { setBaseData } from "./common/redux/reducers/baseDataReducer";
import { getInitialAuthStateThunk } from "./common/redux/thunks/auth/get-initial-auth-state-thunk";
import { CustomIntercomProvider } from "./common/support/CustomIntercomProvider/CustomIntercomProvider";
import { Admin } from "./pages/admin";
import { EmailVerification } from "./pages/auth/email-confirmed";
import { Login } from "./pages/auth/login/Login";
import { NoOrganizationsFound } from "./pages/auth/no-organizations-found/NoOrganizationsFound";
import { EditBasicBid } from "./pages/bids/edit/EditBasicBid";
import { DeliveredBids } from "./pages/bids/view-all/DeliveredBids";
import { EditContract } from "./pages/contracts/edit/EditContract";
import { ContractSettings } from "./pages/contracts/settings/ContractSettings";
import { ContractTasks } from "./pages/contracts/view-all-tasks/ContractTasks";
import { ContractDrafts } from "./pages/contracts/view-all/ContractDrafts";
import { PublishedContracts } from "./pages/contracts/view-all/PublishedContracts";
import { ContractPage } from "./pages/contracts/view-single/ContractPage";
import { AcceptEmailInviteWhenLoggedIn } from "./pages/email-invites/accept/AcceptEmailInviteWhenLoggedIn";
import { AcceptEmailInviteWhenLoggedInWithOrgContext } from "./pages/email-invites/accept/AcceptEmailInviteWhenLoggedInWithOrgContext";
import { EmailInvite } from "./pages/email-invites/view-single/EmailInvite";
import { Home } from "./pages/home/Home";
import { Notifications } from "./pages/notifications/Notifications";
import { EditOrganizationProfile } from "./pages/organizations/current/edit/EditOrganizationProfile";
import { AccessManagementPage } from "./pages/organizations/current/manage-users/AccessManagementPage";
import { OrganizationsPage } from "./pages/organizations/search/OrganizationsPage";
import { ViewOrganizationProfile } from "./pages/organizations/view-single/ViewOrganizationProfile";
import { EditPersonalProfile } from "./pages/personal-profile/edit/EditPersonalProfile";
import { ViewPersonalProfile } from "./pages/personal-profile/view/ViewPersonalProfile";
import { Projects } from "./pages/projects/view-all/Projects";
import { ProjectPage } from "./pages/projects/view-single/ProjectPage";
import { Settings } from "./pages/settings/Settings";
import { EditBasicSourcingEvent } from "./pages/sourcing-events/buyer/edit/EditBasicSourcingEvent";
import { EditNegotiationPage } from "./pages/sourcing-events/buyer/edit/EditNegotiationPage";
import { EventsForBuyer } from "./pages/sourcing-events/buyer/view-all/EventsForBuyer";
import { BseView } from "./pages/sourcing-events/buyer/view-single/BseView";
import { EventsForSupplier } from "./pages/sourcing-events/supplier/view-all";
import { BseSupplierView } from "./pages/sourcing-events/supplier/view-single/BseSupplierView";
import { urls } from "./urls";

export const App = () => {
  const authState = useAppSelector((state) => state.authState.state);
  const dispatch = useAppDispatch();
  const { data } = useGetBaseDataQuery();
  const { t } = useTranslation();

  useEffect(() => {
    dispatch(getInitialAuthStateThunk());
  }, [dispatch]);

  useEffect(() => {
    if (data) dispatch(setBaseData(data));
  }, [data, dispatch]);

  /**
   * Used for translating API error codes
   * DO NOT REMOVE
   * */
  const types = Errors();

  if (!authState || !data) {
    return (
      <Flex w="100%" h="90vh" alignItems={"center"} justifyContent="center">
        {types}
        <Spinner thickness="4px" speed="0.65s" emptyColor="gray.200" color="blue.500" size="xl" />
      </Flex>
    );
  }
  const getRoutes = (): React.ReactElement => {
    switch (authState.type) {
      case "LoggedOut":
        return (
          <Routes>
            <Route path={urls.emailVerification} element={<EmailVerification />} />
            <Route path={urls.login} element={<Login />} />
            <Route path="/*" element={<Navigate to={urls.login} replace />} />
            <Route path={urls.emailInvite} element={<EmailInvite />} />
          </Routes>
        );
      case "LoggedIn": {
        return (
          <Routes>
            <Route path={urls.admin} element={<Admin />} />
            <Route path={urls.organizations.init} element={<NoOrganizationsFound />} />
            <Route path={urls.emailInvite} element={<AcceptEmailInviteWhenLoggedIn />} />
            <Route path="/*" element={<Navigate to={urls.organizations.init} replace />} />
          </Routes>
        );
      }

      case "LoggedInWithOrgContext":
        return (
          <Routes>
            <Route path={urls.admin} element={<Admin />} />
            <Route path={urls.home} element={<Home />} />

            <Route path={urls.events.view.pathname} element={<BseView />} />
            <Route path={urls.emailInvite} element={<AcceptEmailInviteWhenLoggedInWithOrgContext />} />
            <Route path={urls.events.supplierView.pathname} element={<BseSupplierView />} />
            <Route path={urls.events.edit.paths.intro.pathname} element={<EditBasicSourcingEvent />} />
            <Route path={urls.events.editNegotiation.paths.intro.pathname} element={<EditNegotiationPage />} />

            <Route path={urls.emailInvite} element={<AcceptEmailInviteWhenLoggedIn />} />

            <Route
              path={urls.events.viewOngoing}
              element={<EventsForBuyer title={t("Ongoing events")} status="Ongoing" />}
            />

            <Route
              path={urls.events.viewInvitations}
              element={
                <EventsForSupplier
                  title={t("Bid invitations")}
                  subtitle={t("Events you are invited to deliver a bid for")}
                  status="Ongoing"
                />
              }
            />

            <Route path={urls.bids.viewAll} element={<DeliveredBids />} />

            <Route
              path={urls.events.viewDrafts}
              element={<EventsForBuyer title={t("Event drafts")} status="Drafts" />}
            />

            <Route
              path={urls.events.viewCompleted}
              element={<EventsForBuyer title={t("Completed events")} status="Completed" />}
            />

            <Route path={urls.bids.edit.paths.details.pathname} element={<EditBasicBid />} />

            <Route path={urls.contracts.edit.paths.details.pathname} element={<EditContract />} />

            <Route path={urls.contracts.viewDrafts} element={<ContractDrafts />} />

            <Route path={urls.contracts.viewPublished} element={<PublishedContracts />} />

            <Route path={urls.contracts.viewTasks} element={<ContractTasks />} />

            <Route path={urls.contracts.settings} element={<ContractSettings />} />

            <Route path={urls.contracts.viewSingle.pathname} element={<ContractPage />} />

            <Route path={urls.organizations.search} element={<OrganizationsPage />} />

            <Route path={urls.organizations.profile.pathname} element={<ViewOrganizationProfile />} />

            <Route path={urls.myProfile.organization.edit} element={<EditOrganizationProfile />} />

            <Route path={urls.myProfile.organization.manageUsers} element={<AccessManagementPage />} />

            <Route path={urls.notifications} element={<Notifications />} />

            <Route path={urls.myProfile.personal.view} element={<ViewPersonalProfile />} />
            <Route path={urls.myProfile.personal.edit} element={<EditPersonalProfile />} />

            <Route path={urls.projects.viewAll} element={<Projects />} />
            <Route path={urls.projects.view.pathname} element={<ProjectPage />} />

            <Route path={urls.myProfile.organization.settings} element={<Settings />} />

            <Route path="/*" element={<Navigate to="/" replace />} />
          </Routes>
        );
    }
  };

  return <CustomIntercomProvider>{getRoutes()}</CustomIntercomProvider>;
};
