import {
  Box,
  Button,
  Drawer,
  DrawerBody,
  DrawerContent,
  DrawerOverlay,
  Flex,
  Grid,
  GridItem,
  Heading,
  useMediaQuery,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { LastChangedTag } from "../../../../common/editing/LastChangedTag/LastChangedTag";
import { Layout } from "../../../../common/layout/Layout";
import { useSub } from "../../../../common/subscription/useSub";
import { useQueryParams } from "../../../../common/useQueryParams";
import { CONTENT_WIDTH } from "../../../../config";
import { urls } from "../../../../urls";
import { EventStatusTag } from "../../EventStatusTag";
import { useBasicSourcingEventState } from "../../useBasicSourcingEventState";
import styles from "./EditBasicSourcingEventForm.module.css";
import { Navigation } from "./Navigation";
import { Docs } from "./docs/Docs";
import { Intro } from "./intro/Intro";
import { InvitedParties } from "./parties/InvitedParties";
import { Products } from "./products/ProductsPage";
import { Review } from "./review/Review";

export const EditBasicSourcingEventForm = () => {
  const params = useQueryParams();
  const currentStep = params.get("step");
  const [showDrawer, setShowDrawer] = useState(false);
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const navigate = useNavigate();
  const eventState = useBasicSourcingEventState();
  const subscriptions = useSub();

  useEffect(() => {
    if (!currentStep) {
      navigate(urls.events.edit.go(eventState.id).intro);
    }
  }, [currentStep, eventState.id, navigate]);

  if (!currentStep) return <div>{t("Loading")}</div>;

  const navigateToIntro = () => navigate(urls.events.edit.go(eventState.id).intro);
  const navigateToProducts = subscriptions.hasProductAccess
    ? () => navigate(urls.events.edit.go(eventState.id).products)
    : undefined;
  const navigateToDocs = () => navigate(urls.events.edit.go(eventState.id).docs);
  const navigateToParties = () => navigate(urls.events.edit.go(eventState.id).parties);
  const navigateToReview = () => navigate(urls.events.edit.go(eventState.id).review);
  const navigateToEventView = () => navigate(urls.events.view.go(eventState.id));

  const navigation = (
    <Navigation
      navigateToIntro={navigateToIntro}
      navigateToProducts={navigateToProducts}
      navigateToDocs={navigateToDocs}
      navigateToParties={navigateToParties}
      navigateToReview={navigateToReview}
      navigateToEventView={navigateToEventView}
    />
  );

  return (
    <Layout>
      <Drawer placement={"bottom"} onClose={() => setShowDrawer(false)} isOpen={showDrawer}>
        <DrawerOverlay />
        <DrawerContent borderTop="1px solid" borderColor="smBorder">
          <DrawerBody>{navigation}</DrawerBody>
        </DrawerContent>
      </Drawer>
      <Flex
        display={isLargerThan768 ? "none" : "flex"}
        position="fixed"
        zIndex={1}
        bottom="0px"
        width="full"
        height="60px"
        borderTop={"1px solid"}
        borderColor="smBorder"
        alignItems="center"
        bg="smBackground"
      >
        <Button
          variant={"solid"}
          colorScheme="blue"
          width="full"
          ml="10px"
          mr="10px"
          onClick={() => setShowDrawer(true)}
        >
          {t("Change step")}
        </Button>
      </Flex>
      <Grid
        height="full"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={`auto minmax(min-content, auto)`}
        templateAreas={`
                "contentLeft content contentRight"
                `}
        width="full"
        maxWidth="full"
        overflowX="hidden"
      >
        <GridItem area="content" maxWidth="full" width="full">
          <Grid className={styles.grid} width="full" maxWidth="full" marginTop="9px" px="2">
            <GridItem
              area="menuNav"
              alignItems="end"
              borderBottom="1px solid"
              borderColor="smBorder"
              width="full"
              maxWidth="full"
            ></GridItem>
            <GridItem area="contentNav" alignItems="end" borderBottom="1px solid" borderColor="smBorder">
              <Heading as="h2" size="md" mb="2">
                {eventState.title.value}
              </Heading>
              <Flex columnGap="1">
                <EventStatusTag status={eventState.event.status} />
                <LastChangedTag timestamp={eventState.lastChanged} />
              </Flex>
            </GridItem>
            <GridItem area="menu" display={isLargerThan768 ? "block" : "none"}>
              <Box pt="10">{navigation}</Box>
            </GridItem>
            <GridItem area="content" maxWidth="full" overflowX="hidden">
              <Box maxWidth="full">
                <Box display={currentStep === "intro" ? undefined : "none"}>
                  <Intro nextStep={navigateToProducts ?? navigateToDocs} />
                </Box>
                <Box display={currentStep === "products" ? undefined : "none"} maxWidth="full">
                  <Products previousStep={navigateToIntro} nextStep={navigateToDocs} />
                </Box>
                <Box display={currentStep === "docs" ? undefined : "none"}>
                  <Docs previousStep={navigateToProducts ?? navigateToIntro} nextStep={navigateToParties} />
                </Box>
                <Box display={currentStep === "parties" ? undefined : "none"}>
                  <InvitedParties previousStep={navigateToDocs} nextStep={navigateToReview} />
                </Box>
                <Box display={currentStep === "review" ? undefined : "none"}>
                  <Review previousStep={navigateToParties} />
                </Box>
              </Box>
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
