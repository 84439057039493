import { Search2Icon } from "@chakra-ui/icons";
import { Flex, Input, InputGroup, InputLeftElement, Table, Tbody, Td, Text, Th, Thead, Tr } from "@chakra-ui/react";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { useLocation, useNavigate } from "react-router-dom";
import { useListOrganizationUsersQuery } from "../../../../autogen/bff-api";
import { Paging } from "../../../../common/Paging";
import { useConfirmationModal } from "../../../../common/useConfirmationModal";
import { displayPersonName } from "../../../contracts/view-single/sharing/AddExternalParticipantModal";
import { UserRow } from "./UserRow";
import { User } from "./types";

export const Users = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { t } = useTranslation();

  const confirmationModal = useConfirmationModal();

  const [filters, setFilters] = useState(() => {
    const params = new URLSearchParams(location.search);
    return {
      name: params.get("name") || "",
      skip: +(params.get("skip") || 0),
      limit: +(params.get("limit") || 10),
    };
  });

  useEffect(() => {
    const params = new URLSearchParams();
    Object.entries(filters).forEach(([key, value]) => {
      if (value || value === 0) params.set(key, `${value}`);
    });

    navigate(`${location.pathname}?${params.toString()}`, { replace: true });
  }, [filters, navigate, location.pathname]);

  const { data, isLoading } = useListOrganizationUsersQuery({
    name: filters.name,
    limit: filters.limit,
    skip: filters.skip,
  });

  const users: User[] = useMemo(
    () =>
      data?.users?.map((e) => ({
        id: e.person.id,
        name: displayPersonName(e.person),
        email: e.person.email,
        roles: e.roles,
      })) ?? [],
    [data]
  );

  return (
    <>
      {confirmationModal.modal}
      <Flex alignItems="center">
        <InputGroup mr="5px">
          <InputLeftElement pointerEvents="none">
            <Search2Icon color="smMuted" />
          </InputLeftElement>
          <Input
            type="text"
            placeholder={`${t("Search user by name or email")}...`}
            value={filters.name}
            onChange={(e) =>
              setFilters((prev) => ({
                ...prev,
                name: e.target.value,
                skip: 0,
              }))
            }
          />
        </InputGroup>
        <Text display={{ base: "none", sm: "block" }} fontSize="sm" color="smMuted" fontWeight="semibold" ml="-20">
          {data?.count} {t("users")}
        </Text>
      </Flex>
      <Paging
        limit={filters.limit}
        skip={filters.skip}
        setSkip={(skip) => setFilters((prev) => ({ ...prev, skip }))}
        isLoading={isLoading}
        count={data?.count}
      />
      <Flex flexDirection={"column"} maxWidth={"full"} width={"full"} position={"relative"}>
        <Flex border="1px solid" borderColor="smBorder" rounded="md" maxWidth="full" overflowX="auto">
          <Table variant="simple" mb="30px">
            <Thead>
              <Tr>
                <Th>{t("Name")}</Th>
                <Th>{t("Email")}</Th>
                <Th minW={"250px"}>{t("Role")}</Th>
                <Th></Th>
              </Tr>
            </Thead>
            <Tbody>
              {users.map((u) => {
                return <UserRow key={u.id} user={u} confirmationModal={confirmationModal} />;
              })}
              {!users.length && !isLoading && (
                <Tr>
                  <Td colSpan={4} width={"100%"} textAlign="center">
                    {t("No users have been added")}.
                  </Td>
                </Tr>
              )}
              {!users.length && isLoading && (
                <Tr>
                  <Td colSpan={4} width={"100%"} textAlign="center">
                    {t("Loading users")}...
                  </Td>
                </Tr>
              )}
            </Tbody>
          </Table>
        </Flex>
      </Flex>
    </>
  );
};
