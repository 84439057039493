import {
  Badge,
  Button,
  Flex,
  Heading,
  Icon,
  Menu,
  MenuButton,
  MenuItem,
  MenuList,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { useState } from "react";
import { FaBullhorn, FaChevronUp, FaComments, FaQuestion } from "react-icons/fa";
import { bffApi, BseSupplierViewDto } from "../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { setQuestions } from "../../../../common/redux/reducers/basicBidReducer";
import { Announcements } from "../../buyer/view-single/announcements/Announcements";
import { Questions } from "./questions/Questions";

export const EventDialog = ({ event }: { event: BseSupplierViewDto }) => {
  const dispatch = useAppDispatch();

  const [showDialog, setShowDialog] = useState(false);
  const [isSupplierQuestionsOpen, setIsSupplierQuestionsOpen] = useState(false);
  const [isAnnouncementsOpen, setIsAnnouncementsOpen] = useState(false);

  const unresolvedQuestionsCount = event.questions?.filter((e) => e.status === "Unresolved").length ?? 0;

  if (!event) return null;

  return (
    <>
      <Modal isOpen={isSupplierQuestionsOpen} onClose={() => setIsSupplierQuestionsOpen(false)} size="3xl">
        <ModalOverlay />
        <ModalContent width="full" height="80%" maxHeight="80%" padding="5" overflow="hidden">
          <ModalCloseButton />
          <ModalBody height="full">
            <Flex height="full" flexDirection="column">
              <Heading as="h2" size="md" pb="2">
                {t("My questions")}
              </Heading>
              <Questions
                data={event}
                addComment={async (comment: string, questionId: string) => {
                  const result = await dispatch(
                    bffApi.endpoints.createBseQuestionCommentAsSupplier.initiate({
                      eventId: event.id,
                      questionId: questionId,
                      createBseQuestionCommentRequest: {
                        content: comment,
                      },
                    })
                  ).unwrap();
                  dispatch(setQuestions(result.questions));
                }}
                createNewQuestion={async (values: {
                  subject: string;
                  content: string;
                  announcementId: string | null;
                }) =>
                  dispatch(
                    bffApi.endpoints.createBseQuestion.initiate({
                      eventId: event.id,
                      createBseQuestionRequest: {
                        subject: values.subject,
                        content: values.content,
                        announcementId: values.announcementId ?? undefined,
                      },
                    })
                  ).unwrap()
                }
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Modal isOpen={isAnnouncementsOpen} onClose={() => setIsAnnouncementsOpen(false)} size={"3xl"}>
        <ModalOverlay />
        <ModalContent width="full" height={"80%"} maxHeight={"80%"} padding="5" overflow={"hidden"}>
          <ModalCloseButton />
          <ModalBody height={"full"}>
            <Flex height={"full"} flexDirection={"column"}>
              <Heading as="h2" size="md" pb="2">
                {t("Announcements")}
              </Heading>
              <Announcements
                canCreateAnnouncement={false}
                announcements={event.announcements}
                initialAnnouncementValues={null}
              />
            </Flex>
          </ModalBody>
        </ModalContent>
      </Modal>
      <Flex flexDirection="column" position="fixed" bottom="0" left="0" zIndex="10">
        <Menu size="lg" colorScheme="cyan" onOpen={() => setShowDialog(true)} onClose={() => setShowDialog(false)}>
          <MenuButton
            as={Button}
            position="relative"
            size="lg"
            roundedBottom="0"
            roundedLeft="0"
            backgroundColor="smBackgroundSecondary"
            _hover={{ backgroundColor: "smBackgroundTertiary" }}
            leftIcon={<Icon as={FaComments} />}
            rightIcon={<Icon boxSize="3" as={FaChevronUp} />}
          >
            {t("Event dialog")}{" "}
            {unresolvedQuestionsCount > 0 && !showDialog && (
              <Badge position="absolute" top="-2" right="-2" colorScheme="red" variant="solid" rounded="md">
                {unresolvedQuestionsCount} {t("unresolved!")}
              </Badge>
            )}
          </MenuButton>
          <MenuList>
            <MenuItem icon={<Icon as={FaQuestion} />} onClick={() => setIsSupplierQuestionsOpen(true)}>
              <Flex alignItems="center" justifyContent="start">
                <Text>{t("My questions")}</Text>
                {unresolvedQuestionsCount > 0 && (
                  <Badge colorScheme="red" variant="solid" rounded="md" ml="3">
                    {unresolvedQuestionsCount} {t("unresolved!")}
                  </Badge>
                )}
              </Flex>
            </MenuItem>
            <MenuItem icon={<Icon as={FaBullhorn} />} onClick={() => setIsAnnouncementsOpen(true)}>
              {t("Announcements")}
            </MenuItem>
          </MenuList>
        </Menu>
      </Flex>
    </>
  );
};
