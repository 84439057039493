import { Box, Flex, Grid, GridItem, Text } from "@chakra-ui/react";
import { isNumber } from "lodash";
import { useTranslation } from "react-i18next";
import { displaySum } from "../../../../common/formatting/displaySum";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { ContractState } from "../../../../common/redux/reducers/contractReducer";
import { requireDataFields } from "../../typeguards";
import { Commercial } from "./Commercial";
import { Counterparty } from "./Counterparty";
import { CustomField } from "./CustomField";
import { Financial } from "./Financial";
import { GenericDetailRow } from "./GenericDetails";
import { Ifrs } from "./Ifrs";
import { InternalContractOwner } from "./InternalContractOwner";
import { InternalParties } from "./InternalParties";
import { KeyDates } from "./KeyDates";
import { LinkedContracts } from "./LinkedContracts";
import { Options } from "./Options";
import { OriginSourcingEvent } from "./OriginSourcingEvent";
import { Other } from "./Other";
import { Projects } from "./Projects";
import { TerminationDate } from "./TerminationDate";
import { TermsAndConditions } from "./TermsAndConditions";
import { CustomCounterpartyContactPerson } from "./contact-person/CustomCounterpartyContactPerson";
import { NoCounterpartyContactPersonProvided } from "./contact-person/NoCounterpartyContactPerson";
import { SelectedCounterpartyContactPerson } from "./contact-person/SelectedCounterpartyContactPerson";

interface Props {
  data: ContractState["contract"];
}

export const Details = ({ data }: Props) => {
  const { t } = useTranslation();
  const dataFields = requireDataFields(data);
  const estimatedValue = dataFields.estimatedValue;

  const addedDataFields = dataFields.addedDataFields.map((e) => e.name);
  const commercialDetailRows: GenericDetailRow[] = [];
  if (isNumber(estimatedValue)) {
    commercialDetailRows.push({
      type: "Data",

      name: t("Estimated contract value"),
      value: (
        <Text>
          {displaySum({
            value: estimatedValue,
            currency: data.currency,
          })}
        </Text>
      ),
    });
  }

  return (
    <Grid
      height="100%"
      gap={3}
      gridTemplateColumns={`2fr 1fr`}
      gridTemplateRows="1fr"
      templateAreas={`
                "content sidebar"
                `}
      paddingBottom="90px"
    >
      <GridItem area="content">
        {data.description && (
          <Flex border="1px solid" borderColor="smBorder" rounded="lg" flexDirection="column" p="4">
            <Text fontWeight="bold">{t("Description")}</Text>
            <TipTap content={data.description} editable={false} />
          </Flex>
        )}
        <Flex border="1px solid" borderColor="smBorder" rounded="lg" flexDirection="column" mt="4">
          <Financial data={data} />
        </Flex>
        <Flex border="1px solid" borderColor="smBorder" rounded="lg" flexDirection="column" mt="4">
          <Commercial data={data} />
        </Flex>
        <Flex border="1px solid" borderColor="smBorder" rounded="lg" flexDirection="column" mt="4">
          <TermsAndConditions data={data} />
        </Flex>
        <Flex border="1px solid" borderColor="smBorder" rounded="lg" flexDirection="column" mt="4">
          <Other data={data} />
        </Flex>
        <Flex border="1px solid" borderColor="smBorder" rounded="lg" flexDirection="column" mt="4">
          <Options data={data} />
        </Flex>
        {addedDataFields.includes("IfrsValues") && (
          <Flex border="1px solid" borderColor="smBorder" rounded="lg" flexDirection="column" mt="4">
            <Ifrs data={data} />
          </Flex>
        )}
        {Object.values(dataFields.customFields ?? {}).map((section) => (
          <Box key={section.id} border="1px solid" borderColor="smBorder" mt="4" rounded="lg" p="5">
            <Text fontWeight="bold" pb="5">
              {section.name}
            </Text>
            {section.fields.map((field) => (
              <CustomField key={field.id} field={field} />
            ))}
          </Box>
        ))}
      </GridItem>
      <GridItem area="sidebar">
        {dataFields.terminationDate && (
          <Flex
            border="1px solid"
            borderColor="smBorder"
            borderRadius="10px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
            mb="10px"
            bg="red.100"
          >
            <TerminationDate data={dataFields.terminationDate} />
          </Flex>
        )}
        <Flex
          border="1px solid"
          borderColor="smBorder"
          borderRadius="10px"
          boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
        >
          <KeyDates data={data} />
        </Flex>
        <Flex
          border="1px solid"
          borderColor="smBorder"
          borderRadius="10px"
          mt="10px"
          boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
        >
          <Counterparty data={data} />
        </Flex>
        {data.dataFields?.counterpartyContactPerson && (
          <Flex
            border="1px solid"
            borderColor="smBorder"
            borderRadius="10px"
            mt="10px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
          >
            <CustomCounterpartyContactPerson contactPerson={data.dataFields?.counterpartyContactPerson} />
          </Flex>
        )}

        {data.dataFields?.counterpartyContactPerson?.person && (
          <Flex
            border="1px solid"
            borderColor="smBorder"
            borderRadius="10px"
            mt="10px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
          >
            <SelectedCounterpartyContactPerson contactPerson={data.dataFields?.counterpartyContactPerson?.person} />
          </Flex>
        )}

        {data.dataFields?.internalParties?.parties && data.dataFields?.internalParties?.parties.length > 0 && (
          <Flex
            border="1px solid"
            borderColor="smBorder"
            borderRadius="10px"
            mt="10px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
          >
            <InternalParties data={data} />
          </Flex>
        )}

        {addedDataFields.includes("CounterpartyContactPerson") &&
          !data.dataFields?.counterpartyContactPerson?.fullName &&
          !data.dataFields?.counterpartyContactPerson?.person && (
            <Flex
              border="1px solid"
              borderColor="smBorder"
              borderRadius="10px"
              mt="10px"
              boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
            >
              <NoCounterpartyContactPersonProvided />
            </Flex>
          )}
        <Flex
          border="1px solid"
          borderColor="smBorder"
          borderRadius="10px"
          mt="10px"
          boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
        >
          <InternalContractOwner person={data.internalOwner} />
        </Flex>
        {(addedDataFields.includes("OriginSourcingEvent") || data.dataFields?.originSourcingEvent) && (
          <Flex
            border="1px solid"
            borderColor="smBorder"
            borderRadius="10px"
            mt="10px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
          >
            <OriginSourcingEvent data={data} />
          </Flex>
        )}
        {(addedDataFields.includes("LinkedContracts") || data.dataFields?.linkedContracts) && (
          <Flex
            border="1px solid"
            borderColor="smBorder"
            borderRadius="10px"
            mt="10px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
          >
            <LinkedContracts data={data} />
          </Flex>
        )}
        {data.dataFields.projects && (
          <Flex
            border="1px solid"
            borderColor="smBorder"
            borderRadius="10px"
            mt="10px"
            boxShadow="0 1px 15px rgba(71, 85, 105, 0.16)"
          >
            <Projects projects={data.dataFields.projects} />
          </Flex>
        )}
      </GridItem>
    </Grid>
  );
};
