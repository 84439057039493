import { Box, Flex, Icon, Text } from "@chakra-ui/react";
import { Fragment, useEffect, useState } from "react";
import { FaCheck } from "react-icons/fa";

export const ProcessBar = ({
  steps,
  currentStep,
  loopTo,
}: {
  steps: string[];
  currentStep: string;
  loopTo?: { step: string; description: string }[];
}) => {
  const [currentIndex, setCurrentIndex] = useState<number>(0);

  useEffect(() => {
    setCurrentIndex(steps.findIndex((s) => s === currentStep));
  }, [steps, currentStep]);

  return (
    <Flex pt={{ base: "5", md: "10" }} pb="16" color="smPrimary">
      {steps.map((step, i) => (
        <Fragment key={step}>
          <Flex flexDirection="column" width="6" height="6">
            <Flex
              border="2px solid"
              borderColor={i < currentIndex ? "teal.200" : "blue.100"}
              width="6"
              height="6"
              minHeight="6"
              rounded="full"
              alignItems="center"
              justifyContent="center"
              fontWeight="bold"
            >
              {i < currentIndex && <Icon height="3" as={FaCheck} />}
              {i === currentIndex && (
                <Flex width="3" height="3" minHeight="3" rounded="full" backgroundColor="blue.200"></Flex>
              )}
            </Flex>
            <Flex
              color={i < currentIndex ? "smPrimary" : "blue.300"}
              fontSize="small"
              fontWeight="bold"
              overflow="visible"
              justifyContent={i === 0 ? "start" : i === steps.length - 1 ? "end" : "center"}
              whiteSpace={{ base: "normal", md: "nowrap" }}
            >
              {step}
            </Flex>
          </Flex>
          {i !== steps.length - 1 && (
            <Flex position="relative" grow={1} justifyContent="center" alignItems="center">
              <Box
                width="full"
                height="0.125rem"
                bgGradient={`linear(to-r, ${i < currentIndex ? "teal.200" : "blue.100"}, ${
                  i < currentIndex - 1 ? "teal.200" : "blue.100"
                })`}
              ></Box>
              {loopTo
                ?.filter((loop) => loop.step === steps[i])
                ?.map((loop) => (
                  <Flex
                    key={`${loop.description}`}
                    position="absolute"
                    width="full"
                    height="16"
                    backgroundColor="transparent"
                    justifyContent="center"
                  >
                    <Box
                      position="absolute"
                      zIndex="-1"
                      width="100%"
                      height="100%"
                      borderTop="2px solid"
                      borderTopLeftRadius="50%"
                      borderTopRightRadius="50%"
                      borderColor="blue.100"
                      backgroundColor="transparent"
                    />
                    <Box
                      position="absolute"
                      left="0"
                      top="18px"
                      transform="translate(0, 50%) rotate(0deg)"
                      width="0"
                      height="0"
                      borderLeft="6px solid"
                      borderLeftColor="blue.100"
                      borderTop="6px solid transparent"
                      borderRight="6px solid transparent"
                    />
                    <Flex alignItems="center" position="absolute" top="-2.5">
                      <Text fontSize="small" fontWeight="bold" color="blue.300" backgroundColor="smBackground" p="1">
                        {loop.description}
                      </Text>
                    </Flex>
                  </Flex>
                ))}
            </Flex>
          )}
        </Fragment>
      ))}
    </Flex>
  );
};
