import { useEffect } from "react";
import { useTranslation } from "react-i18next";
import { useParams } from "react-router-dom";
import { Layout } from "../../../common/layout/Layout";

import { useAppDispatch, useAppSelector } from "../../../common/redux/hooks";
import { resetState } from "../../../common/redux/reducers/basicBidReducer";
import { loadBasicBidThunk } from "../../../common/redux/thunks/basic-bid/load-basic-bid-thunk";
import { EventDialog } from "../../sourcing-events/supplier/view-single/EventDialog";
import { EditBasicBidForm } from "./EditBasicBidForm";

export const EditBasicBid = () => {
  const { bidId } = useParams();
  const dispatch = useAppDispatch();
  const bidState = useAppSelector((state) => state.basicBid.bid);
  const { t } = useTranslation();

  useEffect(() => {
    if (bidId && bidState?.id !== bidId) {
      dispatch(loadBasicBidThunk({ bidId }));
    }
  }, [bidId, bidState?.id, dispatch]);

  useEffect(() => {
    return () => {
      dispatch(resetState());
    };
  }, [dispatch]);

  if (bidState) {
    return (
      <Layout>
        <EditBasicBidForm />
        <EventDialog event={bidState.forEvent} />
      </Layout>
    );
  }
  return <div>{t("Loading")}...</div>;
};
