import { Box, Button, Flex, Icon, Skeleton, Tab, TabList, TabPanel, TabPanels, Tabs, Text } from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { useEffect, useMemo, useState } from "react";
import { useTranslation } from "react-i18next";
import { FaAward, FaDollarSign, FaHandshake, FaInfo, FaSearch } from "react-icons/fa";
import { useNavigate, useParams } from "react-router-dom";
import {
  BseDto,
  BseStatusDto,
  useGetBasicSourcingEventQuery,
  useListTextDocumentsForSourcingEventQuery,
} from "../../../../autogen/bff-api";
import { ProcessBar } from "../../../../common/ProcessBar";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { isFetchBaseQueryError } from "../../../../common/errors/isFetchBaseQueryError";
import { useApiError } from "../../../../common/errors/useApiError";
import { Layout } from "../../../../common/layout/Layout";
import { useAppDispatch, useAppSelector } from "../../../../common/redux/hooks";
import { BasicSourcingEventState, setSourcingEvent } from "../../../../common/redux/reducers/basicSourcingEventReducer";
import { useSub } from "../../../../common/subscription/useSub";
import { BseBuyerTab, useQueryParams } from "../../../../common/useQueryParams";
import { CONTENT_WIDTH, requireBooleanEnvVar } from "../../../../config";
import { urls } from "../../../../urls";
import { requirePublishedOrAwardedState } from "../../requirePublishedOrAwardedState";
import { Award } from "./Award";
import { Bids } from "./Bids";
import { CreateContracts } from "./CreateContracts";
import { Documents } from "./Documents";
import { EventDialog } from "./EventDialog";
import { EventMeta } from "./EventMeta";
import { Intro } from "./Intro";
import { BidEvalutation } from "./bid-evaluation/BidEvaluation";

const tabs = ["request", "bids", "evaluation", "award", "contract"].filter((tab) => !!tab) as BseBuyerTab[];

const getCurrentStep = (state: BasicSourcingEventState, stateToStepMap: { [state in BseStatusDto]: string }) =>
  stateToStepMap[state.event.status];

const requireDescription = (bse: BseDto): string => {
  const fields = requirePublishedOrAwardedState(bse);
  return fields.description;
};

export const BseView = () => {
  const { t } = useTranslation();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();

  const authState = useLoggedInWithOrgContextState();
  const params = useQueryParams();
  const tab = params.get("tab");
  const subscriptions = useSub();
  const displayer = useApiError();
  const eventState = useAppSelector((state) => state.basicSourcingEvent.state);
  const { eventId } = useParams();

  const { data: event, error, isLoading } = useGetBasicSourcingEventQuery(eventId ? { eventId } : skipToken);
  const { data: textDocRes, isLoading: isLoadingTextDocuments } = useListTextDocumentsForSourcingEventQuery(
    eventId ? { eventId } : skipToken
  );

  const combinedDocs = useMemo(() => {
    return [...(event?.documents ?? []), ...(textDocRes?.documents ?? [])];
  }, [event?.documents, textDocRes?.documents]);

  const [tabIndex, setTabIndex] = useState(0);

  useEffect(() => {
    if (eventState?.id !== eventId) {
      if (event) dispatch(setSourcingEvent(event));
      else dispatch(setSourcingEvent(null));
    }
  }, [event, dispatch, eventState, eventId]);

  const stateToStepMap = useMemo(() => {
    const stateToStepMap: { [state in BseStatusDto]: string } = {
      Draft: t("Create event"),
      Published: t("Receive bids"),
      DeadlineHasPassed: subscriptions.hasProductAccess ? t("Evaluate bids") : t("Award winners"),
      Completed: t("Award winners"),
      Awarded: t("Event completed"),
    };
    return stateToStepMap;
  }, [subscriptions.hasProductAccess, t]);

  useEffect(() => {
    const index = tabs.indexOf(tab as BseBuyerTab);
    if (tab && index > -1) setTabIndex(index);
  }, [tab]);

  useEffect(() => {
    return () => {
      dispatch(setSourcingEvent(null));
    };
  }, [dispatch]);

  if (error) {
    if (isFetchBaseQueryError(error) && error.status === 403) return displayer.showNoAccess();
    return displayer.show(error);
  }

  return (
    <>
      {event && <EventDialog event={event} />}
      <Layout>
        <Flex width="full" justifyContent="center" px="5">
          <Flex flexDirection="column" height="full" width={`${CONTENT_WIDTH}`} maxWidth={`${CONTENT_WIDTH}`}>
            <Flex flexDirection="column" justifyContent="center">
              {eventState && eventId === eventState?.id && (
                <>
                  <ProcessBar
                    steps={Array.from(new Set(Object.values(stateToStepMap)))}
                    currentStep={getCurrentStep(eventState, stateToStepMap)}
                    loopTo={
                      requireBooleanEnvVar("VITE_ENABLE_NEGOTIATIONS")
                        ? [{ step: stateToStepMap.Published, description: t("Negotiation") }]
                        : undefined
                    }
                  />
                  <EventMeta event={eventState.event} />
                  <Flex flexDirection="column">
                    <Tabs
                      colorScheme="teal"
                      width="full"
                      index={tabIndex}
                      onChange={(index) => {
                        setTabIndex(index);
                        params.setSelectedBseTab(indexToQueryValue(index));
                      }}
                      variant="line"
                      mx="0"
                      pt="8"
                    >
                      <TabList justifyContent="center" mx="2">
                        <Tab>
                          <Icon as={FaSearch} mr="1" />
                          {t("Request")}
                        </Tab>
                        <Tab>
                          <Icon as={FaInfo} mr="1" />
                          {t("Bids")}
                        </Tab>
                        {subscriptions.hasProductAccess && (
                          <Tab
                            isDisabled={
                              !authState.selectedOrg.canSeeDeliveredBidsBeforeDeadline &&
                              (eventState.event.status === "Draft" || eventState.event.status === "Published")
                            }
                          >
                            <Icon as={FaDollarSign} mr="1" />
                            {t("Evaluation")}
                          </Tab>
                        )}
                        <Tab
                          isDisabled={eventState.event.status === "Draft" || eventState.event.status === "Published"}
                        >
                          <Icon as={FaAward} mr="1" />
                          {t("Award")}
                        </Tab>
                        <Tab isDisabled={eventState.event.status !== "Awarded"}>
                          <Icon as={FaHandshake} mr="1" />
                          {t("Contract")}
                        </Tab>
                      </TabList>
                      <TabPanels>
                        <TabPanel>
                          <Flex>
                            <Box width="50%" pt="5">
                              <Intro description={event ? requireDescription(event) : ""} />
                            </Box>
                            <Flex width="50%" flexDirection="column" alignItems="end" py="5">
                              {subscriptions.hasProjectAccess && (
                                <Flex flexDirection="column" alignItems="end" fontWeight="semibold">
                                  <Text fontSize="sm" pb="2">
                                    {t("Projects")}
                                  </Text>
                                  <Flex flexDirection="column" rowGap={1}>
                                    {event?.projects?.map((p) => (
                                      <Button
                                        key={p.id}
                                        size="xs"
                                        justifyContent="end"
                                        onClick={() => navigate(urls.projects.view.go(p.id))}
                                      >{`${p.externalId ? `${p.externalId} - ` : ""}${p.name}`}</Button>
                                    ))}
                                  </Flex>
                                </Flex>
                              )}
                            </Flex>
                          </Flex>
                          <Flex rounded="md" flexDirection="column" pt="8">
                            <Text fontSize="sm" fontWeight="semibold" pb="2">
                              {t("Documents")}
                            </Text>
                            {!isLoadingTextDocuments && <Documents documents={combinedDocs} />}
                          </Flex>
                        </TabPanel>
                        <TabPanel>
                          <Bids data={eventState.event} />
                        </TabPanel>
                        {subscriptions.hasProductAccess && (
                          <TabPanel>
                            <BidEvalutation sourcingEvent={eventState.event} />
                          </TabPanel>
                        )}
                        <TabPanel>
                          <Award sourcingEvent={eventState.event} />
                        </TabPanel>
                        <TabPanel>
                          <CreateContracts sourcingEvent={eventState.event} />
                        </TabPanel>
                      </TabPanels>
                    </Tabs>
                  </Flex>
                </>
              )}
              {isLoading && (
                <Flex flexDirection="column" justifyContent="center">
                  <ProcessBar steps={Array.from(new Set(Object.values(stateToStepMap)))} currentStep="" />
                  <Flex width="full" flexDirection="column" alignItems="center" px="2" py="10">
                    <Flex flexDirection="column" rowGap="2" justifyContent="space-between" alignItems="center">
                      <Skeleton
                        width="60"
                        height="6"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="48"
                        height="6"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="80"
                        height="4"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                  </Flex>
                  <Flex flexDirection="column" px="2" pt="8">
                    <Flex width="full" columnGap="2" justifyContent="center" pt="2" pb="12">
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="32"
                        height="8"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                    <Flex flexDirection="column" rowGap="4" pt="2">
                      <Skeleton
                        width="50%"
                        height="40"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                      <Skeleton
                        width="full"
                        height="40"
                        rounded="md"
                        startColor="smBackgroundSecondary"
                        endColor="smBackgroundTertiary"
                      />
                    </Flex>
                  </Flex>
                </Flex>
              )}
            </Flex>
          </Flex>
        </Flex>
      </Layout>
    </>
  );
};

const indexToQueryValue = (index: number): BseBuyerTab => {
  return tabs[index];
};
