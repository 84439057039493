import { Box } from "@chakra-ui/react";
import {
  GroupBase,
  InputActionMeta,
  MenuListProps,
  OptionProps,
  Select,
  SingleValue,
  useChakraSelectProps,
} from "chakra-react-select";
import { SizeProp } from "chakra-react-select/dist/types/types";
import { ComponentType, useEffect, useState } from "react";
import { useTranslation } from "react-i18next";
import { SelectorValue } from "./SelectorValue";

interface Props<T = Record<string, unknown>> {
  value?: SelectorValue<T>;
  options: ({ label: string; value: string } & T)[];
  onChange: (value: SingleValue<SelectorValue<T>>) => void;
  inputValue?: string;
  onInputChange?: ((newValue: string, actionMeta: InputActionMeta) => void) | undefined;
  placeholder?: {
    text: string;
    color: string;
  };
  noOptionsProvidedMessage?: string;
  noMatchingOptionsMessage?: string;
  isClearable?: boolean;
  size?: SizeProp;
  isLoading?: boolean;
  searchValues?: boolean;
  onBlur?: () => void;
  autoFocus?: boolean;
  isDisabled?: boolean;
  CustomOption?: ComponentType<OptionProps<SelectorValue<T>, false, GroupBase<SelectorValue<T>>>> | undefined;
  CustomMenuList?: ComponentType<MenuListProps<SelectorValue<T>, false, GroupBase<SelectorValue<T>>>> | undefined;
}

export const SingleSelector = <T extends Record<string, unknown>>({
  value,
  options,
  onChange,
  inputValue,
  onInputChange,
  placeholder,
  noOptionsProvidedMessage,
  noMatchingOptionsMessage,
  isClearable,
  size,
  isLoading,
  searchValues = true,
  onBlur,
  autoFocus = false,
  isDisabled,
  CustomOption,
  CustomMenuList,
}: Props<T>) => {
  const [selectedValue, setSelectedValue] = useState<SelectorValue<T> | null>();
  const { t } = useTranslation();

  useEffect(() => {
    setSelectedValue(value);
  }, [value]);

  const optionsMessage = noOptionsProvidedMessage ? noOptionsProvidedMessage : t("No options provided");
  const matchingOptionsMessage = noMatchingOptionsMessage ? noMatchingOptionsMessage : t("No matching elements found");

  return (
    <Select<SelectorValue<T>>
      {...useChakraSelectProps({
        size: size ?? "md",
        isMulti: false,
        value: selectedValue ?? null,
        onChange: (option) => {
          setSelectedValue(option);
          onChange(option);
        },
        isClearable: isClearable ?? false,
        selectedOptionStyle: "check",
        placeholder: placeholder ? <Box color={placeholder.color}>{placeholder.text}</Box> : <Box></Box>,
        noOptionsMessage: (inputValue) => (
          <Box>{inputValue.inputValue.trim().length === 0 ? optionsMessage : matchingOptionsMessage}</Box>
        ),
      })}
      components={{
        ...(CustomOption ? { Option: CustomOption } : {}),
        ...(CustomMenuList ? { MenuList: CustomMenuList } : {}),
      }}
      inputValue={inputValue}
      onInputChange={onInputChange}
      options={options}
      filterOption={customFilterOption({ disableFrontendFiltering: !!onInputChange, searchLabelsOnly: !searchValues })}
      isLoading={isLoading}
      chakraStyles={{
        control: (provided) => ({
          ...provided,
          backgroundColor: "smBackground",
          rounded: "md",
        }),
        container: (provided) => ({
          ...provided,
          width: "full",
        }),
        menuList: (provided) => ({
          ...provided,
          rounded: "md",
          maxHeight: "none",
        }),
      }}
      menuPortalTarget={document.body}
      styles={{ menuPortal: (base) => ({ ...base, zIndex: 9999 }) }}
      onBlur={onBlur}
      autoFocus={autoFocus}
      isDisabled={isDisabled}
    />
  );
};

const customFilterOption = ({
  disableFrontendFiltering,
  searchLabelsOnly,
}: {
  disableFrontendFiltering: boolean;
  searchLabelsOnly: boolean;
}) => {
  if (disableFrontendFiltering) return () => true;
  if (searchLabelsOnly) return filterLabels;
  return undefined;
};

const filterLabels = (option: { label: string; value: string }, inputValue: string) => {
  return option.label.toLowerCase().includes(inputValue.toLowerCase());
};
