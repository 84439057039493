import { Box, Button, Collapse, Flex, Text } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { TipTap } from "../../../../common/input/TipTap/TipTap";

interface Props {
  description: string;
}

export const Intro = ({ description }: Props) => {
  const [showFullDescription, setShowFullDescription] = useState(false);
  const { t } = useTranslation();

  return (
    <Flex width="full" flex={3} alignItems="start" flexDirection="column">
      <Text fontSize="sm" fontWeight="semibold" pb="2">
        {t("Description")}
      </Text>
      <Box width="full" shadow="inner" rounded="lg" pt="0" px="4" pb="2">
        <Collapse startingHeight="8rem" in={showFullDescription}>
          <TipTap content={description} editable={false} hasBorder={false} maxHeight="" padding="0" />
        </Collapse>
        <Button size="sm" variant={"link"} mt="5px" onClick={() => setShowFullDescription(!showFullDescription)}>
          {!showFullDescription ? t("Show more") : t("Show less")}
        </Button>
      </Box>
    </Flex>
  );
};
