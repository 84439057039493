import { Button, Flex, FormControl, FormLabel, Icon, Spinner, Text } from "@chakra-ui/react";
import AwsS3 from "@uppy/aws-s3";
import Uppy from "@uppy/core";
import "@uppy/core/dist/style.css";
import "@uppy/drag-drop/dist/style.css";
import ProgressBar from "@uppy/progress-bar";
import "@uppy/progress-bar/dist/style.css";
import { DragDrop } from "@uppy/react";
import { t } from "i18next";
import { isFinite } from "lodash";
import moment from "moment";
import { useCallback, useEffect, useMemo, useState } from "react";
import { FaCheckCircle, FaTimesCircle } from "react-icons/fa";
import { useNavigate } from "react-router-dom";
import { v4 as uuid } from "uuid";
import {
  ContractDto,
  ContractTemplateDto,
  ProjectDto,
  bffApi,
  useCreateContractMutation,
  useCreateUploadForContractMutation,
  useStartDocumentChatMutation,
  useUpdateContractMutation,
} from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { useOrganizationSearch } from "../../../common/organization/useOrganizationSearch";
import { getPusher } from "../../../common/pusher";
import { useAppDispatch } from "../../../common/redux/hooks";
import { urls } from "../../../urls";

enum CreateContractWithAIState {
  Idle,
  IsInitializingContract,
  ContractInitialized,
  UploadCreated,
  UploadScanned,
  DocumentCreated,
  IsAnalyzingDocument,
  DocumentAnalyzed,
  ContractCreated,
}

const createPrompt = (templates: ContractTemplateDto[], loggedInOrg: string) => `
  Based on the given PDF Document text, populate the values in the following json:

  {
    "title": null, // Should be short but descriptive
    "description": null, // Should be short but descriptive. 1-2 paragraphs.
    "templateId": null, // Should be one of the UUIDs in the list below. Select the most appropriate template. Should be null if no template fits. Must be a valid UUID or null!!!
    "startDate": null, // Should be formatted as yyyy-MM-dd. Should be null if there is no start date.
    "endDate": null, // Should be formatted as yyyy-MM-dd. Should be null if there is no end date.
    "paymentTerms": number; // Should be the payment terms in number of days. Should be null or an integer.
    "noticePeriod": number; // Should be the notice period in number of months. Should be null or an integer.
    "counterparty": {
      companyName: string;
      fullName?: string;
      email?: string;
    },
    "documentPages": number; // Number of pages in the text
  }

  The current user is logged in on behalf of ${loggedInOrg}

  The templateId should be one of the UUIDs in the following list:
  ${templates.map((t) => `${JSON.stringify({ uuid: t.id, name: t.name, description: t.description })}, `)}

  It is very important that you return a plain, valid json of the provided format only.
`;

export const CreateContractWithAIForm = ({
  templates,
  project,
}: {
  templates: ContractTemplateDto[];
  project?: ProjectDto;
}) => {
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const navigate = useNavigate();
  const errorDisplayer = useApiError();

  const [createContractState, setCreateContractState] = useState<CreateContractWithAIState>(
    CreateContractWithAIState.Idle
  );
  const [createContract] = useCreateContractMutation();
  const [createUpload] = useCreateUploadForContractMutation();
  const [analyzeDocument, { error: analyzeDocumentError }] = useStartDocumentChatMutation();
  const [updateContract] = useUpdateContractMutation();

  // TODO: Avoid listing all orgs
  const { organizationCombinations } = useOrganizationSearch({ limit: 10_000 });

  const [target, setTarget] = useState<string>();
  const [pagesAnalyzed, setPagesAnalyzed] = useState<number>();
  const [contract, setContract] = useState<ContractDto>();

  useEffect(() => {
    setTarget("#dnd");
  }, []);

  const documentAddedHandler = useCallback(async () => {
    if (!contract?.id) return;
    setCreateContractState(CreateContractWithAIState.UploadScanned);
    const contractWithDocument = await dispatch(
      bffApi.endpoints.getContract.initiate({
        contractId: contract.id,
      })
    ).unwrap();
    if (contractWithDocument.documentFields?.documents[0]) {
      setContract(contractWithDocument);
      setCreateContractState(CreateContractWithAIState.DocumentCreated);
    } else {
      const e = Error("Illegal state - no documents when handling added document");
      errorDisplayer.trigger(e);
      throw e;
    }
  }, [contract?.id, dispatch, errorDisplayer]);

  useEffect(() => {
    const channel = contract?.id ? getPusher().subscribe(contract.id) : undefined;
    channel?.bind("document-added", documentAddedHandler);

    return () => {
      channel?.unbind("document-added", documentAddedHandler);
    };
  }, [documentAddedHandler, contract?.id]);

  useEffect(() => {
    if (createContractState === CreateContractWithAIState.DocumentCreated) {
      const document = contract?.documentFields?.documents[0];
      if (!document) {
        const e = Error("Illegal state - no documents before analyzing documents");
        errorDisplayer.trigger(e);
        throw e;
      }
      setCreateContractState(CreateContractWithAIState.IsAnalyzingDocument);
      analyzeDocument({
        docId: document.id,
        chatCompletionRequest: {
          model: "gpt-4o",
          messages: [
            {
              role: "system",
              content: createPrompt(templates, authState.selectedOrg.name),
            },
          ],
          temperature: 0.7,
        },
      })
        .unwrap()
        .then(async (res) => {
          const analysis = res.messages[res.messages.length - 1].content;
          if (!analysis) throw Error("Could not analyze document");
          const data = JSON.parse(analysis.replace(/```json\s*|\s*```/g, ""));
          setCreateContractState(CreateContractWithAIState.DocumentAnalyzed);
          if (data.documentPages) setPagesAnalyzed(data.documentPages);
          else throw Error("Failed to analyze document.");
          const counterparty = organizationCombinations
            ?.filter((o) => o.id !== authState.selectedOrg.id)
            ?.find((o) => o.name.toLowerCase() === data.counterparty?.companyName?.toLowerCase());
          setContract((c) =>
            c
              ? {
                  ...c,
                  title: data.title,
                  description: data.description,
                  template: templates.find((t) => t.id === data.templateId),
                  counterparty: {
                    organizationEntry: {
                      id: "",
                      name: data.counterparty.companyName,
                      country: {
                        id: "",
                        alpha3Code: "",
                        name: "",
                      },
                      industries: [],
                      organizationNumber: "",
                    },
                  },
                  dataFields: {
                    startDate: data.startDate,
                    endDate: {
                      date: data.endDate,
                      hasNoEndDate: false,
                    },
                    counterpartyContactPerson: {
                      fullName: data.counterparty.fullName,
                      email: data.counterparty.email,
                    },
                    paymentTermsInDays: data.paymentTerms,
                    noticePeriod: data.noticePeriod,
                    addedDataFields: [],
                    requiredDataFields: [],
                    missingDataFields: [],
                  },
                }
              : undefined
          );
          await updateContract({
            contractId: contract.id,
            updateContractRequest: {
              title: data.title,
              description: data.description,
              template: {
                templateId: data.templateId,
              },
              startDate: data.startDate
                ? {
                    date: data.startDate,
                  }
                : undefined,
              endDate: data.endDate
                ? {
                    date: data.endDate,
                    hasNoEndDate: false,
                  }
                : undefined,
              counterpartyContactPerson: data.counterparty
                ? {
                    fullName: data.counterparty.fullName,
                    email: data.counterparty.email,
                  }
                : undefined,
              paymentTermsInDays: isFinite(data.paymentTerms)
                ? {
                    days: data.paymentTerms,
                  }
                : undefined,
              editNoticePeriod: data.noticePeriod
                ? {
                    duration: data.noticePeriod,
                  }
                : undefined,
              counterparty: counterparty
                ? {
                    counterparty: {
                      organizationEntryId: counterparty?.type === "OrganizationEntry" ? counterparty.id : undefined,
                      organizationId: counterparty?.type === "Organization" ? counterparty.id : undefined,
                    },
                  }
                : undefined,
            },
          })
            .unwrap()
            .catch((e) => {
              errorDisplayer.trigger(e);
            });
          setCreateContractState(CreateContractWithAIState.ContractCreated);
        })
        .catch((e) => {
          errorDisplayer.trigger(e);
        });
    }
  }, [
    analyzeDocument,
    authState.selectedOrg.id,
    authState.selectedOrg.name,
    contract,
    createContractState,
    dispatch,
    errorDisplayer,
    organizationCombinations,
    templates,
    updateContract,
  ]);

  const { uppy } = useMemo(() => {
    const uppy = new Uppy({
      id: "AwsS3",
      autoProceed: true,
      onBeforeFileAdded(currentFile) {
        currentFile["id"] = uuid();
        return currentFile;
      },
    })
      .use(ProgressBar, { target })
      .use(AwsS3, {
        id: "AwsS3",
        limit: 1,
        async getUploadParameters(file) {
          if (!isFile(file.data)) throw Error("Only files, not blobs, are supported.");
          setCreateContractState(CreateContractWithAIState.IsInitializingContract);
          const initialContract = await createContract({
            depId: authState.selectedOrg.departments[0].id,
            createContractRequest: {
              title: "",
              timezone: moment.tz.guess(),
              projects: project ? [project.id] : undefined,
            },
          })
            .unwrap()
            .catch((e) => {
              errorDisplayer.trigger(e);
              throw e;
            });
          setCreateContractState(CreateContractWithAIState.ContractInitialized);
          setContract(initialContract);
          const upload = await createUpload({
            contractId: initialContract.id,
            uploadId: file.id,
            createUploadRequestDto: {
              fileName: file.name,
              fileSize: file.size,
              fileExtension: file.extension,
              mimeType: file.data.type,
            },
          })
            .unwrap()
            .catch((e) => {
              errorDisplayer.trigger(e);
              throw e;
            });
          setContract({
            ...initialContract,
            editingMetaFields: {
              uploads: [upload],
            },
          });
          setCreateContractState(CreateContractWithAIState.UploadCreated);
          return {
            url: upload.url,
            method: "POST",
            fields: {
              "x-amz-date": upload.renamedXAmzDate,
              "x-amz-signature": upload.renamedXAmzSignature,
              "x-amz-algorithm": upload.renamedXAmzAlgorithm,
              "x-amz-credential": upload.renamedXAmzCredential,
              policy: upload.policy,
              key: upload.key,
            },
          };
        },
      });

    return { uppy };
  }, [authState.selectedOrg.departments, createContract, createUpload, errorDisplayer, project, target]);

  return (
    <Flex flexDirection={"column"}>
      <FormControl pt="4" display={createContractState === CreateContractWithAIState.Idle ? "block" : "none"}>
        <FormLabel>{t("Add contract document")}</FormLabel>
        <div id="dnd">
          <DragDrop uppy={uppy} />
        </div>
      </FormControl>
      {createContractState === CreateContractWithAIState.IsInitializingContract && (
        <Flex alignItems={"center"} pt="4">
          <Spinner size="xs" thickness="2px" color="blue.200" mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Creating contract")}...
          </Text>
        </Flex>
      )}
      {createContractState >= CreateContractWithAIState.ContractInitialized && (
        <Flex alignItems={"center"} pt="4">
          <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Contract created")}
          </Text>
        </Flex>
      )}
      {createContractState === CreateContractWithAIState.UploadCreated && (
        <Flex alignItems={"center"}>
          <Spinner size="xs" thickness="2px" color="blue.200" mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Scanning document")}...
          </Text>
        </Flex>
      )}
      {createContractState >= CreateContractWithAIState.UploadScanned && (
        <Flex alignItems={"center"}>
          <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          <Text fontSize={"sm"} color="smMuted">
            {t("Document virus scan complete")}
          </Text>
        </Flex>
      )}
      {createContractState === CreateContractWithAIState.IsAnalyzingDocument && (
        <Flex alignItems={"center"}>
          {analyzeDocumentError ? (
            <>
              <Icon as={FaTimesCircle} color="red.400" mr="2" />
              <Text fontSize={"sm"} color="red.400">
                {t("Neural net analysis failed")}...
              </Text>
            </>
          ) : (
            <>
              <Spinner size="xs" thickness="2px" color="blue.200" mr="2" />
              <Text fontSize={"sm"} color="smMuted">
                {t("Running deep neural net analysis")}...
              </Text>
            </>
          )}
        </Flex>
      )}
      {createContractState >= CreateContractWithAIState.DocumentAnalyzed ? (
        <Flex alignItems="center" pb="4">
          {pagesAnalyzed ? (
            <Icon as={FaCheckCircle} color={"green.400"} mr="2" />
          ) : (
            <Icon as={FaTimesCircle} color="red.400" mr="2" />
          )}
          {pagesAnalyzed ? (
            <Text fontSize={"sm"} color="smMuted">
              {t("Deep neural net analysis complete")}. {t("Analyzed")} {pagesAnalyzed} {t("pages")}.
            </Text>
          ) : (
            <Text fontSize={"sm"} color="smMuted">
              {t("Neural net analysis failed")}. {t("Make sure the document is a valid PDF with selectable text")}.
            </Text>
          )}
        </Flex>
      ) : null}
      {createContractState === CreateContractWithAIState.ContractCreated && contract ? (
        <Flex flexDirection={"column"} overflow="auto" backgroundColor={"smBackgroundSecondary"} p="4">
          {contract.title && (
            <Text fontWeight={"bold"} pt="4">
              {contract.title}
            </Text>
          )}
          {contract.description && (
            <Text fontSize={"sm"} py="2">
              {contract.description}
            </Text>
          )}
          {contract.template && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Contract type")}</Text>
              <Text fontWeight={"bold"}>{contract.template.name}</Text>
            </Flex>
          )}
          {contract.dataFields?.startDate && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Start date")}</Text>
              <Text fontWeight={"bold"}>{contract.dataFields.startDate}</Text>
            </Flex>
          )}
          {contract.dataFields?.endDate?.date && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("End date")}</Text>
              <Text fontWeight={"bold"}>{contract.dataFields.endDate.date}</Text>
            </Flex>
          )}
          {contract.dataFields?.paymentTermsInDays ? (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Payment terms")}</Text>
              <Text fontWeight={"bold"}>
                {contract.dataFields.paymentTermsInDays} {t("days")}
              </Text>
            </Flex>
          ) : null}
          {contract.dataFields?.noticePeriod && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Notice period")}</Text>
              <Text fontWeight={"bold"}>
                {contract.dataFields.noticePeriod} {t("months")}
              </Text>
            </Flex>
          )}
          {contract.dataFields?.counterpartyContactPerson && (
            <Flex flexDirection={"column"} fontSize={"sm"} py="2">
              <Text fontSize={"sm"}>{t("Counterparty")}</Text>
              <Text fontWeight={"bold"} pb="2">
                {contract.counterparty?.organizationEntry?.name}
                {contract.counterparty?.organizationEntry?.organizationNumber}
              </Text>
              <Text>{contract.dataFields.counterpartyContactPerson.fullName}</Text>
              <Text>{contract.dataFields.counterpartyContactPerson.email}</Text>
            </Flex>
          )}
        </Flex>
      ) : null}
      <Flex justifyContent={"end"} alignItems={"end"} pt="4">
        <Button
          colorScheme="teal"
          isDisabled={createContractState !== CreateContractWithAIState.ContractCreated}
          onClick={() => {
            if (!contract) return;
            if (urls.contracts.edit.isCurrentPage()) {
              window.location.replace(urls.contracts.edit.go(contract.id).details.fullPathName("details"));
            } else navigate(urls.contracts.edit.go(contract.id).details, { replace: true });
          }}
        >
          {t("Next")}
        </Button>
      </Flex>
    </Flex>
  );
};

const isFile = (file: File | Blob): file is File => {
  return (file as File).lastModified !== undefined;
};
