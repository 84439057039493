import { createSearchParams } from "react-router-dom";
import { EditContractStep } from "./common/useQueryParams";

const editEventPaths = (id: string) => {
  const pathName = `/sourcing/event/${id}/edit`;
  return {
    intro: {
      pathname: pathName,
      fullPathName: (): string => {
        return `${pathName}?step=intro`;
      },
      search: createSearchParams({
        step: "intro",
      }).toString(),
    },
    products: {
      pathname: pathName,
      fullPathName: () => `${pathName}?step=products`,
      search: createSearchParams({ step: "products" }).toString(),
    },
    docs: {
      pathname: pathName,
      search: createSearchParams({
        step: "docs",
      }).toString(),
    },
    parties: {
      pathname: pathName,
      search: createSearchParams({
        step: "parties",
      }).toString(),
    },
    review: {
      pathname: pathName,
      search: createSearchParams({
        step: "review",
      }).toString(),
    },
  };
};

const editNegotiationPaths = ({ eventId, negotiationId }: { eventId: string; negotiationId: string }) => {
  const pathName = `/sourcing/event/${eventId}/negotiation/${negotiationId}/edit`;
  return {
    intro: {
      pathname: pathName,
      fullPathName: (): string => {
        return `${pathName}?step=intro`;
      },
      search: createSearchParams({
        step: "intro",
      }).toString(),
    },
    products: {
      pathname: pathName,
      fullPathName: () => `${pathName}?step=products`,
      search: createSearchParams({ step: "products" }).toString(),
    },
    docs: {
      pathname: pathName,
      search: createSearchParams({
        step: "docs",
      }).toString(),
    },
    parties: {
      pathname: pathName,
      search: createSearchParams({
        step: "parties",
      }).toString(),
    },
    review: {
      pathname: pathName,
      search: createSearchParams({
        step: "review",
      }).toString(),
    },
  };
};

const editBidPaths = (id: string) => {
  const pathName = `/sourcing/bid/${id}/edit`;
  return {
    details: {
      pathname: pathName,
      search: createSearchParams({
        step: "details",
      }).toString(),
    },
    products: {
      pathname: pathName,
      search: createSearchParams({
        step: "products",
      }).toString(),
    },
    review: {
      pathname: pathName,
      search: createSearchParams({
        step: "review",
      }).toString(),
    },
  };
};

const editContractPaths = (id: string, originEvent?: string) => {
  const pathName = `/contracts/${id}/edit`;
  return {
    details: {
      pathname: pathName,
      fullPathName: (step: EditContractStep): string => {
        return `${pathName}?step=${step}`;
      },
      search: createSearchParams({
        step: "details",
        ...(originEvent ? { originEvent } : {}),
      }).toString(),
    },
    documents: {
      pathname: pathName,
      search: createSearchParams({
        step: "docs",
        ...(originEvent ? { originEvent } : {}),
      }).toString(),
    },
    review: {
      pathname: pathName,
      search: createSearchParams({
        step: "review",
        ...(originEvent ? { originEvent } : {}),
      }).toString(),
    },
  };
};

export const urls = {
  home: "/",
  emailInvite: "/invite/:emailInviteId",
  projects: {
    viewAll: "/projects",
    view: {
      pathname: "/projects/:projectId",
      go: (projectId: string) => `/projects/${projectId}`,
    },
  },
  events: {
    edit: {
      paths: editEventPaths(":eventId"),
      go: (id: string) => {
        return editEventPaths(id);
      },
      isCurrentPage: (): boolean => {
        const regex = /\/event\/.*\/edit/;
        return regex.test(window.location.href);
      },
    },
    view: {
      pathname: "/sourcing/event/:eventId",
      go: (id: string) => {
        return `/sourcing/event/${id}`;
      },
      question: (eventId: string, questionId: string) => {
        return `/sourcing/event/${eventId}?tab=questions&question=${questionId}`;
      },
    },
    editNegotiation: {
      paths: editNegotiationPaths({ eventId: ":eventId", negotiationId: ":negotiationId" }),
      go: ({ eventId, negotiationId }: { eventId: string; negotiationId: string }) =>
        editNegotiationPaths({ eventId, negotiationId }),
    },
    supplierView: {
      pathname: "/sourcing/event/:eventId/supplier-view",
      go: (id: string) => {
        return `/sourcing/event/${id}/supplier-view`;
      },
      announcement: (eventId: string, announcementId: string) => {
        return `/sourcing/event/${eventId}/supplier-view?tab=announcements&announcement=${announcementId}`;
      },
      question: (eventId: string, questionId: string) => {
        return `/sourcing/event/${eventId}/supplier-view?tab=questions&question=${questionId}`;
      },
    },
    viewOngoing: "/sourcing/events/ongoing",
    viewDrafts: "/sourcing/events/drafts",
    viewCompleted: "/sourcing/events/completed",
    viewInvitations: "/sourcing/events/invitations",
  },
  contracts: {
    edit: {
      paths: editContractPaths(":contractId"),
      go: (id: string, originEvent?: string) => {
        return editContractPaths(id, originEvent);
      },
      isCurrentPage: (): boolean => {
        const regex = /\/contracts\/.*\/edit/;

        return regex.test(window.location.href);
      },
    },
    viewSingle: {
      pathname: "/contracts/:contractId",
      go: (id: string) => {
        return `/contracts/${id}`;
      },
      task: (contractId: string, taskId: string) => {
        return `/contracts/${contractId}?tab=tasks&task=${taskId}`;
      },
      documents: (contractId: string) => {
        return `/contracts/${contractId}?tab=documents`;
      },
      reminders: (contractId: string) => {
        return `/contracts/${contractId}?tab=reminders`;
      },
    },
    viewAll: "/contracts",
    viewDrafts: "/contracts/drafts",
    viewPublished: "/contracts/published",
    viewTasks: "/contracts/tasks",
    settings: "/contracts/settings",
  },
  admin: "/admin",
  notifications: "/notifications",
  bids: {
    edit: {
      paths: editBidPaths(":bidId"),
      go: (id: string) => {
        return editBidPaths(id);
      },
    },
    viewSingle: {
      pathname: "/sourcing/bids/:bidId",
      go: (id: string) => {
        return `/sourcing/bids/${id}`;
      },
    },
    received: "/sourcing/bids/received",
    viewAll: "/sourcing/my-bids",
  },
  organizations: {
    init: "/organization/init",
    search: "/organizations",
    profile: {
      pathname: "/organizations/:organizationId",
      go: (id: string) => {
        return `/organizations/${id}`;
      },
    },
  },
  myProfile: {
    organization: {
      edit: "/organization/edit",
      manageUsers: "/organization/manage-users",
      settings: "/organization/settings",
    },
    personal: {
      view: "/personal-profile",
      edit: "/personal-profile/edit",
    },
  },
  login: "/login",
  emailVerification: "/confirmation",
  completeRecovery: (flowId: string): string => `/login?flow=${flowId}`,
  init: "/init",
};
