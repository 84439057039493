import { Box, Flex, FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { useListContractTemplatesQuery } from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { SingleSelector } from "../../../../../common/input/Selector/SingleSelector";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useTemplateMeta } from "../../../settings/ContractTemplates/useTemplateMeta";
import { useContractState } from "../../useContractState";

export const Template = () => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const { data } = useListContractTemplatesQuery({
    orgId: authState.selectedOrg.id,
    contractId: contractState.contract.id,
  });
  const templateMeta = useTemplateMeta();
  const errorMessage = containsError(contractState, "EditTemplate") ? t("Update failed") : null;

  if (!data) return <div>{t("Loading...")}</div>;

  const eventHandler = async (templateId: string | null) => {
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditTemplate",
          value: templateId,
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <FormControl id="template" isInvalid={errorMessage !== null}>
      <FormLabel>
        {t("Contract type")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <Box borderRadius={"10px"}>
        <Flex>
          <SingleSelector
            value={
              contractState.contract.template
                ? {
                    value: contractState.contract.template.id,
                    label: templateMeta.getTemplateName(contractState.contract.template),
                  }
                : undefined
            }
            isClearable={true}
            options={data.templates.map((e) => ({
              value: e.id,
              label: templateMeta.getTemplateName(e),
            }))}
            onChange={(e) => {
              if (e) eventHandler(e.value);
              else eventHandler(null);
            }}
            noMatchingOptionsMessage={t("No matching persons found") ?? ""}
            noOptionsProvidedMessage={t("No persons available") ?? ""}
          />
        </Flex>
      </Box>
      <FormHelperText>{t("Use a contract type")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
