import { Box, Grid, GridItem, Heading, Text, useMediaQuery } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PersonDto, useListContractTasksForOrganizationQuery } from "../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../common/auth/useLoggedInWithOrgContextState";
import { useApiError } from "../../../common/errors/useApiError";
import { Layout } from "../../../common/layout/Layout";
import { useSkeletons } from "../../../common/useSkeletons";
import { CONTENT_WIDTH } from "../../../config";
import { MultiSelectOption } from "../view-all/filtering/MultiSelectFilter";
import { TasksTable } from "./TasksTable";
import { TaskFilters } from "./filtering/TaskFilters";

export const ContractTasks = () => {
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const displayer = useApiError();
  const [selectedResponsiblePersons, setSelectedResponsiblePersons] = useState<PersonDto[]>([]);
  const [selectedContracts, setSelectedContracts] = useState<MultiSelectOption[]>([]);
  const [selectedStatuses, setSelectedStatuses] = useState<MultiSelectOption[]>([]);

  const { data, error, isLoading } = useListContractTasksForOrganizationQuery({
    organizationId: authState.selectedOrg.id,
    orgId: authState.selectedOrg.id,
    statuses: selectedStatuses.map((e) => e.value).join(","),
    personIds: selectedResponsiblePersons.map((p) => p.id).join(","),
    contractIds: selectedContracts.map((e) => e.value).join(","),
  });

  const skeletons = useSkeletons().stackedLines;

  if (error) return displayer.show(error);

  return (
    <Layout>
      <Grid
        height="full"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
        px="2"
      >
        <GridItem area={"content"} px={isLargerThan768 ? "0" : "2.5"}>
          <Grid height="full" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid #e1e6ee"}>
              <Heading as="h2" size="lg">
                {t("Contract tasks")}
              </Heading>
            </GridItem>
            <GridItem>
              <Box mt="15px" mb="20px">
                <Text fontSize="medium" color={"#585858"} mb="10px">
                  {t("Tasks for all contracts, including contracts shared with you")}
                </Text>
              </Box>
              <Box mb="25px">
                <TaskFilters
                  selectedResponsiblePersons={selectedResponsiblePersons}
                  onSelectedResponsiblePersonsChange={(e) => setSelectedResponsiblePersons(e)}
                  selectedContracts={selectedContracts}
                  onSelectedContractsChange={(e) => setSelectedContracts(e)}
                  selectedStatuses={selectedStatuses}
                  onSelectedStatusesChange={(e) => setSelectedStatuses(e)}
                />
              </Box>
              {data && <TasksTable tasks={data.tasks} />}
              {isLoading && !data && skeletons(0, 5)}
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
