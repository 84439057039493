import { FormControl, FormErrorMessage, FormHelperText, FormLabel, Spinner } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { PersonDto } from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { PersonSelector } from "../../../../../common/persons/PersonSelector";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { containsError } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import { useContractState } from "../../useContractState";

export const InternalOwner = () => {
  const { t } = useTranslation();
  const contractState = useContractState();
  const [isLoading, setIsLoading] = useState(false);
  const authState = useLoggedInWithOrgContextState();
  const dispatch = useAppDispatch();
  const errorMessage = containsError(contractState, "EditInternalOwner") ? t("Update failed") : null;

  const eventHandler = async (person: PersonDto | null) => {
    if (!person) throw Error("Internal owner should not be null");
    setIsLoading(true);
    await dispatch(
      editContractThunk({
        command: {
          type: "EditInternalOwner",
          value: person.id,
        },
      })
    );
    setIsLoading(false);
  };

  return (
    <FormControl id="responsiblePerson" isInvalid={errorMessage !== null}>
      <FormLabel>
        {t("Responsible person")} {isLoading && <Spinner size="xs" />}
      </FormLabel>
      <PersonSelector
        size="md"
        organizationIds={[authState.selectedOrg.id]}
        selectedPerson={contractState.contract.internalOwner}
        onChange={eventHandler}
        isClearable={false}
      />
      <FormHelperText>{t("Provide a responsible person in your company")}</FormHelperText>
      <FormErrorMessage>{errorMessage && errorMessage}</FormErrorMessage>
    </FormControl>
  );
};
