import { Box, CircularProgress, CircularProgressLabel, Flex, Text, useToast } from "@chakra-ui/react";
import { uniqBy } from "lodash";
import { useTranslation } from "react-i18next";
import { BseDto, useAwardToBidMutation } from "../../../../autogen/bff-api";
import { useApiError } from "../../../../common/errors/useApiError";
import { InvitedOrganizations } from "./InvitedOrganizations";

interface Props {
  data: BseDto;
}

export const Bids = ({ data }: Props) => {
  const { t } = useTranslation();
  const toast = useToast();
  const [awardToBidMutation, { isLoading: isAwarding }] = useAwardToBidMutation();
  const apiErrorDisplayer = useApiError();

  const awardToBid = async (bidId: string) => {
    await awardToBidMutation({
      eventId: data.id,
      awardToBidRequest: {
        bidIds: [bidId],
      },
    })
      .unwrap()
      .catch((e) => {
        apiErrorDisplayer.trigger(e);
      })
      .then(() => {
        toast({
          title: t("Event awarded!"),
          description: t("The event has been successfully awarded!"),
          status: "success",
        });
      });
  };

  const draftCreated = data.organizationInvites.filter(
    ({ status }) =>
      status === "DraftCreated" || status === "BidIsSubmitted" || status === "IsWinner" || status === "IsLoser"
  ).length;

  const numberOfInvitedParties =
    uniqBy(data.emailInvites, (invite) => invite.organizationEntry?.id).length + data.organizationInvites.length;

  const numberOfDeliveredBids = data.organizationInvites.filter(
    (e) => e.status === "BidIsSubmitted" || e.status === "IsWinner" || e.status === "IsLoser"
  ).length;

  const declinedInvitations = data.organizationInvites.filter((e) => e.status === "InvitationDeclined").length;

  return (
    <>
      <Flex width="full" justifyContent="center" columnGap={20} pt="5">
        <Flex flexDirection="column" alignItems="center">
          <CircularProgress
            capIsRound
            value={(declinedInvitations / numberOfInvitedParties) * 100}
            size={40}
            color="smDanger"
            trackColor="smBackgroundSecondary"
          >
            <CircularProgressLabel fontSize="lg">{`${declinedInvitations} ${t(
              "of"
            )} ${numberOfInvitedParties}`}</CircularProgressLabel>
          </CircularProgress>
          <Text size="sm" fontWeight="semibold">
            {t("Declined")}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <CircularProgress
            capIsRound
            value={(draftCreated / numberOfInvitedParties) * 100}
            size={40}
            color="smPrimary"
            trackColor="smBackgroundSecondary"
          >
            <CircularProgressLabel fontSize="lg">{`${draftCreated} ${t(
              "of"
            )} ${numberOfInvitedParties}`}</CircularProgressLabel>
          </CircularProgress>
          <Text size="sm" fontWeight="semibold">
            {t("Draft created")}
          </Text>
        </Flex>
        <Flex flexDirection="column" alignItems="center">
          <CircularProgress
            capIsRound
            value={(numberOfDeliveredBids / numberOfInvitedParties) * 100}
            size={40}
            color="smTertiary"
            trackColor="smBackgroundSecondary"
          >
            <CircularProgressLabel fontSize="lg">
              {`${numberOfDeliveredBids} ${t("of")} ${numberOfInvitedParties}`}
            </CircularProgressLabel>
          </CircularProgress>
          <Text size="sm" fontWeight="semibold">
            {t("Bid delivered")}
          </Text>
        </Flex>
      </Flex>
      <Box display={"flex"} borderRadius={"md"} pt="10" flexDirection={"column"}>
        <Text fontSize={"lg"} fontWeight="semibold">
          {t("Invited companies & bids")}
        </Text>
        <Box h="15px"></Box>
        <InvitedOrganizations event={data} awardToBid={awardToBid} isAwarding={isAwarding} />
      </Box>
    </>
  );
};
