import {
  Box,
  Checkbox,
  Flex,
  FormControl,
  FormLabel,
  Icon,
  Input,
  InputGroup,
  InputRightAddon,
  Select,
  Text,
} from "@chakra-ui/react";
import { t } from "i18next";
import { cloneDeep, debounce } from "lodash";
import { useCallback } from "react";
import { FaLock } from "react-icons/fa";
import { OrgDto } from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { ISODateFormControl } from "../../../../../common/input/DateTimeSelectors/ISODateFormControl";
import { MultiSelector } from "../../../../../common/input/Selector/MultiSelector";
import { TipTap } from "../../../../../common/input/TipTap/TipTap";
import { PersonSelector } from "../../../../../common/persons/PersonSelector";
import { useAppDispatch, useAppSelector } from "../../../../../common/redux/hooks";
import { ContractState, setCustomField, setCustomFields } from "../../../../../common/redux/reducers/contractReducer";
import { editContractThunk } from "../../../../../common/redux/thunks/contract/edit-contract-thunk";
import {
  CustomContractField,
  CustomContractFieldSections,
  CustomEnumField,
  CustomFieldType,
  CustomMultiEnumField,
} from "../../../../../common/types";
import { GenericMonetaryValue } from "./financial/GenericMonetaryValue";
import { RemoveDataFieldButton } from "./RemoveDataFieldButton";

const isRequiredCustomField = ({
  state,
  sectionId,
  fieldId,
}: {
  state?: ContractState | null;
  sectionId: string;
  fieldId: string;
}): boolean =>
  !!state?.contract.dataFields.requiredCustomFields?.find((f) => f.sectionId === sectionId && f.fieldId === fieldId);

const getEnumOptions = ({
  sectionId,
  fieldId,
  org,
}: {
  sectionId: string;
  fieldId: string;
  org: Omit<OrgDto, "customContractSections"> & {
    customContractSections?: CustomContractFieldSections | undefined;
  };
}) =>
  (
    org.customContractSections?.[sectionId]?.fields.find((f) => f.id === fieldId) as
      | CustomEnumField
      | CustomMultiEnumField
  )?.options;

export const CustomField = ({ sectionId, field }: { sectionId: string; field: CustomContractField }) => {
  const dispatch = useAppDispatch();

  const { customFields, state } = useAppSelector((state) => state.contract);

  const removeField = () => {
    if (!customFields) return;
    const updatedSections = {
      ...customFields,
      [sectionId]: {
        ...customFields[sectionId],
        fields: customFields[sectionId].fields.filter((f) => f.id !== field.id),
      },
    };
    dispatch(setCustomFields(updatedSections));
    dispatch(
      editContractThunk({
        command: {
          type: "EditCustomFields",
          value: updatedSections,
        },
      })
    );
  };

  return (
    <Box pb="4" position={"relative"}>
      <Box position={"absolute"} right="0" zIndex={10}>
        <RemoveDataFieldButton
          removeDataField={isRequiredCustomField({ state, sectionId, fieldId: field.id }) ? undefined : removeField}
        />
      </Box>
      <Box pt="3">
        <FieldForm sectionId={sectionId} field={field} />
        {field.isInternal && (
          <Text fontSize={"xs"} color="smMuted">
            <Icon as={FaLock} boxSize={"2"} mr="1" />
            {t("Internal field - will not be visible to contract counterparty")}
          </Text>
        )}
      </Box>
    </Box>
  );
};

const FieldForm = ({ sectionId, field }: { sectionId: string; field: CustomContractField }) => {
  const dispatch = useAppDispatch();

  const authState = useLoggedInWithOrgContextState();

  const { customFields, state } = useAppSelector((state) => state.contract);

  const updateField = useCallback(
    async (sections: CustomContractFieldSections) => {
      await dispatch(
        editContractThunk({
          command: {
            type: "EditCustomFields",
            value: sections,
          },
        })
      );
    },
    [dispatch]
  );

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const updateFieldDebounced = useCallback(
    debounce(async (sections: CustomContractFieldSections) => {
      await updateField(sections);
    }, 300),
    [updateField]
  );

  const handleUpdateField = ({ sectionId, field }: { sectionId: string; field: CustomContractField }) => {
    const sections = cloneDeep(customFields);
    if (!sections) throw Error("Sections not defined - cannot update");
    const fieldIndex = sections[sectionId].fields.findIndex((f) => f.id === field.id);
    if (fieldIndex === -1) throw Error("Field not found - cannot update");
    sections[sectionId].fields[fieldIndex] = field;
    dispatch(setCustomField({ sectionId, field }));
    updateField(sections);
  };

  const handleUpdateFieldDebounced = ({ sectionId, field }: { sectionId: string; field: CustomContractField }) => {
    const sections = cloneDeep(customFields);
    if (!sections) throw Error("Sections not defined - cannot update");
    const fieldIndex = sections[sectionId].fields.findIndex((f) => f.id === field.id);
    if (fieldIndex === -1) throw Error("Field not found - cannot update");
    sections[sectionId].fields[fieldIndex] = field;
    dispatch(setCustomField({ sectionId, field }));
    updateFieldDebounced(sections);
  };

  switch (field.type) {
    case CustomFieldType.CustomAmount:
      return (
        <GenericMonetaryValue
          label={field.name}
          placeholderText={t("Enter amount")}
          amount={field.amount ?? 0}
          errorMessage={null}
          editAmount={(amount) => {
            handleUpdateField({ sectionId, field: { ...field, amount, currency: state?.contract.currency } });
          }}
          helperText={""}
          isRequiredButNotProvided={false}
        />
      );
    case CustomFieldType.CustomNumber:
      return (
        <FormControl pb="4">
          <FormLabel>{field.name}</FormLabel>
          <Input
            type="number"
            placeholder={t("Enter value") ?? ""}
            value={field.value ?? ""}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  value: e.target.value ? +e.target.value : undefined,
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomUrl:
      return (
        <FormControl pb="4">
          <FormLabel>{field.name}</FormLabel>
          <Input
            placeholder={t("Enter URL") ?? ""}
            value={field.url ?? ""}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  url: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomText:
      return (
        <FormControl pb="4">
          <FormLabel>{field.name}</FormLabel>
          <Input
            placeholder={t("Enter text") ?? ""}
            value={field.text ?? ""}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  text: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomRichText:
      return (
        <Flex flexDirection="column">
          <Text fontWeight="medium" pb="2">
            {field.name}
          </Text>
          <TipTap
            editable
            content={field.text ?? ""}
            onUpdate={(text) =>
              handleUpdateField({
                sectionId,
                field: {
                  ...field,
                  text,
                },
              })
            }
          />
        </Flex>
      );
    case CustomFieldType.CustomEmail:
      return (
        <FormControl pb="4">
          <FormLabel>{field.name}</FormLabel>
          <Input
            placeholder={t("Enter email") ?? ""}
            value={field.email ?? ""}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  email: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomBoolean:
      return (
        <FormControl pb="4">
          <FormLabel>{field.name}</FormLabel>
          <Checkbox
            pl="4"
            isChecked={field.isChecked}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  isChecked: e.target.checked,
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomTag:
      return (
        <FormControl pb="4">
          <FormLabel>{field.name}</FormLabel>
          <Input
            placeholder={t("Enter tag") ?? ""}
            value={field.tag ?? ""}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  tag: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomDate:
      return (
        <ISODateFormControl
          id={field.id}
          title={field.name}
          helperText={""}
          defaultDate={field.date}
          errorMessage={null}
          onChange={(date) =>
            handleUpdateFieldDebounced({
              sectionId,
              field: {
                ...field,
                date,
              },
            })
          }
          isRequiredButNotProvided={false}
        />
      );
    case CustomFieldType.CustomPercentage:
      return (
        <>
          <FormLabel>{field.name}</FormLabel>
          <InputGroup pb="4">
            <Input
              type="number"
              placeholder={t("Enter value") ?? ""}
              value={field.value && isFinite(field.value) ? field.value * 100 : ""}
              onChange={(e) => {
                handleUpdateFieldDebounced({
                  sectionId,
                  field: {
                    ...field,
                    value: e.target.value && isFinite(+e.target.value) ? +e.target.value / 100 : undefined,
                  },
                });
              }}
            />
            <InputRightAddon>%</InputRightAddon>
          </InputGroup>
        </>
      );
    case CustomFieldType.CustomPhone:
      return (
        <FormControl pb="4">
          <FormLabel size={"sm"}>{field.name}</FormLabel>
          <Input
            placeholder={t("Enter phone number") ?? ""}
            value={field.number ?? ""}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  number: e.target.value,
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomEnum:
      return (
        <FormControl pb="4">
          <FormLabel size={"sm"}>{field.name}</FormLabel>
          <Select
            placeholder={t("Select value") ?? ""}
            value={field.value ?? ""}
            onChange={(e) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  value: e.target.value,
                },
              });
            }}
          >
            {(getEnumOptions({ org: authState.selectedOrg, sectionId, fieldId: field.id }) ?? field.options)?.map(
              (option) => (
                <option key={option} value={option}>
                  {option}
                </option>
              )
            )}
          </Select>
        </FormControl>
      );
    case CustomFieldType.CustomMultiEnum:
      return (
        <FormControl pb="4">
          <FormLabel size={"sm"}>{field.name}</FormLabel>
          <MultiSelector
            placeholder={{ text: t("Select value") ?? "", color: "smMuted" }}
            options={
              (getEnumOptions({ org: authState.selectedOrg, sectionId, fieldId: field.id }) ?? field.options)?.map(
                (o) => ({ label: o, value: o })
              ) ?? []
            }
            noOptionsAvailableMessage={t("No available options")}
            value={field.values?.map((v) => ({ label: v, value: v })) ?? []}
            onChange={(values) => {
              handleUpdateFieldDebounced({
                sectionId,
                field: {
                  ...field,
                  values: values.map((v) => v.value),
                },
              });
            }}
          />
        </FormControl>
      );
    case CustomFieldType.CustomPerson:
      return (
        <FormControl pb="4">
          <FormLabel size="sm">{field.name}</FormLabel>
          <PersonSelector
            size="md"
            selectedPerson={field.person}
            organizationIds={[authState.selectedOrg.id]}
            onChange={(p) => handleUpdateField({ sectionId, field: { ...field, person: p ?? undefined } })}
          />
        </FormControl>
      );
  }
};
