import { Button, Icon } from "@chakra-ui/react";
import { useState } from "react";
import { useTranslation } from "react-i18next";
import { FaEye } from "react-icons/fa";
import { BseBidDto } from "../../../../../autogen/bff-api";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { setProductGroups as setBidProductGroups } from "../../../../../common/redux/reducers/basicBidReducer";
import { setProductGroups } from "../../../../../common/redux/reducers/productReducer";
import { ViewDeliveredBseBid } from "../ViewDeliveredBseBid";

interface Props {
  bid: BseBidDto;
}

export const ViewBidButton = ({ bid }: Props) => {
  const [bidToView, setBidToView] = useState<BseBidDto>();
  const { t } = useTranslation();
  const dispatch = useAppDispatch();

  return (
    <>
      {bidToView && (
        <ViewDeliveredBseBid
          bidId={bidToView.id}
          isOpen={bidToView !== undefined}
          onClose={() => {
            setBidToView(undefined);
            dispatch(setBidProductGroups([]));
            dispatch(setProductGroups([]));
          }}
        />
      )}
      <Button
        size="sm"
        leftIcon={<Icon as={FaEye} w="15px" h="15px" />}
        variant={"solid"}
        onClick={() => setBidToView(bid)}
      >
        {t("View bid")}
      </Button>
    </>
  );
};
