import { Flex, Text } from "@chakra-ui/react";
import { chakraComponents, GroupBase, MenuListProps, OptionProps, SingleValue } from "chakra-react-select";
import { t } from "i18next";
import { debounce } from "lodash";
import { useCallback, useState } from "react";
import { MinimalOrgDto, OrganizationType, useSearchOrganizationsAndEntriesQuery } from "../../autogen/bff-api";
import { SelectorValue } from "../input/Selector/SelectorValue";
import { SingleSelector } from "../input/Selector/SingleSelector";
import { OrganizationTypeTag } from "./OrganizationTypeTag";

export const OrganizationSelector = ({
  organization,
  onChange,
  size = "sm",
  placeholder,
  type,
  orgsToExclude,
  isClearable = true,
}: {
  organization?: MinimalOrgDto;
  onChange: ({ organization }: { organization?: MinimalOrgDto }) => void | Promise<void>;
  size?: "sm" | "md";
  placeholder?: string;
  type?: OrganizationType;
  orgsToExclude?: string[];
  isClearable?: boolean;
}) => {
  const [searchName, setSearchName] = useState("");
  const [debouncedSearchName, setDebouncedSearchName] = useState("");

  // eslint-disable-next-line react-hooks/exhaustive-deps
  const handleDebouncedSearchName = useCallback(
    debounce((name: string) => setDebouncedSearchName(name), 300),
    []
  );

  const { data, isFetching } = useSearchOrganizationsAndEntriesQuery({ name: debouncedSearchName, type, limit: 10 });

  return (
    <SingleSelector<{ organization: MinimalOrgDto }>
      isClearable={isClearable}
      size={size}
      placeholder={{ text: placeholder ?? `${t("Select organization")}...`, color: "" }}
      value={
        organization
          ? {
              label: `${organization.name}${
                organization.organizationNumber ? ` (${organization.organizationNumber})` : ""
              }`,
              value: organization.id,
              organization,
            }
          : undefined
      }
      inputValue={searchName}
      isLoading={isFetching}
      onInputChange={(name: string) => {
        setSearchName(name);
        handleDebouncedSearchName(name);
      }}
      options={
        data?.organizations
          .filter((o) => !orgsToExclude?.includes(o.id))
          .map((o) => ({
            label: `${o.name} ${o.organizationNumber ? `(${o.organizationNumber})` : ""}`,
            value: o.id,
            organization: o,
          })) ?? []
      }
      onChange={(value?: SingleValue<SelectorValue<{ organization: MinimalOrgDto }>>) => {
        if (!value) {
          onChange({});
          return;
        }
        const organization = data?.organizations?.find((o) => value.value === o.id);
        onChange({ organization });
      }}
      searchValues={false}
      CustomOption={CustomOption}
      CustomMenuList={CustomMenuList}
    />
  );
};

const CustomMenuList = (
  props: MenuListProps<
    SelectorValue<{ organization: MinimalOrgDto }>,
    false,
    GroupBase<SelectorValue<{ organization: MinimalOrgDto }>>
  >
) => {
  return (
    <chakraComponents.MenuList {...props}>
      <Flex flexDirection="column" maxWidth="full" overflow="hidden">
        {props.children}
      </Flex>
    </chakraComponents.MenuList>
  );
};

const CustomOption = (
  props: OptionProps<
    SelectorValue<{ organization: MinimalOrgDto }>,
    false,
    GroupBase<SelectorValue<{ organization: MinimalOrgDto }>>
  >
) => {
  const { data } = props;
  return (
    <chakraComponents.Option {...props}>
      <Flex
        width="full"
        maxWidth="full"
        align="center"
        justify="space-between"
        overflowX="auto"
        pr={{ base: 0, sm: 6 }}
      >
        <Flex flexDirection="column" width="70%" maxWidth="70%" align={{ base: "center", sm: "start" }}>
          <Text fontWeight="semibold" textAlign={{ base: "center", sm: "start" }}>
            {data.organization.name}
          </Text>
          <Text fontSize="xs" color="smMuted">
            {data.organization.organizationNumber}
          </Text>
        </Flex>
        <Flex width="30%">
          <OrganizationTypeTag type={data.organization.type} />
        </Flex>
      </Flex>
    </chakraComponents.Option>
  );
};
