import {
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Modal,
  ModalBody,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
  Text,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { useState } from "react";
import { BseStatusDto, MinimalOrgDto, useListPersonsQuery } from "../../../../../autogen/bff-api";
import { ModalSkeleton } from "../../../../../common/ModalSkeleton";
import { Paging } from "../../../../../common/Paging";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";
import { displayPersonNameWithEmail } from "../../../../contracts/view-single/sharing/AddExternalParticipantModal";

const limit = 10;

export const InviteOrganizationModal = ({
  minimalOrg,
  onClose,
  eventStatus,
}: {
  minimalOrg?: MinimalOrgDto;
  onClose: () => void;
  eventStatus: BseStatusDto;
}) => {
  const dispatch = useAppDispatch();
  const { data, isLoading } = useListPersonsQuery(minimalOrg ? { orgIds: [minimalOrg.id], limit: 10 } : skipToken);

  const [personIds, setPersonIds] = useState<string[]>([]);

  const [skip, setSkip] = useState(0);

  const createInvite = async () => {
    if (!minimalOrg?.id) throw Error("Organization id was unexpectedly undefined");
    await dispatch(
      editBseThunk({
        command: {
          type: "AddInvitedOrganization",
          value: { organizationId: minimalOrg?.id, personIds },
        },
      })
    );
  };

  const handleClose = () => {
    setPersonIds([]);
    onClose();
  };

  return (
    <Modal isOpen={!!minimalOrg} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent width="full" maxHeight="80%" padding="5" overflow="hidden">
        <ModalCloseButton />
        <ModalBody height="full">
          <Flex height={"full"} flexDirection={"column"}>
            <Heading as="h2" size="md" pb="2">
              {t("Select persons to invite for")} {minimalOrg?.name}
            </Heading>
            {isLoading && <ModalSkeleton />}
            {data && (
              <Flex flexDirection="column">
                <Paging
                  limit={limit}
                  skip={skip}
                  setSkip={(skip) => setSkip(skip)}
                  isLoading={isLoading}
                  count={data?.count}
                />
                <CheckboxGroup colorScheme="teal">
                  {data.persons.map((p) => (
                    <Checkbox
                      key={p.id}
                      isChecked={!!personIds.find((id) => id === p.id)}
                      onChange={(e) => {
                        setPersonIds((personIds) => {
                          if (e.target.checked) return [...personIds.filter((id) => id !== p.id), p.id];
                          return [...personIds.filter((id) => id !== p.id)];
                        });
                      }}
                    >
                      {displayPersonNameWithEmail(p)}
                    </Checkbox>
                  ))}
                </CheckboxGroup>
                <Text fontSize="sm" color="smMuted" pt="5">
                  {t(
                    `If you'd like to invite someone, but you don't see them listed here, get in touch with one of the persons above`
                  )}
                  .
                </Text>
                <FormControl flexDirection="column" alignItems="end" pt="5">
                  <Button
                    isDisabled={!personIds.length || eventStatus === "Awarded" || eventStatus === "Completed"}
                    onClick={async () => {
                      await createInvite();
                      handleClose();
                    }}
                  >
                    {t("Create invite")}
                  </Button>
                  {(eventStatus === "Published" || eventStatus === "DeadlineHasPassed") && (
                    <FormHelperText color="orange">
                      {t("The sourcing event is published and invitations will be sent at once")}.
                    </FormHelperText>
                  )}
                </FormControl>
              </Flex>
            )}
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
