import { Tag } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { BseBidStatusDto } from "../../../autogen/bff-api";

export const BidInvitationStatus = ({ status }: { status: BseBidStatusDto }) => {
  const { t } = useTranslation();

  const getTagMeta = (
    status: BseBidStatusDto
  ): {
    color: string;
    text: string;
  } => {
    switch (status) {
      case "DeadlinePassedAndBidDelivered":
      case "BidDelivered":
        return {
          color: "blue",
          text: t("Delivered"),
        };
      case "DraftCreated":
        return {
          color: "yellow",
          text: t("Draft"),
        };
      case "InviteAccepted":
      case "InviteNotAccepted":
        return {
          color: "gray",
          text: t("Invited"),
        };
      case "DeadlinePassedWithoutBidDelivered":
        return {
          color: "red",
          text: t("Overdue"),
        };
      case "IsLoser":
        return {
          color: "red",
          text: t("Loser"),
        };
      case "IsWinner":
        return {
          color: "green",
          text: t("Winner!"),
        };
      case "InviteDeclined":
        return {
          color: "red",
          text: t("Declined"),
        };
    }
  };

  const tagMeta = getTagMeta(status);

  return (
    <>
      <Tag size="sm" variant="outline" colorScheme={tagMeta.color} textAlign="center" py="1">
        {tagMeta.text}
      </Tag>
    </>
  );
};
