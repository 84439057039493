import { Box, Checkbox, FormControl, Icon, Link, Tag, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { FaLock } from "react-icons/fa";
import { displayDate } from "../../../../common/formatting/displayDate";
import { TipTap } from "../../../../common/input/TipTap/TipTap";
import { useAppSelector } from "../../../../common/redux/hooks";
import { CustomContractField, CustomFieldType } from "../../../../common/types";
import { displayPersonNameWithEmail } from "../sharing/AddExternalParticipantModal";

export const CustomField = ({ field }: { field: CustomContractField }) => {
  return (
    <Box pb="4">
      <Text fontWeight={"bold"} fontSize="sm">
        {field.name}
      </Text>
      {field.isInternal && (
        <Text fontSize={"xs"} color="smMuted" pb="2">
          <Icon as={FaLock} boxSize={"2"} mr="1" />
          {t("Internal field - will not be visible to contract counterparty")}
        </Text>
      )}
      <FieldValue field={field} />
    </Box>
  );
};

const FieldValue = ({ field }: { field: CustomContractField }) => {
  const contractState = useAppSelector((state) => state.contract.state);

  switch (field.type) {
    case CustomFieldType.CustomAmount:
      return (
        <Text fontSize="sm">
          {field.amount?.toLocaleString()} {field.currency ?? contractState?.contract.currency}
        </Text>
      );
    case CustomFieldType.CustomText:
      return <Text fontSize="sm">{field.text}</Text>;
    case CustomFieldType.CustomNumber:
      return <Text fontSize="sm">{field.value?.toLocaleString()}</Text>;
    case CustomFieldType.CustomUrl:
      return (
        <Link fontSize="sm" target="_blank" rel="noopener noreferrer">
          {field.url}
        </Link>
      );
    case CustomFieldType.CustomRichText:
      return <TipTap content={field.text ?? ""} editable={false} />;
    case CustomFieldType.CustomEmail:
      return <Text fontSize="sm">{field.email}</Text>;
    case CustomFieldType.CustomBoolean:
      return (
        <FormControl>
          <Checkbox pl="4" isChecked={field.isChecked} isDisabled={true} />
        </FormControl>
      );
    case CustomFieldType.CustomTag:
      return (
        <Tag fontSize="xs" colorScheme="teal">
          {field.tag}
        </Tag>
      );
    case CustomFieldType.CustomDate:
      return <Text>{field.date ? displayDate(field.date) : null}</Text>;
    case CustomFieldType.CustomPercentage:
      return (
        <Text fontSize="sm">
          {/* eslint-disable-next-line @typescript-eslint/no-non-null-assertion */}
          {!field.value && field.value !== 0 ? null : field.value * 100}%
        </Text>
      );
    case CustomFieldType.CustomPhone:
      return (
        <Text fontSize="sm">
          {field.callingCode} {field.number}
        </Text>
      );
    case CustomFieldType.CustomEnum:
      return (
        <Tag fontSize="xs" colorScheme="teal">
          {field.value}
        </Tag>
      );
    case CustomFieldType.CustomMultiEnum:
      return (
        <>
          {field.values?.map((value) => (
            <Tag key={value} fontSize="xs" colorScheme="teal" mr="1">
              {value}
            </Tag>
          ))}
        </>
      );
    case CustomFieldType.CustomPerson:
      return (
        <Text fontSize="sm">{field.person ? displayPersonNameWithEmail(field.person) : t("No person provided")}</Text>
      );
  }
};
