import { Badge, Flex, Spacer, Text } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { PersonDto } from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";

interface Props {
  isOriginalQuestion: boolean;
  sender: PersonDto;
  sentAt: string;
  content: string;
}

export const SupplierQuestionComment = ({ isOriginalQuestion, sender, sentAt, content }: Props) => {
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();

  return (
    <Flex width="full" justifyContent={authState.me.id === sender.id ? "start" : "end"}>
      <Flex
        width="90%"
        flexDirection="column"
        backgroundColor={authState.me.id === sender.id ? "smSecondarySoft" : "smBackgroundSecondary"}
        p="2.5"
        rounded="md"
      >
        <Flex w="full">
          <Flex fontSize="xs" alignItems="center">
            <Text>
              {sender.firstName} {sender.lastName}{" "}
            </Text>
            {isOriginalQuestion && (
              <Badge colorScheme="purple" ml="6px">
                {t("Original question")}
              </Badge>
            )}
          </Flex>
          <Spacer />
          <Flex fontSize="xs">{displayDateWithTime(sentAt)}</Flex>
        </Flex>
        <Flex fontWeight={"semibold"}>
          <Text fontSize={"md"} whiteSpace={"pre-line"}>
            {content}
          </Text>
        </Flex>
      </Flex>
    </Flex>
  );
};
