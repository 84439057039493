import { FormControl, FormHelperText, FormLabel, Grid, GridItem, Heading, useMediaQuery } from "@chakra-ui/react";
import { useTranslation } from "react-i18next";
import { useUpdateOrganizationMutation } from "../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../common/auth/useLoggedInWithOrgContextState";
import { Layout } from "../../../../common/layout/Layout";
import { PersonsSelector } from "../../../../common/persons/PersonsSelector";
import { useAppDispatch } from "../../../../common/redux/hooks";
import { OrgDto, updateSelectedOrg } from "../../../../common/redux/reducers/authStateReducer";
import { CONTENT_WIDTH } from "../../../../config";
import { ChangeAboutUs } from "./ChangeAboutUs";
import { ChangeCountry } from "./ChangeCountry";
import { ChangeHomePageUrl } from "./ChangeHomePageUrl";
import { ChangeName } from "./ChangeName";
import { ChangeOrgNumber } from "./ChangeOrgNumber";
import { ChangeSize } from "./ChangeSize";
import { ChangeSpecializations } from "./ChangeSpecializations";

export interface EditCompanyProfileFormValues {
  name: string;
  about: string;
  specialties: string;
}

export const EditOrganizationProfile = () => {
  const dispatch = useAppDispatch();
  const [isLargerThan768] = useMediaQuery("(min-width: 768px)");
  const authState = useLoggedInWithOrgContextState();
  const { t } = useTranslation();
  const [editOrg] = useUpdateOrganizationMutation();
  return (
    <Layout>
      <Grid
        height="100%"
        gridTemplateColumns={`1fr minmax(0, ${CONTENT_WIDTH}) 1fr`}
        gridTemplateRows={"1fr"}
        templateAreas={`
                "leftContent content rightContent"
                `}
        px="2"
      >
        <GridItem area={"content"} px={isLargerThan768 ? "0" : "2.5"}>
          <Grid h="100%" gridTemplateColumns={"1fr"} gridTemplateRows={"70px 1fr"}>
            <GridItem display="flex" alignItems="center" borderBottom={"1px solid"} borderColor="smBorder">
              <Heading as="h2" size="lg">
                {t("Edit company profile")}
              </Heading>
            </GridItem>
            <GridItem paddingBottom={"70px"}>
              <ChangeName currentValue={authState.selectedOrg.name} />
              <ChangeCountry currentValue={authState.selectedOrg.country.id} />
              <ChangeOrgNumber currentValue={authState.selectedOrg.organizationNumber} />
              <ChangeHomePageUrl currentValue={authState.selectedOrg.homePageUrl ?? ""} />
              <ChangeAboutUs currentValue={authState.selectedOrg.aboutUs ?? ""} />
              <ChangeSpecializations currentValue={authState.selectedOrg.specializations ?? ""} />
              <FormControl pt="4">
                <FormLabel>{t("Contact persons")}</FormLabel>
                <PersonsSelector
                  organizationIds={[authState.selectedOrg.id]}
                  selectedPersons={authState.selectedOrg.contactPersons}
                  onChange={async (values) => {
                    const org = await editOrg({
                      orgId: authState.selectedOrg.id,
                      editOrganizationRequest: {
                        contactPersons: { persons: values.map((p) => p.id) },
                      },
                    }).unwrap();
                    dispatch(updateSelectedOrg(org as OrgDto));
                  }}
                />
                <FormHelperText>{`${t("Select contact persons for your organization")}. ${t(
                  "These will be visible to other organizations"
                )}.`}</FormHelperText>
              </FormControl>
              <ChangeSize currentValue={authState.selectedOrg.size ?? null} />
            </GridItem>
          </Grid>
        </GridItem>
      </Grid>
    </Layout>
  );
};
