import {
  Box,
  Button,
  Checkbox,
  CheckboxGroup,
  Flex,
  FormControl,
  FormHelperText,
  Heading,
  Modal,
  ModalCloseButton,
  ModalContent,
  ModalOverlay,
} from "@chakra-ui/react";
import { skipToken } from "@reduxjs/toolkit/dist/query";
import { t } from "i18next";
import { useState } from "react";
import {
  BseStatusDto,
  ContactPersonDto,
  MinimalOrgDto,
  useGetOrganizationEntryQuery,
} from "../../../../../autogen/bff-api";
import { ContactPersonForm } from "../../../../../common/ContactPersonForm";
import { ModalSkeleton } from "../../../../../common/ModalSkeleton";
import { useAppDispatch } from "../../../../../common/redux/hooks";
import { editBseThunk } from "../../../../../common/redux/thunks/basic-sourcing-event/edit-bse-thunk";

export const InviteOrganizationEntryModal = ({
  minimalOrg,
  onClose,
  eventStatus,
}: {
  minimalOrg?: MinimalOrgDto;
  onClose: () => void;
  eventStatus: BseStatusDto;
}) => {
  const dispatch = useAppDispatch();
  const { data: organizationEntry, isLoading } = useGetOrganizationEntryQuery(
    minimalOrg ? { entryId: minimalOrg.id } : skipToken
  );

  const [contactPersons, setContactPersons] = useState<ContactPersonDto[]>([]);

  const handleClose = () => {
    setContactPersons([]);
    onClose();
  };

  const createInvites = async () => {
    if (!minimalOrg?.id) throw Error("Organization id was unexpectedly undefined");
    await dispatch(
      editBseThunk({
        command: {
          type: "AddEmailInvites",
          invites: contactPersons.map((p) => ({
            email: p.email,
            language: p.language,
            organizationEntryId: minimalOrg.id,
          })),
        },
      })
    );
    handleClose();
  };

  return (
    <Modal isOpen={!!minimalOrg} onClose={handleClose} size="3xl">
      <ModalOverlay />
      <ModalContent width="full" height="60%" minHeight="60%" maxHeight="80%" padding="5" overflow="hidden">
        <ModalCloseButton />
        <Flex minHeight="full" height="full" flexDirection="column" overflow="hidden">
          <Heading as="h2" size="md" pb="2" pr="5">
            {t("Select persons to invite for")} {minimalOrg?.name}
          </Heading>
          {isLoading && <ModalSkeleton />}
          {organizationEntry && (
            <Flex flexDirection="column" grow="1" justifyContent="space-between" overflowY="auto">
              <Flex flexDirection="column">
                <Box pt="5" pl="2">
                  <CheckboxGroup colorScheme="teal">
                    {organizationEntry.contactPersons?.map((p) => (
                      <Checkbox
                        key={p.email}
                        width="full"
                        pb="2"
                        isChecked={!!contactPersons.find((selectedPerson) => selectedPerson.email === p.email)}
                        onChange={(e) => {
                          setContactPersons((selectedPersons) => {
                            if (e.target.checked)
                              return [
                                ...selectedPersons.filter((selectedPerson) => selectedPerson.email !== p.email),
                                p,
                              ];
                            return [...selectedPersons.filter((selectedPerson) => selectedPerson.email !== p.email)];
                          });
                        }}
                      >
                        {`${p.name} (${p.email})`}
                      </Checkbox>
                    ))}
                  </CheckboxGroup>
                </Box>
                {organizationEntry && (
                  <ContactPersonForm
                    organizationEntry={organizationEntry}
                    onSubmit={(newContactPerson) =>
                      setContactPersons((selectedPersons) => [...selectedPersons, newContactPerson])
                    }
                  />
                )}
              </Flex>
              <FormControl width="full" flexDirection="column" alignItems="end" pt="5">
                <Button isDisabled={!contactPersons.length} onClick={() => createInvites()}>
                  {t("Create invite")}
                </Button>
                {(eventStatus === "Published" || eventStatus === "DeadlineHasPassed") && (
                  <FormHelperText color="orange">
                    {t("The sourcing event is published and invitations will be sent at once")}.
                  </FormHelperText>
                )}
              </FormControl>
            </Flex>
          )}
        </Flex>
      </ModalContent>
    </Modal>
  );
};
