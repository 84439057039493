import { Flex, Heading, Modal, ModalBody, ModalCloseButton, ModalContent, ModalOverlay, Text } from "@chakra-ui/react";
import { t } from "i18next";
import { BseQuestionDto } from "../../../../../autogen/bff-api";
import { displayDateWithTime } from "../../../../../common/formatting/displayDateWithTime";
import { displayWrappedWithEllipsis } from "../../../../../common/formatting/displayWrappedWithEllipsis";
import { QuestionStatusTag } from "../../../buyer/view-single/questions/QuestionStatusTag";
import { SupplierQuestionComments } from "../../../buyer/view-single/questions/SupplierQuestionComments";

interface Props {
  isOpen: boolean;
  close: () => void;
  canAddComments: boolean;
  question: BseQuestionDto;
  addComment: (values: { comment: string; createAnnouncementDraft: boolean }) => Promise<void>;
  addCommentDisabledReason: string | null;
}

export const QuestionModal = ({ isOpen, close, question, addComment, addCommentDisabledReason }: Props) => {
  return (
    <Modal isOpen={isOpen} onClose={close} size="3xl">
      <ModalOverlay />
      <ModalContent height="80%" maxHeight="80%">
        <ModalCloseButton />
        <ModalBody height="full" p={5}>
          <Flex flexDirection="column" height="full">
            <Heading as="h2" size="md" py="2">
              {displayWrappedWithEllipsis(question.subject, 60)}
            </Heading>
            <Flex borderRadius="md" w="full">
              <Flex w="full" alignItems={"center"}>
                <Text pr="2" fontSize={"sm"}>
                  {t("Asked by")}{" "}
                  <b>
                    {question.askedByPerson.firstName} {question.askedByPerson.lastName}
                  </b>
                  , {displayDateWithTime(question.askedAt)}
                </Text>
                <QuestionStatusTag status={question.status} size="sm" />
              </Flex>
            </Flex>
            <SupplierQuestionComments
              addCommentDisabledReason={addCommentDisabledReason}
              addComment={addComment}
              question={question}
              showCreateAnnouncementDraft={false}
            />
          </Flex>
        </ModalBody>
      </ModalContent>
    </Modal>
  );
};
