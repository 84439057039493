import { Box, Button, Flex, FormControl, FormErrorMessage, FormLabel, Icon, Text } from "@chakra-ui/react";
import { useCallback, useMemo, useState } from "react";
import { Controller, FormProvider, useForm } from "react-hook-form";
import { useTranslation } from "react-i18next";
import { FaPaperPlane } from "react-icons/fa";
import isEmail from "validator/lib/isEmail";
import {
  DocumentSignerMethod,
  PersonDto,
  SignerDto,
  TextDocumentDto,
  useGetContractQuery,
  ViewDocumentAsOwnerDto,
} from "../../../../../autogen/bff-api";
import { useLoggedInWithOrgContextState } from "../../../../../common/auth/useLoggedInWithOrgContextState";
import { DateTimeSelector } from "../../../../../common/input/DateTimeSelectors/DateTimeSelector";
import { MultiSelector } from "../../../../../common/input/Selector/MultiSelector";
import { SelectorValue } from "../../../../../common/input/Selector/SelectorValue";
import { SingleSelector } from "../../../../../common/input/Selector/SingleSelector";
import { PersonsSelectorForm } from "../../../../../common/persons/PersonsSelectorForm";
import { useSub } from "../../../../../common/subscription/useSub";
import { Explanation } from "../../../../../common/support/Explanation/Explanation";
import { useSkeletons } from "../../../../../common/useSkeletons";
import { displayPersonNameWithEmail } from "../../../view-single/sharing/AddExternalParticipantModal";
import { InvitedSigners } from "./InvitedSigners";

interface Props {
  contractId: string;
  documentId: string;
  documents: ViewDocumentAsOwnerDto[];
  textDocuments?: TextDocumentDto[];
  sendForSigning: (values: {
    mainDocumentId: string;
    attachments: SelectorValue[];
    signers?: SignerDto[];
    deadline?: string;
  }) => Promise<void>;
}

interface FormValues {
  mainDocumentId: string;
  attachments: SelectorValue[];
  internalSigners: PersonDto[];
  counterpartySigners: PersonDto[];
  organizationEntryCounterpartySigners: PersonDto[];
  manualSigners?: SignerDto[];
  deadline?: string;
  signatureMethod: DocumentSignerMethod;
}

/* Documents may be signed by 
- Internal users: Persons in the current organization,
- Counterparty organization entry contact persons: Contact persons in a self registered counterparty organization
- Counterparty organization persons: Persons in a registered counterparty organization
- Email users: Any person given by a manually typed email
 */

export const CreateSignedDocument = ({ contractId, documentId, documents, textDocuments, sendForSigning }: Props) => {
  const { t } = useTranslation();
  const authState = useLoggedInWithOrgContextState();
  const skeletons = useSkeletons();
  const sub = useSub();
  const { data, error, isLoading: isLoadingContract } = useGetContractQuery({ contractId });

  const counterpartyOrganizationId = data?.counterparty?.organization?.id;
  const counterpartyOrganizationEntry = data?.counterparty?.organizationEntry;

  const signatureMethods: { [key in DocumentSignerMethod]: string } = {
    Handwritten: t("Handwritten"),
    NoBankId: t("BankID"),
  };

  const organizationEntryContactPersons: PersonDto[] | undefined = useMemo(
    () =>
      data?.counterparty?.organizationEntry?.contactPersons?.map((p) => ({
        id: p.email,
        firstName: p.name,
        email: p.email,
      })),
    [data?.counterparty?.organizationEntry]
  );

  const [isLoading, setIsLoading] = useState(false);

  const methods = useForm<FormValues>({
    defaultValues: {
      mainDocumentId: documentId,
      manualSigners: [],
      signatureMethod: sub.hasBankIdAccess ? "NoBankId" : "Handwritten",
    },
  });

  const mapSigners = useCallback(
    ({
      internalSigners,
      counterpartySigners,
      manualSigners,
      signatureMethod,
      organizationEntryCounterpartySigners,
    }: {
      internalSigners?: PersonDto[];
      counterpartySigners?: PersonDto[];
      manualSigners?: SignerDto[];
      organizationEntryCounterpartySigners?: PersonDto[];
      signatureMethod: DocumentSignerMethod;
    }): SignerDto[] => {
      return [
        ...(manualSigners?.map((s) => ({
          ...s,
          signatureMethod,
        })) ?? []),
        ...(internalSigners?.map((s) => ({
          firstName: s.firstName ?? "",
          lastName: s.lastName ?? "",
          email: s.email.trim(),
          signatureMethod,
        })) ?? []),
        ...(counterpartySigners?.map((s) => ({
          firstName: s.firstName ?? "",
          lastName: s.lastName ?? "",
          email: s.email.trim(),
          signatureMethod,
        })) ?? []),
        ...(organizationEntryCounterpartySigners?.map((s) => ({
          firstName: s.firstName ?? "",
          lastName: s.lastName ?? "",
          email: s.email.trim(),
          signatureMethod,
        })) ?? []),
      ];
    },
    []
  );

  const send = async ({
    mainDocumentId,
    attachments,
    internalSigners,
    counterpartySigners,
    organizationEntryCounterpartySigners,
    manualSigners,
    deadline,
    signatureMethod,
  }: FormValues) => {
    setIsLoading(true);
    await sendForSigning({
      mainDocumentId,
      attachments,
      signers: mapSigners({
        internalSigners,
        counterpartySigners,
        organizationEntryCounterpartySigners,
        manualSigners,
        signatureMethod,
      }),
      deadline,
    });
    setIsLoading(false);
  };

  const mainDocumentId = methods.watch("mainDocumentId");
  const manualSigners = methods.watch("manualSigners");
  const internalSigners = methods.watch("internalSigners");
  const counterpartySigners = methods.watch("counterpartySigners");
  const organizationEntryCounterpartySigners = methods.watch("organizationEntryCounterpartySigners");

  const allSignersAreValid =
    manualSigners?.every(
      (e) => isEmail(e.email.trim()) && e.firstName.trim().length > 1 && e.lastName.trim().length > 1
    ) ?? true;

  if (error) return <Text>{t("Something went wrong")}...</Text>;

  if (isLoadingContract && !data) return skeletons.stackedLines(0, 5);

  return (
    <Box backgroundColor="smBackgroundSecondary" pt="5" px="5" rounded="md">
      <FormProvider {...methods}>
        <form onSubmit={methods.handleSubmit(send)}>
          <FormControl id="documentToBeSigned" isInvalid={methods.formState.errors.mainDocumentId !== undefined} pb="5">
            <FormLabel>{t("Document to be signed")}</FormLabel>
            <Controller
              name="mainDocumentId"
              control={methods.control}
              render={({ field: { onChange, value } }) => {
                const selectedOption =
                  documents.find((d) => d.id === value) ?? textDocuments?.find((t) => t.id === value);
                return (
                  <SingleSelector
                    size="sm"
                    value={selectedOption ? { label: selectedOption.name, value } : undefined}
                    options={[
                      ...documents.map((d) => ({ label: d.name, value: d.id })),
                      ...(textDocuments?.map((d) => ({ label: d.name, value: d.id })) ?? []),
                    ]}
                    onChange={(value) => onChange(value?.value)}
                    placeholder={{
                      text: t("Select document"),
                      color: "inherit",
                    }}
                    noOptionsProvidedMessage={t("No documents have been uploaded") ?? ""}
                    noMatchingOptionsMessage={t("No matching documents") ?? ""}
                  />
                );
              }}
            />
            <FormErrorMessage>
              {methods.formState.errors.mainDocumentId && methods.formState.errors.mainDocumentId.message}
            </FormErrorMessage>
          </FormControl>
          {mainDocumentId && (
            <>
              <FormControl id="attachments" isInvalid={methods.formState.errors.attachments !== undefined} pb="5">
                <FormLabel>{t("Attachments")}</FormLabel>
                <Controller
                  name="attachments"
                  control={methods.control}
                  render={({ field: { onChange, value } }) => {
                    return (
                      <MultiSelector
                        size="sm"
                        value={value}
                        options={[
                          ...documents.map((e) => ({ label: e.name, value: e.id })),
                          ...(textDocuments?.map((d) => ({ label: d.name, value: d.id })) ?? []),
                        ].filter((d) => d.value !== mainDocumentId)}
                        onChange={(value) => onChange(value)}
                        placeholder={{ text: t("Select document"), color: "inherit" }}
                        noOptionsAvailableMessage={t("No matching documents") ?? ""}
                      />
                    );
                  }}
                />
                <FormErrorMessage>
                  {methods.formState.errors.attachments && methods.formState.errors.attachments.message}
                </FormErrorMessage>
              </FormControl>
              {sub.hasBankIdAccess && (
                <FormControl
                  id="signatureMethod"
                  isInvalid={methods.formState.errors.signatureMethod !== undefined}
                  pb="5"
                >
                  <FormLabel>{t("Signature method")}</FormLabel>
                  <Controller
                    name="signatureMethod"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <SingleSelector
                          size="sm"
                          value={{ value: value as string, label: signatureMethods[value] }}
                          options={Object.keys(signatureMethods).map((value) => ({
                            label: signatureMethods[value as DocumentSignerMethod],
                            value,
                          }))}
                          onChange={(value) => onChange(value?.value)}
                          placeholder={{
                            text: t("Select signature method"),
                            color: "inherit",
                          }}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {methods.formState.errors.signatureMethod && methods.formState.errors.signatureMethod.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <PersonsSelectorForm<"internalSigners", FormValues>
                organizationIds={[authState.selectedOrg.id]}
                label={t("Internal signers")}
                control={methods.control}
                name="internalSigners"
                placeholderText={t("Select internal signers")}
                errorMessage={methods.formState.errors.internalSigners?.message}
              />
              {counterpartyOrganizationId && (
                <Flex pt="5">
                  <PersonsSelectorForm<"counterpartySigners", FormValues>
                    organizationIds={[counterpartyOrganizationId]}
                    label={t("Counterparty signers")}
                    control={methods.control}
                    name="counterpartySigners"
                    placeholderText={t("Select counterparty signers")}
                    errorMessage={methods.formState.errors.counterpartySigners?.message}
                  />
                </Flex>
              )}
              {counterpartyOrganizationEntry && (
                <FormControl
                  id="organizationEntryCounterpartySigners"
                  isInvalid={methods.formState.errors.counterpartySigners !== undefined}
                  pb="5"
                >
                  <FormLabel>{t("Counterparty signers")}</FormLabel>
                  <Controller
                    name="organizationEntryCounterpartySigners"
                    control={methods.control}
                    render={({ field: { onChange, value } }) => {
                      return (
                        <MultiSelector
                          size="sm"
                          value={value?.map((u) => ({
                            label: displayPersonNameWithEmail(u),
                            value: u.id,
                          }))}
                          options={
                            organizationEntryContactPersons?.map((u) => ({
                              label: displayPersonNameWithEmail(u),
                              value: u.id,
                            })) ?? []
                          }
                          onChange={(o) => {
                            const persons = organizationEntryContactPersons?.filter((p) =>
                              o.map((o) => o.value).includes(p.id)
                            );
                            onChange(persons);
                          }}
                          placeholder={{ text: t("Select counterparty signers"), color: "inherit" }}
                          noOptionsAvailableMessage={t("No matching users") ?? ""}
                        />
                      );
                    }}
                  />
                  <FormErrorMessage>
                    {methods.formState.errors.counterpartySigners &&
                      methods.formState.errors.counterpartySigners.message}
                  </FormErrorMessage>
                </FormControl>
              )}
              <FormLabel htmlFor="signedDocuments" pb="2" pt="5">
                {t("Other signers")}
              </FormLabel>
              <InvitedSigners />
              <FormLabel htmlFor="deadline">{t("Deadline")}</FormLabel>
              <Controller
                name="deadline"
                control={methods.control}
                render={({ field: { onChange, value } }) => {
                  return <DateTimeSelector defaultDate={value} onChange={onChange} />;
                }}
              />
              <Flex justifyContent={"end"} mt="30px">
                <Explanation
                  fontSize="sm"
                  enabled={!allSignersAreValid}
                  text={t(
                    `Make sure all signers have a valid email, first name and last name before sending the document for signing.`
                  )}
                >
                  <Button
                    type="submit"
                    variant={"solid"}
                    size="sm"
                    colorScheme={"blue"}
                    isLoading={isLoading}
                    isDisabled={
                      !allSignersAreValid ||
                      !(
                        manualSigners?.length ||
                        internalSigners?.length ||
                        counterpartySigners?.length ||
                        organizationEntryCounterpartySigners?.length
                      )
                    }
                    mb="5"
                    leftIcon={<Icon as={FaPaperPlane} w="15px" h="15px" />}
                  >
                    {t("Send out for signing")}
                  </Button>
                </Explanation>
              </Flex>
            </>
          )}
        </form>
      </FormProvider>
    </Box>
  );
};
